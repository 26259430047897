
/******************
* MODULY
******************/

#uvod {
	overflow: hidden;
	margin: 0 0 30px 0;

	h3 {
		font-weight: normal;
		margin: 10px 0;
		padding: 0;
		font-family: $font;
		line-height: 1.2;
	}
}

.oci.profil{
	#obalUpozorneni {
		position: relative;
		height: 150px;
		overflow: hidden;
	}

	#upozorneni{
		background: $color-main;
		max-width: 680px;
		box-sizing: border-box;
		padding: 22px 33px;
		margin-top: 80px;
		@extend %sf;

		h3.cvi, a{
			color: white;
		}

		h3.cvi{
			margin: 0;
			padding: 0;
			line-height: 1.25;
			float: left;
		}

		.vice-info{
			float: right;
		}

		.dok-upoutavka{
			@include fill;
		
			img{
				width: 100%;
			}
		}
	}

	.profil-titul.editor{
		margin: 45px auto 20px;
	}
}

.profil-telo {
	margin-bottom: 50px;
}

.o-jihlave {
	&-left,
	&-right {
		padding: 50px 0;
	}

	&-left {
		padding-bottom: 0;
	}

	&-right {
		padding-top: 0;
	}

	@include bp("> 700px") {
		&-right {
			padding-bottom: 50px;
		}

		&-right {
			padding-top: 50px;
		}
	}

	&-left {
		@include font-size(16px);
		color: #a53740;

		p {
			margin: 0 0 30px 0;
		}

		.te-medium,
		.te-big {
			line-height: 1;
		}

		.te-medium {
			@include font-size(31px);
			font-family: $font-normal;
		}

		.te-big {
			@include font-size(54px);
			font-family: $font-normal;
		}
	}

	&-right {
		@include font-size(18px);
		line-height: ( 34 / 18 );
	}

	@include bp("> 700px") {
		&-left {
			float: left;
			width: 24%;
			width: calc(25% - 18px);
		} 

		&-right {
			float: right;
			width: 74%;
			width: calc(75% - 6px);
		}
	}
}

#mbannery {
	font-size: rem(14px);
	clear: both;
	padding: 30px 0;
	margin: 0;
	color: #000000;
	background: #ffffff;

	.editor {
		text-align: center;

		@include link-all {
			color: #000000 !important;
		}
	}
	
	li { 
		margin-top: 0;
		margin-bottom: 0;
	}

	// hr.grafika {
	// 	position: absolute;
	// 	width: 100%;
	// 	left: 0;
	// 	top: 0;
	// }
}

#jmeniny {
	padding: 6px 12px 2px 15px;
	text-align: left;
	
	* {
		display: inline;
	}
}

#kontakt {
	// &::before {
	// 	content: "Space";
	// 	display: block;
	// 	color: transparent;
	// 	padding: 18px 0 0 0;
	// 	font-family: $font-normal;
	// 	font-size: rem(21px);
	// }

	address {
		display: block;
		font-style: normal;
		margin: 0 0 15px 0;
		line-height: ( 33 / 16 );
	}
	
	strong {
		font-weight: normal;
	}

	.kontakt-ext {
		img {
			margin: 0 1em 1em 0;
		}
	}
}

#anketa {
	p {
		font-size: rem(18px);
		display: inline;
		padding: 0;
		line-height: 1.4;
		margin: 0 0 1em 0;
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 2em 0 5px 0;

		li {
			margin-bottom: 8px;
		}
	}

	.hlas {
		margin-top: 3px;
		z-index: 1;
		
		div {
			height: 12px;
			background-color: $color-main;
		}
	}
}

#pocitadlo {
	margin: 15px 0 0 0;
	text-align: center;
	padding-bottom: 15px;

	ul.inline {
		padding-left: 0;
		display: inline;

		li span {
			margin: 0 0 0 2px;
		}
	}
}
