
/*****************************************
* ECCO - FLEXBOX
*****************************************/

@mixin flex( $values ) {
	-webkit-box-flex: $values;
	-moz-box-flex:  $values;
	-webkit-flex:  $values;
	-ms-flex:  $values;
	flex:  $values;
}

@mixin flex-base {
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-moz-box-wrap: nowrap;
	-webkit-box-wrap: nowrap;
	-webkit-flex-wrap: nowrap;
	-ms-flexbox-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

@mixin flex-wrap( $value ) {
	-moz-box-wrap: $value;
	-webkit-box-wrap: $value;
	-webkit-flex-wrap: $value;
	-ms-flexbox-wrap: $value;
	-ms-flex-wrap: $value;
	flex-wrap: $value;
}

@mixin flex-order( $value ) {
	-webkit-box-ordinal-group: $value;  
	-moz-box-ordinal-group: $value;     
	-ms-flex-order: $value;     
	-webkit-order: $value;  
	order: $value;
}

@mixin flex-direction( $dir ) {
	-moz-box-direction: $dir;
	-webkit-box-direction: $dir;
	-webkit-box-orient: if( $dir == "row", "horizontal", "vertical" );
	-webkit-flex-direction: $dir;
	-ms-flexbox-direction: $dir;
	-ms-flex-direction: $dir;
	flex-direction: $dir;
}

@mixin flex-align-content( $value ) {
	-webkit-box-align-content: $value;
	-webkit-align-content: $value;
	-ms-flex-align-content: $value;
	align-content: $value;
}

@mixin align-items( $value ) {
	-webkit-box-align: $value;
	-webkit-align-items: $value;
	-moz-box-align: $value;
	-ms-flex-align: $value;
	align-items: $value;
}

@include ecco-import-once("ecco-flexbox") {

	%flex {
	   display: -webkit-box;
	   display: -moz-box;
	   display: -webkit-flex;
	   display: -ms-flexbox;
	   display: flex;
	   -moz-box-wrap: nowrap;
	   -webkit-box-wrap: nowrap;
	   -webkit-flex-wrap: nowrap;
	   -ms-flexbox-wrap: nowrap;
	   -ms-flex-wrap: nowrap;
	   flex-wrap: nowrap;
	}

	%flex-row {
	   -moz-box-direction: row;
	   -webkit-box-direction: row;
	   -webkit-box-orient: horizontal;
	   -webkit-flex-direction: row;
	   -ms-flexbox-direction: row;
	   -ms-flex-direction: row;
	   flex-direction: row;
	}

	%flex-col {
	   -moz-box-direction: column;
	   -webkit-box-direction: column;
	   -webkit-box-orient: vertical;
	   -webkit-flex-direction: column;
	   -ms-flexbox-direction: column;
	   -ms-flex-direction: column;
	   flex-direction: column;
	}

	%flex-align-between {
	   -webkit-box-align-content: space-between;
	   -webkit-align-content: space-between;
	   -ms-flex-align-content: space-between;
	   align-content: space-between;
	}

	%flex-align-center {
	   -webkit-box-align-content: center;
	   -webkit-align-content: center;
	   -ms-flex-align-content: center;
	   align-content: center;
	}

	%flex-align-start {
	   -webkit-box-align-content: flex-start;
	   -webkit-align-content: flex-start;
	   -ms-flex-align-content: flex-start;
	   align-content: flex-start;
	}

	%flex-align-item-start {
	   -webkit-box-align: flex-start;
	   -webkit-align-items: flex-start;
	   -moz-box-align: flex-start;
	   -ms-flex-align: flex-start;
	   align-items: flex-start;
	}

	%flex-align-item-end {
	   -webkit-box-align: flex-end;
	   -webkit-align-items: flex-end;
	   -moz-box-align: flex-end;
	   -ms-flex-align: flex-end;
	   align-items: flex-end;
	}

	%flex-align-item-center {
	   -webkit-box-align: center;
	   -webkit-align-items: center;
	   -moz-box-align: center;
	   -ms-flex-align: center;
	   align-items: center;
	}

	%flex-start-all {
	   -webkit-box-pack: justify;
	   -webkit-justify-content: flex-start;
	   -ms-flex-pack: justify;
	   -moz-box-pack: justify;
	   justify-content: flex-start;
	   -webkit-box-align: flex-start;
	   -webkit-align-items: flex-start;
	   -moz-box-align: flex-start;
	   -ms-flex-align: flex-start;
	   align-items: flex-start;
	   -webkit-box-align-content: flex-start;
	   -webkit-align-content: flex-start;
	   -ms-flex-align-content: flex-start;
	   align-content: flex-start;
	}

	%flex-align-item-stretch {
	   -webkit-box-align: stretch;
	   -webkit-align-items: stretch;
	   -moz-box-align: stretch;
	   -ms-flex-align: stretch;
	   align-items: stretch;
	}

	%flex-justify-between {
	   -webkit-box-pack: justify;
	   -webkit-justify-content: space-between;
	   -ms-flex-pack: justify;
	   -moz-box-pack: justify;
	   justify-content: space-between;
	}

	%flex-justify-center {
	   -webkit-box-pack: justify;
	   -webkit-justify-content: center;
	   -ms-flex-pack: justify;
	   -moz-box-pack: justify;
	   justify-content: center;
	}

	%flex-justify-start {
	   -webkit-box-pack: justify;
	   -webkit-justify-content: flex-start;
	   -ms-flex-pack: justify;
	   -moz-box-pack: justify;
	   justify-content: flex-start;
	}

	%flex-justify-end {
	   -webkit-box-pack: justify;
	   -webkit-justify-content: flex-end;
	   -ms-flex-pack: justify;
	   -moz-box-pack: justify;
	   justify-content: flex-end;
	}

	%flex-wrap {
	   -moz-box-wrap: wrap;
	   -webkit-box-wrap: wrap;
	   -webkit-flex-wrap: wrap;
	   -ms-flexbox-wrap: wrap;
	   -ms-flex-wrap: wrap;
	   flex-wrap: wrap;
	}

	%flex-item {
	   -webkit-box-basis: auto;
	   -webkit-flex-basis: auto;
	   -ms-flex-basis: auto;
	   flex-basis: auto;
	   -webkit-box-flex: 1;
	   -moz-box-flex: 1;
	   -webkit-flex: 1;
	   -ms-flex: 1 0 auto;
	   flex: 1;
	}

}
