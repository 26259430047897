
/*****************************
* FORMULÁŘE
*****************************/

/*****************************
* DEFINICE PROMĚNNÝCH
*****************************/

$form-background:     transparent;           // pozadí formulářů
$form-padding:        20px 0;         // odsazení formulářů
$format-height:       40px;              // výška běžných inputů a selectů ve formulářích
$format-border:       #d7d7d7;           // barva okrajů inputů
$format-border-focus: darken($format-border, 15%);           // barva okrajů inputů
$btn-height:          40px;              // výška tlačítek ve formulářích
$btn-background:      $color-main;            // pozadí tlačítek
$btn-border:          1px $color-main solid;   // border tlačítek
$btn-color:           #ffffff;           // barva textu tlačítek

/****************************/


/*****************************
* PLACEHOLDERY PRO PRVKY
*****************************/

%hiding-label {
	line-height: 1.2;
	text-align: left;
	padding: 3px 2px;
	display: block;

	&.label-skryty {
		padding: 0 2px;
		position: relative;
		
		&::after {
			@extend %fill;
			content: "";
			z-index: 2;
		}
	}
}

/****************************/


/*************************************************
* VŠECHNY BĚŽNÉ PRVKY VE FORMULÁŘÍCH
*************************************************/

.format {
	@include transition(border-color .15s ease-out);
	background-color: #ffffff;
	color: #000000;
	height: $format-height;
	border-radius: 0;
	line-height: ( 17 / 14 );

	&:focus {
		border-color: $format-border-focus;
	}
}

.format,
fieldset {
	border: 2px $format-border solid;
}

textarea.format {
	min-height: $format-height * 2;
}

select.format[multiple] {
	height: $format-height * 3;
}

.btn {
	background-color: $btn-background;
	border: $btn-border;
	color: $btn-color;
	height: $btn-height;
	font-family: $font-fett;
	font-size: rem(22px);
}

.fbtn .btn {
	padding: 0 1em;
}

/************************************************/

.fkont {
	margin-top: 20px;

	form {
		padding: $form-padding;
	}

	&.nizky .fvpravo p {
		text-align: right;
	}
}

.formular,
.fkont form,
.nastaveni {
	background-color: $form-background;
}

//zjistit, kde to vůbec je kromě diskuze
.fcesta,
#{selector-link-all(".fcesta a")} {
	background-color: $th-background;
	color: $th-text;
}


/*******************************
*	ABO A NAPIŠTE NÁM NA TITULCE
*******************************/

.titulformular {
	label {
		@extend %hiding-label;

		&.label-skryty::after {
			background: transparent url(images/frm_prekryti.png) repeat 0 0;
		}
	}

	.format {
		margin: 2px 0;
		padding: 5px 15px;
		width: 100%;
		border-color: #ffffff;
		background: {
			repeat: no-repeat;
			position: 0 0;
		}
	}

	img {
		float: left;
		margin: 0 8px 0 0;
	}
	
	.captchaformat {
		width: 102px;
		margin: 0;
		float: right;
	}
	
	.fbtn,
	.btn {
		clear: none;
		margin: 0;
		float: right;
	}

	.fbtn {
		width: 102px;
	}
	
	.btn {
		width: 100%;
	}
	
	p {
		padding: 0 0 10px 0;
		margin: 0;

		&.opiste {
			padding: 6px 0 0 0;
		}
	}
}


/***************
*	HLEDÁNÍ
***************/

#hledani {
	overflow: hidden;
	position: relative;
	top: 9px;
	padding: 0 15px;

	#stranka & {
		clear: both;
	}

	@include bp("> 500px") {
		float: right;
		width: 164px;
		padding: 0;
		top: 0;
		margin-top: 24px;

		#stranka & {
			clear: none;
		}
	}

	.havireni & {
		padding-bottom: 14px;
	}

	.pole {
		position: relative;
		z-index: 5;
	}

	label {
		@extend %hiding-label;

		&.label-skryty::after {
			background: transparent url(images/hl_prekryti.png) repeat 0 0;
		}

		@include bp("<= 500px") {
			display: none;
		}
	}

	input:focus {
		outline: 0;
	}
	
	.format {
		@include font-size(16px);
		width: 100%;
		margin: 0;
		//height: 42px;
		height: 26px;
		border-width: 1px;
		border-color: #d7d7d7;
		color: #000000;
		padding: 4px 30px 4px 8px;
		background: {
			repeat: no-repeat;
			position: 0 50%;
		}
	}

	.btn {
		@extend %reset;
		border: 0;
		background-color: transparent;
		position: absolute;
		z-index: 2;
		top: 1px;
		right: 1px;
		height: auto;
		color: #000000;
	}
}
