// Sablona - vsechny stranky + FTB

//@include font($font);
@import url('https://fonts.bunny.net/css?family=roboto:100,300,400,500,700,900,700i,500i,400i,300i,100i,900i');
@include font-face(
   (
      family: $font-fett,
      weight: bold,
   ),
   'teutonfettbold-web'
);
@include font-face(
   (
      family: $font-normal,
      weight: bold,
   ),
   'teutonnormalbold-web'
);
@include font-face(
   (
      family: $font-mager,
   ),
   'teutonmager-web'
);
@include font-face(
   (
      family: $font-hell,
   ),
   'teutonhell-web'
);

$font-base-px: 16px;
$font-base: get-base-font-size-percentage($font-base-px) !global;

html {
   line-height: ratio($font-base-px, 22px, float);
   font-size: 18px;
   font-family: $font;

   @include bp('> 600px') {
      font-size: $font-base-px;
   }
}

body.editor {
   background-color: #ffffff;
   font-size: 18px;
   font-family: $font;

   @include bp('> 600px') {
      font-size: $font-base-px;
   }

   h3 {
      font-size: rem(22px);
      font-family: $font;
      font-weight: normal;
   }
}

body,
.editor {
   font-size: 1em;
}

// body:not(.profil.oci) .editor ul {
// 	li {
// 		list-style-type: none;
// 		margin-left: 0;
// 		padding-left: 24px;
// 		position: relative;

// &::before {
// 	content: url(images/arr-text.svg);
// 	margin-right: .75em;
// 	position: absolute;
// 	top: .22em;
// 	left: 0;
// }
//list-style-image: url(images/arr-text.svg);
// 	}
// }

body,
.automat {
   color: #666666;
}

@include link {
   color: #a53740;
   text-decoration: underline;

   .dgm &,
   .gustavmahler &,
   .htradice & {
      color: #007d90;
   }

   .odpady & {
      color: #00893b;
   }
   .turista & {
      color: $color-turista;
   }

   .doprava & {
      color: #007aae;
   }
}

@include link-over {
   text-decoration: none;
}

hr,
hr.oddelovac {
   color: #dddddd;
   background-color: #dddddd;
}

input,
button,
textarea,
select {
   font-family: $sans-serif;
}

.zvyrazneni,
.vystraha,
.strlistovani strong {
   color: $color-main;
   background-color: #ffffff;
   padding: 0 3px;
}

.vystraha {
   border-radius: 3px;
}

.zvyrazneni2 {
   background-color: #303030;
   color: #ffffff;
   padding: 0 2px;
}

strong.zprava {
   font-size: 1.1em;
}

img {
   max-width: 100%;
   height: auto;
}

.editor {
   .personalni-detail {
      //font-family: $font-hell;
      //font-size: rem(20px);
      color: #666;

      .te-fett {
         font-weight: bold;
         font-family: $font-fett;
         font-size: rem(20px);
      }

      .te-main {
         color: $color-main;
      }

      .te-dull {
         color: #6f6f6e;
      }

      br {
         line-height: 0.6;
      }

      ul {
         list-style-type: none;
         padding: 0;
         margin: 0;

         li {
            list-style-type: circle;
            list-style-position: outside;
            margin-left: 20px;
         }
      }
   }
}
