/*************************
* ZÁHLAVÍ
*************************/

/*************************
* HLAVA
*************************/

#obalhlava {
    position: relative;
    z-index: 52;
    background-color: #ffffff;

    @include bp('> 1040px') {
        .titul & {
            position: sticky;
            left: 0;
            right: 0;
            top: 0;
            width: 100%;
            z-index: 50;

            //+ #obalobrazek {
            //   padding-top: 84px;
            //}
        }
    }
}

#hlava {
    @extend %sf;
    clear: both;
    position: relative;
    z-index: 3;
    text-align: center;

    @include bp('> 500px') {
        text-align: left;
    }

    #stranka & {
        margin-bottom: 18px;
    }

    #stranka.profil--havireni &,
    #stranka.havireni & {
        margin-bottom: 0;
    }

    .profil &,
    .profil-str & {
        @include bp('> 1024px') {
            padding-top: 15px;
        }
    }

    .profil.iti &,
    .profil-str.iti & {
        .znak.text {
            display: none;
            @include bp('> 600px') {
                display: inline-block;
            }
        }
        .znak.notext {
            display: inline-block;
            @include bp('> 600px') {
                display: none;
            }
        }
    }

    .profil.bez-znaku &,
    .profil-str.bez-znaku &,
    .profil-str.namesti & {
        padding-top: 60px;

        @include bp('> 800px') {
            padding-top: 15px;
            padding-right: 100px;
        }
    }

    #profil-navrat {
        text-decoration: none !important;
        position: absolute;
        right: 0;
        top: 0;

        img {
            width: 100px;
        }

        @include bp('> 800px') {
            top: 56px;
        }

        .havireni & {
            top: 0;
            bottom: auto;

            @include bp('> 800px') {
                top: auto;
                bottom: 0;
            }
        }
    }
}

.hlava {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include bp('> 600px') {
        flex-direction: row;
        align-items: flex-end;
    }
}

#znak {
    margin: 0;
    position: relative;
    z-index: 3;
    height: auto;

    @include bp('<= 500px') {
        width: 80px;
    }
}

#menu-trigger {
    float: right;
    margin-right: 20px;
    margin-top: 24px;
    background-color: #f5eaea;
    padding: 1px 8px;
    font-size: rem(21px);
    color: $color-main;

    @include bp('> 500px') {
        display: none;
    }
}

#menu-close {
    display: block;
    text-align: right;
    margin-bottom: 20px;
    padding: 15px 15px 0 15px;

    @include bp('> 500px') {
        display: none;
    }
}

@include bp('<= 500px') {
    // .mobile-displayed {
    // 	overflow: hidden;
    // }

    .obalnahore {
        @include transition(max-height 0.3s ease-out);
        position: fixed;
        overflow: hidden;
        text-align: left;
        z-index: 60;
        top: 0;
        left: 0;
        right: 0;
        max-height: 0;
        background-color: rgba(#000000, 0.85);
        color: #ffffff;

        @include link {
            color: #ffffff;
        }

        .mobile-displayed & {
            max-height: 100vh;
            overflow-y: scroll;
        }
    }
}

#nazev {
    text-align: left;
    position: relative;
    margin: 24px 0 0 0;
    display: inline-block;
    float: left;

    #stranka:not([class*='profil']) & {
        margin-left: 20px;
        margin-top: 0;
    }

    ~ .nsa,
    ~ .kv {
        float: right;
        margin-top: 42px;
        margin-right: 10px;
    }

    .profil &,
    .profil-str & {
        margin-top: 11px;
    }

    .profil.havireni &,
    .profil-str.havireni & {
        margin-top: 0;
    }

    .namesti &,
    .probereme & {
        margin-bottom: 10px;
    }

    @include bp('> 600px') {
        #stranka:not([class*='profil']) & {
            margin-left: 40px;
        }
    }

    a {
        z-index: 30;
        display: inline;
        text-decoration: none !important;
        outline-width: 0;
        font-size: 0;

        img {
            font-size: rem(16px);
            line-height: 1;
        }

        &::after {
            content: '';
            @extend %fill;
        }
    }
}

.oci.profil {
    #hlava {
        padding: 0 !important;
        max-width: 1360px;
    }

    #nazev {
        margin-top: 24px;
    }

    .hledani-gte {
        float: right;

        #hledani {
            display: block;
            float: none;
            margin-top: 0;
            width: 320px;
            max-width: 100%;
            box-sizing: border-box;

            @include bp('> 1000px', '<= 1100px') {
                width: 300px;
            }

            label {
                line-height: 0.75;
            }

            .format {
                width: 100%;
                height: 38px;
            }

            .btn {
                right: 7px;
                top: 7px;
            }
        }

        #jazyky {
            padding-top: 15px;
            margin-left: 0;
            float: left;
        }

        #profil-navrat {
            position: static !important;
            float: right;
            margin-top: 10px;
        }
    }

    .editor.profil-titul {
        max-width: 920px !important;
        margin: 0 auto;
    }
}

@include bp('> 500px') {
    #znak,
    #nazev a,
    h1.cvi {
        float: left;
    }
}

#content-lista {
    display: none;
}

@include bp('> 1040px') {
    #content-lista {
        display: block;
        text-align: left;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 50;
        background: #d7d7d7 url(images/bg-fixed-header.svg) no-repeat 100% 17px;
        line-height: 1.2;

        object {
            position: absolute;
            right: 0;
            top: 17px;
        }

        .centrovany {
            &::before {
                margin-top: 24px;
                content: url(images/znak.svg);
                float: left;
                width: 138px;
                height: 60px;
            }
        }

        ul {
            @include box-sizing;
            list-style-type: none;
            padding: 42px 0 0 0;
            margin: 0;
            line-height: 1.2;
            float: left;
            position: relative;
            z-index: 3;
        }

        li {
            float: left;
            margin: 0;

            @include firefox-only {
                line-height: 1;
            }

            &:first-child {
                margin-left: 40px;
            }
        }

        @include link {
            color: #444444;
            text-decoration: none;
        }

        a {
            font-size: rem(18px);
            display: inline;
            padding: 0 40px 20px 0;
            position: relative;

            span {
                @include transition(width 0.25s, background-color 0.25s);
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 13px;
                background-color: transparent;

                &.in-progress {
                    background-color: #b2b2b2;
                }

                &.finished {
                    background-color: #b2b2b2;
                }
            }
        }
    }
}

/****************************
* HLAVNÍ OBRÁZEK A BLENDER
****************************/

#obalobrazek {
    //display: none;
    clear: both;

    #obrazek {
        position: relative;
        padding-bottom: ratio(1280px, 370px);
        background: {
            color: #666666;
            repeat: no-repeat;
            position: 50% 0;
            size: cover;
        }

        &:empty {
            background-image: url(images/bg-header.jpg);
        }

        > div {
            @include fill;
        }

        .ib_image,
        .ib_link {
            @include fill;
            z-index: 1;
            text-align: right;
            background: {
                repeat: no-repeat;
                position: 50% 0;
                size: cover;
            }
        }

        .ib_popisek_obal {
            @include font-size(39px);
            position: absolute;
            left: 20%;
            right: 0;
            bottom: 0.12em;

            @include bp('> 1024px') {
                left: 40%;
            }
        }

        .ib_popisek {
            display: none;
        }

        @include bp('<= 600px') {
            padding-bottom: 48%;
        }

        @include bp('> 675px') {
            .ib_popisek {
                background-color: rgba(#8d6568, 0.7);
                color: #ffffff;
                text-decoration: none;
                font-family: $font-normal;
                padding: 10px 40px 10px 40px;
                display: inline;
                line-height: 1.82;
                -webkit-box-decoration-break: clone;
                box-decoration-break: clone;

                @include msie-only {
                    display: inline-block;
                    line-height: 1.2;
                }

                .tic & {
                    background-color: #159ba7;
                }
                .personalni & {
                    display: none;
                }

                .dgm &,
                .gustavmahler &,
                .htradice & {
                    background-color: $color-dgm;
                }
                .turista & {
                    background-color: $color-turista;
                }
                .sport & {
                    background-color: $color-sport;
                }
                .kultura & {
                    background-color: $color-kultura;
                }
                .festival & {
                    background-color: $color-festival;
                }

                .bezodpadu & {
                    background-color: #b48c43;
                }

                .topakce & {
                    background-color: #964e23;
                }

                .havireni & {
                    background-color: $color-havireni;
                }

                .podzemi & {
                    background-color: $color-podzemi;
                }

                .mp & {
                    background-color: rgba(#ffffff, 0.8);
                    color: #6f0707;
                }

                .arena & {
                    background-color: rgba(#600724, 0.7);
                    color: #ffffff;
                }

                .namesti &,
                .probereme & {
                    background-color: rgba(#ffffff, 0.75);
                    color: $color-main;
                }
            }
        }
    }

    #administraceobrazky {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 5;
    }
}

.tic,
.topakce,
.sport,
.iti,
.kultura {
    #obalobrazek {
        position: relative;
    }

    #obrlinky {
        position: absolute;
        left: 1em;
        top: 0.75em;
        z-index: 30;

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        li {
            display: inline-block;
            margin-right: 1em;

            a {
                border: 3px #ffffff solid;
                width: 20px;
                height: 20px;
                display: inline-block;

                &.imagesBlender_link_active {
                    background-color: $color-main;
                }
            }
        }
    }
}

// @include bp("> 600px") {
// 	#obalobrazek {
// 		display: block;
// 		clear: both;
// 	}
// }

#lista {
    ul {
        @extend %reset-list;
        position: relative;

        &.inline {
            display: inline;
        }
    }

    li {
        margin: 0;
        white-space: nowrap;

        &.skip {
            position: absolute;
            display: block;
            top: 0 !important;
            left: 0 !important;
            max-width: 320px;
            padding: 1px;
            border: 0;
            white-space: normal;

            a {
                text-align: center;
                border: 0;
                position: absolute;
                left: 0;
                top: -500px;
                z-index: 0;
                padding: 0 2px;
                background-color: #ffffff;
                color: #000000;
            }

            a:focus,
            a:active {
                z-index: 10 !important;
                position: absolute !important;
                right: 10px;
                top: 5px;
            }
        }
    }
}

#jazyky {
    @extend %sf;
    margin: 0;
    padding: 25px 25px 0 10px;
    position: relative;
    text-align: center;
    float: right;

    ul {
        @extend %reset-list;
        display: inline;
    }

    @include bp('> 500px') {
        padding: 42px 34px 0 0;
        margin-left: 36px;
        text-align: right;
    }

    @include font-size(18px);

    li {
        display: inline;
        margin-left: 8px;
    }

    span {
        color: $color-main;
        font-weight: bold;
    }

    @include link {
        color: #666666;
    }

    .havireni & {
        padding-top: 7px;
        padding-bottom: 14px;

        @include bp('> 500px') {
            padding-top: 42px;
        }
    }
}
