/*******************
* NADPISY
*******************/

h1.cvi {
   font-size: rem(18px);
   padding: 5px 0 0 5px;
   line-height: 1.1;
   margin: 0;
   position: absolute;
   left: 0;
   top: 0;
   font-family: $font;
   font-weight: normal;
   display: inline-block;

   strong {
      font-weight: normal;
   }

   .international.bez-znaku &,
   .profil.bez-znaku &,
   .profil-str.bez-znaku & {
      padding-left: 0;
      position: static;
      font-size: rem(60px);
      color: $color-main;
      font-family: $font-mager;

      [lang='uk'] & {
         font-family: $font-hell;
      }

      strong {
         font-family: $font-fett;
      }
   }

   .profil.oci & {
      font-family: $font-fett;
      font-size: rem(50px);
      text-transform: uppercase;
      color: $color-main;

      span {
         display: block;
         font: 400 rem(16px) $font;
         color: #666666;
         text-transform: none;
         letter-spacing: 0.025em;

         strong {
            font-weight: 900;
            //text-transform: uppercase;
         }
      }
   }

   .profil.bez-znaku.dgm &,
   .profil.bez-znaku.podzemi &,
   .profil-str.bez-znaku.dgm &,
   .profil.bez-znaku.gustavmahler &,
   .profil-str.bez-znaku.gustavmahler &,
   .profil.bez-znaku.htradice &,
   .profil-str.bez-znaku.htradice & {
      color: $color-dgm;
   }

   .profil.bez-znaku.turista &,
   .profil-str.bez-znaku.turista & {
      color: $color-turista;
   }

   .profil.bez-znaku.informacni-portal &,
   .profil-str.bez-znaku.informacni-portal & {
      color: $color-infoportal;
   }

   .profil.bez-znaku.odpady &,
   .profil-str.bez-znaku.odpady & {
      color: #00893b;
   }

   .profil.bez-znaku.kultura &,
   .profil-str.bez-znaku.kultura & {
      color: $color-kultura;
   }
   .profil.bez-znaku.festival &,
   .profil-str.bez-znaku.festival & {
      color: $color-festival;
   }

   .profil.bez-znaku.doprava &,
   .profil-str.bez-znaku.doprava & {
      color: #fe697c;
   }

   .profil.bez-znaku.podzemi &,
   .profil-str.bez-znaku.podzemi & {
      color: #a3043e;
   }

   .profil.bez-znaku.bezodpadu &,
   .profil-str.bez-znaku.bezodpadu & {
      color: #6d9a0b;
   }

   .profil.bez-znaku.arena &,
   .profil-str.bez-znaku.arena & {
      color: #600724;
      text-transform: lowercase;
      margin: 0 0 0.075em -0.09em;

      &::before,
      &::after {
         font-size: rem(75px);
         font-weight: bold;
         font-family: $font-normal;
      }

      &::before {
         content: '\005b';
      }

      &::after {
         content: '\005d';
      }
   }
}

h1.logo {
   margin: 0 20px 0 0;

   .havireni & #znak {
      width: 200px;

      @include bp('> 1024px') {
         width: auto;
      }
   }
}

h2.cvi,
h2.cist,
h3.cvi,
h3.ud,
h4.ud,
#prohlaseni h3 {
   font-family: $font;
}

h2.cvi,
#kotva.kotva,
.poz h2.cist {
   @extend %reset;
   font-size: rem(31px);
   color: $color-main;
   font-weight: normal;
   font-family: $font-mager;
   line-height: 1.1;

   [lang='uk'] & {
      font-family: $font-hell;
   }

   span {
      display: inline-block;
      padding: 15px 32px;
   }
}

#zahlavi h2.cvi {
   margin: 58px 0 10px 0;
   padding: 0;
   display: inline-block;
}

// Hlavní nadpisy na podstránce
#zahlavi h2.cvi,
#zalozkynadpis h2.cvi {
   font-size: rem(44px);
   color: $color-main;
   line-height: 1;

   .dgm &,
   .gustavmahler &,
   .htradice & {
      color: $color-dgm;
   }

   .odpady & {
      color: #00893b;
   }
   .turista & {
      color: $color-turista;
   }
   .sport & {
      color: $color-sport;
   }
   .doprava & {
      color: #0090cd;
   }

   .podzemi & {
      color: $color-podzemi;
   }
   .kultura & {
      color: $color-kultura;
   }
   .festival & {
      color: $color-festival;
   }
}

h3 {
   &.cvi,
   &.ud,
   #prohlaseni & {
      font-size: rem(21px);
      font-weight: normal;
      margin: 25px 0 5px 0;
      padding: 8px 20px 4px 0;
      line-height: 1.2;
      color: #585758;
      font-family: $font-normal;
   }
}

#prohlaseni h4 {
   font-size: rem(18px);
   line-height: 1.2;
}

h4.ud {
   font-size: rem(20px);

   + .udz {
      margin-top: 28px;
   }
}

/**********************
* OSTATNÍ TEXTY
**********************/

form input,
form textarea,
form button,
form label,
form select {
   font-size: rem(14px);
}

#kalakci {
   .floatingBubbleElm p {
      font-size: rem(16px);
   }

   td,
   th {
      font-size: rem(15px);
   }
}

// .kontext2 h3 {
// 	font-size: rem(16px);
// }

.vzzalozky,
#rejstrik ul.rejstrikpismena {
   font-size: rem(17px);
}
