/*************************
* SEZNAMY
*************************/

/*************************
* DEFINICE PROMĚNNÝCH
*************************/

$document-link-color: $color-main; // barva odkazů ve výpisu dokumentů

$event-day-size: 48px; // velikost písma v cedulce - den
$event-month-size: 16px; // velikost písma v cedulce - den
$event-link-color: $color-main; // barva odkazů ve výpisu akcí
$event-day-background: transparent; // pozadí dne cedulky v kalendáři
$event-day-text: #a53740; // text dne cedulky v kalendáři
$event-month-background: transparent; // pozadí měsíce cedulky v kalendáři
$event-month-text: #a53740; // text měsíce cedulky v kalendáři

/************************/

/*************************
* MIXINY PRO ODRÁŽKY
*************************/

@mixin bullet($bg: #bcbcbc, $border: $bg, $top: 0.66em) {
    &::before {
        @extend %border-box;
        position: absolute;
        left: 0;
        top: $top;
        display: inline-block;
        content: '';
        width: 7px;
        height: 7px;
        background: transparent
            encode-svg(
                '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="7px" height="7px" viewBox="0 0 7 7"><path fill="#{$bg}" stroke="#{$border}" stroke-miterlimit="10" d="M2.214,0.5h2.572C5.732,0.5,6.5,1.268,6.5,2.214v2.572C6.5,5.732,5.732,6.5,4.786,6.5H2.214C1.268,6.5,0.5,5.732,0.5,4.786V2.214C0.5,1.268,1.268,0.5,2.214,0.5z"/></svg>'
            )
            no-repeat 0 0;
        line-height: 0;
        overflow: hidden;

        @content;
    }
}

@mixin no-bullet {
    &::before {
        content: normal !important;
    }
}

/************************/

.bodkazy .ui li {
    padding-left: 0;

    &.typsouboru {
        padding-left: 34px;
        background-position: 0 0.4em;
        list-style-image: none;
        margin-left: -25px;
    }
}

dl.kontakty ul.ui {
    margin-left: 0;

    ul {
        margin-left: 0;
    }
}

li {
    .odkazy .ui & li,
    .aktuality2 &.bezobr li,
    .utvary .ui &.o li,
    .kategorie .ui & li,
    .utvary .ui & li,
    .galerie .ui & li {
        @include bullet($top: 0.56em);
    }

    &.u,
    &.typsouboru {
        @include no-bullet;
        list-style-type: none !important;
    }

    .inline & {
        display: inline;
        list-style-type: none;

        &::before {
            position: relative;
            content: '\|';
            display: inline-block;
            margin: 0 10px 0 11px;
        }

        &:first-child::before,
        &.skip::before,
        &.skip + li:not(.skip)::before {
            content: normal;
        }
    }
}

.ui {
    li {
        clear: none;
        padding: 0;
        margin: 5px 0 20px 0;

        strong {
            font-weight: normal;

            a {
                font-family: $font-normal;
                font-size: rem(23px);
            }
        }

        li {
            margin: 5px 0;
            padding-left: 22px;

            &::after {
                content: normal;
            }

            strong {
                a {
                    font-family: $font;
                    font-size: rem(16px);
                }
            }

            // @include link {
            // 	color: #232323;
            // }
        }
    }

    div {
        font-weight: normal;
        margin-bottom: 2px;
        margin-top: 4px;
        padding-top: 0;
        padding-bottom: 0;

        &.ktg {
            margin-top: 0;
        }
    }
}

/**********************************
*	POVINNĚ ZVEŘEJŇOVANÉ INFORMACE
**********************************/

#isvs {
    .hodnota {
        @extend .b2;

        li {
            @include no-bullet;
        }
    }

    .odkazy > .ui > li > strong:first-child {
        font-family: $font-normal;
        font-size: rem(23px);
        color: #a53740;
    }
}

/*********************
* VÝPISY DOKUMENTŮ
*********************/

.dok {
    ul {
        @extend %reset-list;
    }

    li {
        content: normal;
        display: block;
        margin: 20px 0;
        padding: 0;
    }

    strong {
        img {
            margin: 3px 12px 4px 0;
            float: left;
        }

        a {
            font-size: rem(21px);
            font-weight: normal;
            font-family: $font-normal;
            color: $document-link-color;
            line-height: (25 / 21);
        }

        @include link {
            text-decoration: underline;
        }

        @include link-over {
            text-decoration: none;
        }
    }
}

#zpravodajstvi {
    ul.inline {
        padding: 0;
        margin: 20px 0;
    }
}

img.pritomnost {
    border-radius: 50%;
    margin: 0 0.75em;
}

h2.cvi + img.pritomnost {
    vertical-align: middle;
    margin-top: -0.75em;
}

.ui strong img.pritomnost {
    float: none;
    margin: 0 0.75em;
}

#nahore .odkazy,
#dole .odkazy,
.souvisejiciodkazy.kategorie {
    .ui li {
        &::before {
            content: url(images/arr-text.svg);
            margin-right: 0.75em;
            position: relative;
            top: 0.15em;
            left: 0;
        }

        a {
            font-family: $sans-serif;
            font-size: rem(16px);
        }
    }
}

/*******************************
* PŘEHLED OSOB
*******************************/

.dok.osoby {
    li {
        padding-left: 146px;

        img {
            margin-left: -146px;
            max-width: 120px;

            &.pritomnost {
                float: none;
                margin: 0 0.75em 0 0;
            }
        }

        @include link('[href*="tel:"]') {
            color: #a53740;
        }

        &.noface {
            &::before {
                content: '';
                margin-left: -146px;
                float: left;
                width: 120px;
                height: 120px;
                background: #cccccc url(/aspinclude/vismoweb5/html/noface.svg) no-repeat 50% 50%;
                background-size: 80px auto;
            }
        }

        @include bp('< 420px') {
            padding-left: 0;

            strong img,
            &.noface::before {
                float: none;
                display: table;
                margin: 0 0 10px 0;
            }

            strong img.pritomnost {
                display: initial;
            }
        }
    }

    &.seznam-ji li {
        padding-left: 0;
    }
}

.ui .ji-kontakty li {
    @include link {
        color: #a53740;
    }
}

/*****************************
* OSNOVA S UPOUTÁVKAMI V POZADÍ
*****************************/

.obrodkazy {
    .ui {
        @extend %reset-list;
    }

    li {
        @include no-bullet;
        @extend %reset;
        display: block;
        margin-bottom: 13px;
        background-image: none;
        position: relative;
    }

    a {
        font-size: rem(20px);
        font-family: $sans-serif;
        background-repeat: no-repeat;
        background-position: 0 50%;
        padding-left: 65px;
        display: table;
        height: 1em;
        min-height: 48px;
    }

    .mtext {
        display: table-cell;
        vertical-align: middle;
    }

    strong {
        font-weight: normal;
    }

    @include link {
        text-decoration: none;
    }

    @include link-over {
        text-decoration: underline;
    }
}

/*****************************
* VÝPIS AKCÍ S CEDULKAMI
*****************************/

.vice-akci {
    // margin-bottom: 62px;
    margin-bottom: 12px;
    margin-top: 1em;
    position: relative;

    @include bp('> 500px') {
        margin-top: -1.2em;
        padding-right: 12em;
    }

    .inline {
        @extend %reset-list;
        display: inline;
    }
}

.vypisakci-titul {
    @extend %border-box;
    @include grid(700px 2 22px);
    margin-bottom: -2px;

    &.vypisakci-probereme {
        width: 100%;
        max-width: 700px;
        margin-left: auto !important;
        margin-right: auto !important;
        float: none;
        margin-bottom: 40px;
    }

    h2.cvi {
        color: #c36066;
        margin-bottom: -1px;
    }

    &.akce-doporucene {
        h2.cvi span {
            background-color: #c36066;
            color: #ffffff;
        }
    }

    + .vypisakci-titul {
        h2.cvi span {
            padding-left: 0;
        }
    }

    &.akce-doporucene li:nth-child(2),
    & + .vypisakci-titul li:first-child {
        background: transparent url(images/bg-event.svg) no-repeat 100% 0;
    }

    .dok {
        ul {
            border-top: 1px #d9d9d8 solid;
            border-bottom: 1px #ececec solid;
        }

        li {
            @extend %border-box;
            display: table;
            margin: 0;
            padding: 14px 0;
            width: 100%;
            position: relative;
            border-bottom: 1px #d9d9d8 solid;
            border-top: 1px #ececec solid;
            height: 1em;
            min-height: 100px;

            &:first-child {
                margin-top: 0;
                padding-top: 14px;
            }

            strong {
                @include link {
                    color: $event-link-color;
                }
            }
        }

        .datum,
        .prazdnyDatum {
            display: table-cell;
            vertical-align: top;
            width: 4.35em;
            padding-top: 5px;
            text-align: left;
            font-family: $font;

            @include bp('> 600px') {
                width: 6.25em;
            }
        }

        .den,
        .mesic {
            display: block;
            line-height: 0.9;
        }

        .den {
            font-size: rem($event-day-size);
            font-weight: bold;
            background: $event-day-background;
            color: $event-day-text;
            font-family: $font-fett;
        }

        .mesic {
            font-size: rem($event-month-size);
            background: $event-month-background;
            color: $event-month-text;
            text-transform: lowercase;
        }

        .denPredlozka,
        .rok,
        .den .tecka {
            position: absolute;
            left: -9999px;
            right: 9990px;
        }

        .je-vicedenni {
            display: block;
            margin-top: 6px;
            color: #848484;
            font-size: rem(14px);
        }

        .prazdnyDatum {
            background: transparent;
        }

        .obsahAkce {
            display: table-cell;
            padding-right: 15px;
            padding-left: 15px;
        }
    }
}

/*****************************
* SEKCE TÉMATA NA TITULCE
*****************************/

$barvy-temata: (
    #ca1517,
    #048fcd,
    #004a7a,
    #006ea2,
    #c36066,
    #de5b12,
    #6c9b2e,
    #159aa8,
    #a8888a,
    #a43740,
    #018739,
    #dd697b,
    #988a86,
    #cc7e00,
    #579e7c
);
$barvy-temata-tic: (#ca1517, #159ba7, #a53740, #c36065, #007d99, #018fcd, #006a7a, #a8888b, #159aa9, #a53740);
$barvy-temata-dgm: (#ca0c0e, #c36065, #159aa9, #6f9294, #016990, #009cbb, #007d99, #9d9d9d);
$barvy-temata-odpady: (
    #ca1517,
    #006a7a,
    #159ba7,
    #018fcd,
    #00893b,
    #007d99,
    #6d9a0b,
    #7d7d7b,
    #007539,
    #226307,
    #607d23
);
$barvy-temata-namesti: (#aa878b, #ca0c0e, #c36065, #af735b, #6a6a6a, #de5b03, #a53740);
$barvy-temata-zdrave-mesto: (#018fcd, #ca0c0e, #c36065, #b36d32, #de5b03, #e7311b, #a8888b, #7d7d7b, #b36d32, #a53740);
$barvy-temata-doprava: (#3bd4ff, #8a7dd9, #68c260, #b2b598, #ff3461, #ffae44, #0094dc, #ff6c41);
$barvy-temata-podzemi: (#ca1517, #c36065, #b30970, #96140c, #800063, #865560, #9d9d9c);
//$barvy-temata-podzemi: ( #ca0c0e, #c36065, #159aa9, #6f9294, #016990, #009cbb, #007d99, #9d9d9d );
$barvy-temata-policie: (#ca0c0f, #929496, #c36065, #a4373f, #6f0707, #a9888a, #764e59, #6b6a6a);
$barvy-temata-havireni: ($color-havireni, #108d36, #c90b0f, #b8037a, #0e6729, #b57ca1, #4b1f89, #7d3fa9);
$barvy-temata-bezodpadu: (#ca0c0f, #000000, #cca050, #b48c43, #6d9a0b, #b3b136, #7b7908, #7b7908);
$barvy-temata-arena: (
    #600624,
    #c92026,
    #3fa535,
    #063e85,
    #ab2854,
    #c6403c,
    #2e3b31,
    #3f5073,
    #3f1f24,
    #915f5c,
    #272422,
    #6f6f6e
);
$barvy-temata-topakce: (#ca1517, #de5b12, #964e23, #61645a, #c36066, #6c383c, #cc8258, #858e70, #a8888a, #a43740);
$barvy-temata-turista: (#ca1517, #789b2e, #35642c, #46983b, #c34c39, #cd6120, #1c885d, #5b615a);
$barvy-temata-sport: (#ca1517, #a43740, #004a7a, #048fcd);
$barvy-temata-kultura: (#ca1517, #b1456e, #653b94, #324ab0, #c36066, #6c383c, #736484, #887373);
$barvy-temata-gustavmahler: (#159aa9, #69554b, #007d99, #6f9294);
$barvy-temata-festival: (#ca1517, #ba5d2b, #b09123, #908f78, #b78555, #9a4e4f, #949149, #69554b);
$barvy-temata-proberemeji: (#e8bb00, #c90c0f, #623655, #b1bc03, #b37d19, #127e34, #213a7b, #a877a8);
$barvy-temata-personalni: (#f38336, #a56b85, #5da183, #dc7281, #819245, #565a5a, #d40100);
$barvy-temata-infoportal: (
    #565656,
    #828282,
    #c12318,
    #f95a3b,
    #ea9c02,
    #9f4141,
    #ffb459,
    #b48d6e,
    #ad6767,
    #e95e7d,
    #804971,
    #c6c6c6
);
$barvy-temata-iti: (#db4e2f, #f99b21, #e9bb00, #a3c1c9, #a9c041, #c5d464, #c4b1a8, #d9c4b9);

.temata {
    padding-top: 62px;
    margin-bottom: 60px;

    &[class*='temata--']:not(.temata--tic) {
        li {
            @include grid(520px 2 22px, 800px 3, 1024px 4);
        }
    }

    &.temata--tic {
        padding-top: 40px;

        li {
            @include grid(520px 2 22px, 800px 2, 1024px 3);
        }
    }

    ul.ui {
        @extend %reset-list;
        @extend %sf;

        div {
            margin-top: 7px;
            line-height: (22 / 16);
        }

        li {
            margin-top: 0;
            margin-bottom: 22px;
            /*  display: flex;
            flex-direction: column;

            > strong:first-child {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
            } */

            a {
                @include font-size(22px);
                background: #ca1517;
                display: block;
                padding: 8px 12px 6px 12px;
                line-height: 1.1;
                font-family: $font-normal;
            }

            @include link {
                color: #ffffff;
                text-decoration: none;
            }

            @include link-over {
                text-decoration: underline;
            }
        }
    }

    &.temata--main ul.ui li {
        @for $i from 1 through length($barvy-temata) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata, $i);
            }
        }
    }

    &.temata--tic ul.ui li {
        @for $i from 1 through length($barvy-temata-tic) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata-tic, $i);
            }
        }
    }

    &.temata--dgm ul.ui li {
        @for $i from 1 through length($barvy-temata-dgm) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata-dgm, $i);
            }
        }
    }

    &.temata--gustavmahler ul.ui li {
        @for $i from 1 through length($barvy-temata-gustavmahler) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata-gustavmahler, $i);
            }
        }
    }

    &.temata--bezodpadu ul.ui li {
        @for $i from 1 through length($barvy-temata-bezodpadu) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata-bezodpadu, $i);
            }
        }
    }

    &.temata--arena ul.ui li {
        @for $i from 1 through length($barvy-temata-arena) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata-arena, $i);
            }
        }
    }

    &.temata--namesti ul.ui li {
        @for $i from 1 through length($barvy-temata-namesti) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata-namesti, $i);
            }
        }
    }

    &.temata--odpady ul.ui li {
        @for $i from 1 through length($barvy-temata-odpady) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata-odpady, $i);
            }
        }
    }

    &.temata--zdrave-mesto ul.ui li {
        @for $i from 1 through length($barvy-temata-zdrave-mesto) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata-zdrave-mesto, $i);
            }
        }
    }

    &.temata--doprava ul.ui li {
        @for $i from 1 through length($barvy-temata-doprava) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata-doprava, $i);

                @include link(&) {
                    color: #000;
                }
            }
        }
    }

    &.temata--podzemi ul.ui li {
        @for $i from 1 through length($barvy-temata-podzemi) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata-podzemi, $i);
            }
        }
    }

    &.temata--mp ul.ui li {
        @for $i from 1 through length($barvy-temata-policie) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata-policie, $i);
            }
        }
    }

    &.temata--havireni ul.ui li {
        @for $i from 1 through length($barvy-temata-havireni) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata-havireni, $i);
            }
        }
    }
    &.temata--informacni-portal ul.ui li {
        @for $i from 1 through length($barvy-temata-infoportal) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata-infoportal, $i);
            }
        }
    }
    &.temata--topakce ul.ui li {
        @for $i from 1 through length($barvy-temata-topakce) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata-topakce, $i);
            }
        }
    }
    &.temata--turista ul.ui li {
        @for $i from 1 through length($barvy-temata-turista) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata-turista, $i);
            }
        }
    }
    &.temata--sport ul.ui li {
        @for $i from 1 through length($barvy-temata-sport) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata-sport, $i);
            }
        }
    }
    &.temata--kultura ul.ui li {
        @for $i from 1 through length($barvy-temata-kultura) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata-kultura, $i);
            }
        }
    }
    &.temata--festival ul.ui li {
        @for $i from 1 through length($barvy-temata-festival) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata-festival, $i);
            }
        }
    }
    &.temata--personalni ul.ui li {
        @for $i from 1 through length($barvy-temata-personalni) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata-personalni, $i);
            }
        }
    }
    &.temata--proberemeji ul.ui li {
        @for $i from 1 through length($barvy-temata-proberemeji) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata-proberemeji, $i);
            }
        }
    }
    &.temata--iti ul.ui li {
        @for $i from 1 through length($barvy-temata-iti) {
            &:nth-child(#{$i}) a {
                background-color: nth($barvy-temata-iti, $i);

                @include link(&) {
                    color: #000;
                }
            }
        }
    }
}

/*****************************
* MODUL DALŠÍ ZDROJE NA TITULCE
*****************************/

.osnova {
    .ui {
        @extend %reset-list;
        text-align: left;

        li {
            margin: 0 0 0.5em 0;

            @include link {
                color: $color-main;
                text-decoration: none;
                border-bottom: 5px $color-main solid;
            }
        }
    }
}

.titulodkazy {
    .ui {
        @extend %reset-list;
    }

    li {
        @include no-bullet;
        display: block;
        background-image: none;
        margin: 4px 0;
        padding: 0;
        position: relative;

        div {
            margin: 2px 0 0 0;
        }
    }

    strong {
        font-weight: normal;
    }
}

//$volny-cas-colours: ( rgba(#44934a, .87), rgba(#2793d0, .96), rgba(#d03727, .89), rgba(#79a03a, .93), rgba(#2d9dab, .96), rgba(#c97173, .9) );
$volny-cas-colours: rgba(#1c99d0, 0.95), rgba(#189149, 0.95), rgba(#1175a2, 0.95), rgba(#c62220, 0.95),
    rgba(#ce930a, 0.95), rgba(#78a240, 0.95), rgba(#38949c, 0.95), rgba(#c76c6f, 0.95);

.volny-cas-odkazy {
    padding-top: 64px;
    min-height: 283px;
    box-sizing: border-box;

    // @include bp("> 1024px") {
    // 	float: right;
    // 	width: calc( 75% - 6px );
    // }

    .ui {
        @extend %reset-list;
        @extend %sf;
    }

    li {
        @extend %border-box;
        @include no-bullet;
        @include font-size(26px);
        @include grid(500px 2 22px, 700px 3, 1000px 4);
        display: block;
        background-image: none;
        margin-top: 0;
        margin-bottom: 22px;
        padding: 9px 20px;
        position: relative;
        font-family: $font-normal;

        @for $i from 1 through length($volny-cas-colours) {
            &:nth-child(#{$i}) {
                background-color: nth($volny-cas-colours, $i);
            }
        }

        a {
            display: block;
        }

        @include link {
            color: #ffffff;
            text-decoration: none;
        }

        @include link-over {
            text-decoration: underline;
        }
    }

    strong {
        font-weight: normal;
    }
}

.obalmenunavrat {
    margin-bottom: 30px;
}

.menunavrat {
    color: #ffffff;
    font-family: $font-fett;
    font-size: rem(24px);
    font-weight: bold;
    margin: 30px -20px 0 -20px;

    a {
        background-color: $color-main;
        display: block;
        padding: 15px 22px 12px 22px;
        @extend %truncate-text;

        .dgm &,
        .gustavmahler &,
        .htradice & {
            background-color: #007d90;
        }

        .odpady & {
            background-color: #00893b;
        }
        .turista & {
            background-color: $color-turista;
        }
        .sport & {
            background-color: $color-sport;
        }

        .doprava & {
            background-color: #007aae;
        }

        .podzemi & {
            background-color: $color-podzemi;
        }

        &::before {
            content: url(images/arr-nav-back.svg);
            margin-right: 12px;
            vertical-align: middle;
            position: relative;
            top: -0.1em;
        }
    }

    .navrat-fixed & {
        margin: 0;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 60;
        border-bottom: 6px rgba(#aaaaaa, 0.49) solid;

        + .mapa-stranek-2016 {
            padding-top: 72px;
        }
    }

    @include link {
        color: #ffffff;
        text-decoration: none;
    }

    @include link-over {
        text-decoration: underline;
    }

    @include bp('> 600px') {
        display: none;
    }
}

.mapa-stranek-2016 {
    h3.souvisejiciodkazy {
        @extend %vh;
    }
}

@include bp('<= 600px') {
    .mapa-stranek-2016 .popis:first-child {
        display: none;
    }
}

.mapa-stranek-2016 .odkazy.souvisejici {
    margin: 0 -20px 50px -20px;
    background-color: $color-main;
    color: #ffffff;
    padding: 10px 0 30px 0;

    .dgm &,
    .gustavmahler &,
    .htradice & {
        background-color: #007d90;
    }

    .odpady & {
        background-color: #00893b;
    }
    .turista & {
        background-color: $color-turista;
    }

    .sport & {
        background-color: $color-sport;
    }

    .doprava & {
        background-color: #007aae;
    }

    .podzemi & {
        background-color: $color-podzemi;
    }

    li {
        @extend %border-box;
        @include no-bullet;
        clear: none;
        padding: 6px 0 0 0;
        margin-top: 0;
        margin-bottom: 0;

        div {
            display: none;
        }

        a {
            font-size: rem(16px);
            line-height: 1.1;
            font-family: $font;
            padding: 8px 22px;
            display: block;

            &::after {
                content: url(images/arr-nav.svg);
                vertical-align: middle;
                position: relative;
                float: right;
                top: 0.3em;
            }
        }

        @include link {
            color: #ffffff;
            text-decoration: none;
        }

        @include link-over {
            text-decoration: underline;
        }
    }

    @include bp('> 600px') {
        margin-top: 30px;
        margin-left: 0;
        margin-right: 0;
        background-color: transparent !important;
        color: #666666;
        padding: 0;

        li {
            margin-bottom: 25px;
            border-top: 8px #d6a4a6 solid;
            position: relative;

            .dgm &,
            .gustavmahler &,
            .htradice & {
                border-top-color: #b2cfd7;
            }

            .namesti &,
            .probereme & {
                border-top-color: #a78989;
            }

            .odpady & {
                border-top-color: #6d9a0b;
            }
            .turista & {
                border-top-color: #c2eabd;
            }
            .sport & {
                border-top-color: #6bb2d2;
            }

            .kultura & {
                border-top-color: #7789d2;
            }
            .festival & {
                border-top-color: #cec5c1;
            }

            .zdrave-mesto & {
                border-top-color: #fbb104;
            }

            .doprava & {
                border-top-color: #88bddc;
            }

            .podzemi & {
                border-top-color: #b30970;
            }

            .bezodpadu & {
                border-top-color: #dec08a;
            }

            div {
                margin-top: 6px;
                display: block;
            }

            a {
                font-family: $font-normal;
                padding: 0;
                font-size: rem(22px);
                display: inline;

                &::after {
                    content: normal;
                }

                &::before {
                    @include fill;
                    content: '';
                }
            }

            @include link {
                color: $color-main;

                .dgm &,
                .gustavmahler &,
                .htradice & {
                    color: #007d90;
                }

                .namesti &,
                .probereme & {
                    color: #a78989;
                }

                .odpady & {
                    color: #6d9a0b;
                }

                .turista & {
                    color: $color-turista;
                }

                .sport & {
                    color: $color-sport;
                }
                .kultura & {
                    color: $color-kultura;
                }
                .festival & {
                    color: $color-festival;
                }

                .zdrave-mesto & {
                    color: #fbb104;
                }

                .doprava & {
                    color: #88bddc;
                }

                .bezodpadu & {
                    color: #6d9a0b;
                }

                .podzemi & {
                    color: $color-podzemi;
                }
            }

            &:hover {
                border-top-color: $color-main;

                .dgm &,
                .podzemi &,
                .gustavmahler &,
                .htradice & {
                    border-top-color: #159aa9;
                }

                .namesti &,
                .probereme & {
                    border-top-color: #c46164;
                }

                .odpady & {
                    border-top-color: #00893b;
                }

                .turista & {
                    border-top-color: $color-turista;
                }

                .sport & {
                    border-top-color: $color-sport;
                }

                .kultura & {
                    border-top-color: $color-kultura;
                }

                .festival & {
                    border-top-color: $color-festival;
                }

                .zdrave-mesto & {
                    border-top-color: #de5b03;
                }

                .doprava & {
                    border-top-color: #0090cc;
                }

                .podzemi & {
                    border-top-color: $color-podzemi;
                }
            }
        }
    }

    body:not(.noleftpanel) & li {
        @include grid(740px 2 2, 940px 3 3);
    }

    .noleftpanel & li {
        @include grid(650px 2 2, 880px 3 3, 1080px 4 3);
    }
}

ul.ui li dl {
    margin-left: 0;
}

.dok .ui ul.inline {
    display: inline;

    li {
        display: inline;
        padding-left: 0;
    }
}

// dokumenty na titulce
#kotva2.aktualne {
    border-bottom: 3px solid #cbcbcb;
    margin-bottom: 32px;

    .cvi {
        font: 400 rem(34px) $font-mager;
        padding: 11px 20px;
        background: $color-main;
        color: white;
        display: inline-block;
    }

    .zpravy {
        font: 400 rem(19px) $font;
        color: #666666;
        float: right;
        display: block;
        padding-top: 10px;

        strong {
            color: #2d2d2d;
            font-weight: 900;
        }

        @include bp('<= 600px') {
            display: none;
        }
    }
}

h2.aktualne {
    padding: 0 32px;
    border-bottom: 3px solid #cbcbcb;
    margin-bottom: 32px;

    .cvi {
        font: 400 rem(34px) $font-mager;
        padding: 11px 20px;
        background: $color-main;
        color: white;
        display: inline-block;
    }

    .zpravy {
        font: 400 rem(19px) $font;
        color: #666666;
        float: right;
        display: block;
        padding-top: 10px;

        strong {
            color: #2d2d2d;
            font-weight: 900;
        }

        @include bp('<= 600px') {
            display: none;
        }
    }
}

#pozicovani {
    //padding-bottom: 30px;
    padding-bottom: 60px;

    .obaldalsi {
        // border-bottom: 3px solid #cbcbcb;
        padding-right: 34px;
    }

    .cards {
        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
        }

        li {
            margin: 0;
            box-sizing: border-box;
            padding: 0 0 35px;
            clear: right;
            position: relative;

            strong a {
                color: $color-main;
                font-family: $font-fett;
                font-size: rem(24px);

                &:hover {
                    color: #2d2d2d;
                }
            }

            .dok-popis:not(:empty) {
                color: #666666;
                font-family: $font;
                font-size: rem(18px);
                padding-top: 16px;
            }

            .dok-upoutavka {
                float: left;
                width: 50%;
                max-width: 320px;
                margin-right: 20px;
            }

            .dok-datum {
                color: #2d2d2d;
                font-weight: 700;
                font-size: rem(18px);
                font-family: $font;
                padding-top: 16px;
            }
            @include bp('> 800px') {
                width: 48.2%;
                float: right;
            }
        }

        &.hlavni-aktualita {
            strong a {
                font-size: rem(36px);
            }

            .dok-upoutavka {
                width: 100%;
                display: block;
                margin-bottom: 16px;
                max-width: 100%;
                float: none;
                padding: 0;
            }

            @include bp('> 800px') {
                li {
                    float: left;
                }
            }
        }
    }

    .cards2 {
        ul {
            border-top: 3px solid #cbcbcb;
            padding: 35px 0 0;
            margin: 0;
            list-style-type: none;
        }

        li {
            margin-top: 0;
            margin-bottom: 0;
            box-sizing: border-box;
            padding: 0 0 35px;
            clear: right;
            position: relative;
            @include grid(901px 2 3%);

            strong a {
                color: $color-main;
                font-family: $font-fett;
                font-size: rem(24px);

                &:hover {
                    color: #2d2d2d;
                }
            }

            .dok-popis:not(:empty) {
                color: #666666;
                font-family: $font;
                font-size: rem(18px);
                padding-top: 16px;
            }

            .dok-upoutavka {
                float: left;
                width: 35%;
                max-width: 240px;
                background-color: #e2e2e2;
                margin-right: 20px;
            }

            .dok-datum {
                color: #2d2d2d;
                font-weight: 700;
                font-size: rem(18px);
                font-family: $font;
                padding-top: 16px;
            }
        }
    }

    @include bp('>= 650px') {
        .skryty {
            display: none;
        }
    }
}

.profil.oci .uprednostnene-aktuality + .cards {
    > ul {
        @include max(799px) {
            display: grid;
        }
    }
}

.personalni-info {
    margin: 25px 0;
}

.personalni-nabidky {
    margin-bottom: 40px;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        .personalni-nabidka {
            background-color: #e6e8e8;
            padding: 10px;

            .dok-nazev strong {
                @include link {
                    color: $color-main;
                    font-size: rem(20px);
                    font-weight: bold;
                    text-decoration: none;
                    font-family: $font-normal;
                }

                @include link-over {
                    text-decoration: underline;
                }
            }

            .dok-vice {
                text-align: center;

                @include min(600px) {
                    text-align: right;
                }

                @include link {
                    padding: 8px;
                    font-size: rem(17px);
                    background-color: $color-main;
                    color: white;
                    font-weight: bold;
                    font-family: $font-normal;
                    text-decoration: none;
                }

                @include link-over {
                    text-decoration: underline;
                }
            }

            .dok-text {
                display: grid;
                grid-gap: 25px;
                align-items: flex-end;

                @include min(600px) {
                    grid-template-columns: 1fr max-content;
                }
            }
        }
    }
}

#kalendarAkci > .dok {
    li strong img {
        max-width: 300px;
        @include min(800px) {
            width: 300px;
        }
    }
}
