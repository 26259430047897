@charset "UTF-8";
/*! ECCO SASS knihovna
    @version: 1.0.4 */
/*****************************************
* ECCO - GLOBAL VARIABLES
*****************************************/
/*****************************************
* ECCO - FUNCTIONS
*****************************************/
/*****************************************
* ECCO - MIXINS
*****************************************/
/*****************************************
* ECCO - PLACEHOLDERS
*****************************************/
@import url("https://fonts.bunny.net/css?family=roboto:100,300,400,500,700,900,700i,500i,400i,300i,100i,900i");
#menu1 li, #kalakci .mesice, #hledani .btn, #titul .zalozky li, #keskryti, .galerie-2016 .ui li, .obrodkazy li, .dalsi, body, h2.cvi,
#kotva.kotva,
.poz h2.cist, #menu ul, #menu1 ul, .bezjs ul, .zalozky ul, .galerie-2016 .ui, .volny-cas-odkazy .ui, .titulodkazy .ui, .osnova .ui, .temata ul.ui, .vice-akci .inline, .obrodkazy .ui, .dok ul, #jazyky ul, #lista ul, .profil.oci.noleftpanel .obsah#dokument .jezkovi-oci-vybery ul,
.profil.oci.noleftpanel .obsah#dokument .jezkovi-oci-vybery li, .akce-podle-data .inline, #nadpatou .ui {
  margin: 0;
  padding: 0;
}

#menu ul, #menu1 ul, .bezjs ul, .zalozky ul, .galerie-2016 .ui, .volny-cas-odkazy .ui, .titulodkazy .ui, .osnova .ui, .temata ul.ui, .vice-akci .inline, .obrodkazy .ui, .dok ul, #jazyky ul, #lista ul, .profil.oci.noleftpanel .obsah#dokument .jezkovi-oci-vybery ul,
.profil.oci.noleftpanel .obsah#dokument .jezkovi-oci-vybery li, .akce-podle-data .inline, #nadpatou .ui {
  list-style-type: none;
}

#hledani label.label-skryty::after, .titulformular label.label-skryty::after, #nazev a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.menunavrat a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mapa-stranek-2016 h3.souvisejiciodkazy {
  position: absolute;
  left: -9999em;
  top: 0;
  width: 0;
  height: 0;
  line-height: 0;
  overflow: hidden;
  outline: 0;
}

.galerie-2016 .ui li, .nahledy div > a, .mapa-stranek-2016 .odkazy.souvisejici li, .volny-cas-odkazy li, .vypisakci-titul .dok li, .vypisakci-titul, .odkazy .ui li li::before, .aktuality2 li.bezobr li::before, .utvary .ui li.o li::before, .kategorie .ui li li::before, .utvary .ui li li::before, .galerie .ui li li::before, .volny-cas .volny-cas-info, #pozicovani .dok li, #stred,
#vpravo,
#vlevo, .centrovany {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.oci.profil #upozorneni::after, .zalozky ul::after, .galerie-2016 .ui::after, .volny-cas-odkazy .ui::after, .temata ul.ui::after, #jazyky::after, #hlava::after, .profil.oci.noleftpanel .obsah#dokument .jezkovi-oci-vybery::after, .profil.oci .aktuality-obal::after, .centrovany::after {
  content: "";
  display: table;
  font-size: 0;
  clear: both;
}

/*****************************************
* ECCO - FLEXBOX
*****************************************/
.nahledy {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -moz-box-wrap: nowrap;
  -webkit-box-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  -ms-flexbox-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.nahledy {
  -moz-box-wrap: wrap;
  -webkit-box-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flexbox-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/*************************
* GLOBÁLNÍ PROMĚNNÉ
*************************/
/************************/
/*******************
* NADPISY
*******************/
h1.cvi {
  font-size: 1.125rem;
  padding: 5px 0 0 5px;
  line-height: 1.1;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  font-family: Roboto;
  font-weight: normal;
  display: inline-block;
}
h1.cvi strong {
  font-weight: normal;
}
.international.bez-znaku h1.cvi, .profil.bez-znaku h1.cvi, .profil-str.bez-znaku h1.cvi {
  padding-left: 0;
  position: static;
  font-size: 3.75rem;
  color: #ca1517;
  font-family: TeutonMagerWeb;
}
[lang=uk] .international.bez-znaku h1.cvi, [lang=uk] .profil.bez-znaku h1.cvi, [lang=uk] .profil-str.bez-znaku h1.cvi {
  font-family: TeutonHellWeb;
}
.international.bez-znaku h1.cvi strong, .profil.bez-znaku h1.cvi strong, .profil-str.bez-znaku h1.cvi strong {
  font-family: TeutonFettWeb;
}
.profil.oci h1.cvi {
  font-family: TeutonFettWeb;
  font-size: 3.125rem;
  text-transform: uppercase;
  color: #ca1517;
}
.profil.oci h1.cvi span {
  display: block;
  font: 400 1rem Roboto;
  color: #666666;
  text-transform: none;
  letter-spacing: 0.025em;
}
.profil.oci h1.cvi span strong {
  font-weight: 900;
}
.profil.bez-znaku.dgm h1.cvi, .profil.bez-znaku.podzemi h1.cvi, .profil-str.bez-znaku.dgm h1.cvi, .profil.bez-znaku.gustavmahler h1.cvi, .profil-str.bez-znaku.gustavmahler h1.cvi, .profil.bez-znaku.htradice h1.cvi, .profil-str.bez-znaku.htradice h1.cvi {
  color: #159aa9;
}
.profil.bez-znaku.turista h1.cvi, .profil-str.bez-znaku.turista h1.cvi {
  color: #46983b;
}
.profil.bez-znaku.informacni-portal h1.cvi, .profil-str.bez-znaku.informacni-portal h1.cvi {
  color: #565656;
}
.profil.bez-znaku.odpady h1.cvi, .profil-str.bez-znaku.odpady h1.cvi {
  color: #00893b;
}
.profil.bez-znaku.kultura h1.cvi, .profil-str.bez-znaku.kultura h1.cvi {
  color: #324ab0;
}
.profil.bez-znaku.festival h1.cvi, .profil-str.bez-znaku.festival h1.cvi {
  color: #69554b;
}
.profil.bez-znaku.doprava h1.cvi, .profil-str.bez-znaku.doprava h1.cvi {
  color: #fe697c;
}
.profil.bez-znaku.podzemi h1.cvi, .profil-str.bez-znaku.podzemi h1.cvi {
  color: #a3043e;
}
.profil.bez-znaku.bezodpadu h1.cvi, .profil-str.bez-znaku.bezodpadu h1.cvi {
  color: #6d9a0b;
}
.profil.bez-znaku.arena h1.cvi, .profil-str.bez-znaku.arena h1.cvi {
  color: #600724;
  text-transform: lowercase;
  margin: 0 0 0.075em -0.09em;
}
.profil.bez-znaku.arena h1.cvi::before, .profil.bez-znaku.arena h1.cvi::after, .profil-str.bez-znaku.arena h1.cvi::before, .profil-str.bez-znaku.arena h1.cvi::after {
  font-size: 4.6875rem;
  font-weight: bold;
  font-family: TeutonNormalWeb;
}
.profil.bez-znaku.arena h1.cvi::before, .profil-str.bez-znaku.arena h1.cvi::before {
  content: "[";
}
.profil.bez-znaku.arena h1.cvi::after, .profil-str.bez-znaku.arena h1.cvi::after {
  content: "]";
}

h1.logo {
  margin: 0 20px 0 0;
}
.havireni h1.logo #znak {
  width: 200px;
}
@media screen and (min-width: 1025px) {
  .havireni h1.logo #znak {
    width: auto;
  }
}

h2.cvi,
h2.cist,
h3.cvi,
h3.ud,
h4.ud,
#prohlaseni h3 {
  font-family: Roboto;
}

h2.cvi,
#kotva.kotva,
.poz h2.cist {
  font-size: 1.9375rem;
  color: #ca1517;
  font-weight: normal;
  font-family: TeutonMagerWeb;
  line-height: 1.1;
}
[lang=uk] h2.cvi,
[lang=uk] #kotva.kotva,
[lang=uk] .poz h2.cist {
  font-family: TeutonHellWeb;
}
h2.cvi span,
#kotva.kotva span,
.poz h2.cist span {
  display: inline-block;
  padding: 15px 32px;
}

#zahlavi h2.cvi {
  margin: 58px 0 10px 0;
  padding: 0;
  display: inline-block;
}

#zahlavi h2.cvi,
#zalozkynadpis h2.cvi {
  font-size: 2.75rem;
  color: #ca1517;
  line-height: 1;
}
.dgm #zahlavi h2.cvi, .gustavmahler #zahlavi h2.cvi, .htradice #zahlavi h2.cvi,
.dgm #zalozkynadpis h2.cvi,
.gustavmahler #zalozkynadpis h2.cvi,
.htradice #zalozkynadpis h2.cvi {
  color: #159aa9;
}
.odpady #zahlavi h2.cvi,
.odpady #zalozkynadpis h2.cvi {
  color: #00893b;
}
.turista #zahlavi h2.cvi,
.turista #zalozkynadpis h2.cvi {
  color: #46983b;
}
.sport #zahlavi h2.cvi,
.sport #zalozkynadpis h2.cvi {
  color: #048fcd;
}
.doprava #zahlavi h2.cvi,
.doprava #zalozkynadpis h2.cvi {
  color: #0090cd;
}
.podzemi #zahlavi h2.cvi,
.podzemi #zalozkynadpis h2.cvi {
  color: #a3043e;
}
.kultura #zahlavi h2.cvi,
.kultura #zalozkynadpis h2.cvi {
  color: #324ab0;
}
.festival #zahlavi h2.cvi,
.festival #zalozkynadpis h2.cvi {
  color: #69554b;
}

h3.cvi, h3.ud, #prohlaseni h3 {
  font-size: 1.3125rem;
  font-weight: normal;
  margin: 25px 0 5px 0;
  padding: 8px 20px 4px 0;
  line-height: 1.2;
  color: #585758;
  font-family: TeutonNormalWeb;
}

#prohlaseni h4 {
  font-size: 1.125rem;
  line-height: 1.2;
}

h4.ud {
  font-size: 1.25rem;
}
h4.ud + .udz {
  margin-top: 28px;
}

/**********************
* OSTATNÍ TEXTY
**********************/
form input,
form textarea,
form button,
form label,
form select {
  font-size: 0.875rem;
}

#kalakci .floatingBubbleElm p {
  font-size: 1rem;
}
#kalakci td,
#kalakci th {
  font-size: 0.9375rem;
}

.vzzalozky,
#rejstrik ul.rejstrikpismena {
  font-size: 1.0625rem;
}

@font-face {
  font-family: "TeutonFettWeb";
  font-weight: bold;
  font-style: normal;
  src: url("/ASPINCLUDE/vismoWeb5/html/fonty/teutonfettbold-web.woff2") format("woff2"), url("/ASPINCLUDE/vismoWeb5/html/fonty/teutonfettbold-web.woff") format("woff"), url("/ASPINCLUDE/vismoWeb5/html/fonty/teutonfettbold-web.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "TeutonNormalWeb";
  font-weight: bold;
  font-style: normal;
  src: url("/ASPINCLUDE/vismoWeb5/html/fonty/teutonnormalbold-web.woff2") format("woff2"), url("/ASPINCLUDE/vismoWeb5/html/fonty/teutonnormalbold-web.woff") format("woff"), url("/ASPINCLUDE/vismoWeb5/html/fonty/teutonnormalbold-web.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "TeutonMagerWeb";
  font-weight: normal;
  font-style: normal;
  src: url("/ASPINCLUDE/vismoWeb5/html/fonty/teutonmager-web.woff2") format("woff2"), url("/ASPINCLUDE/vismoWeb5/html/fonty/teutonmager-web.woff") format("woff"), url("/ASPINCLUDE/vismoWeb5/html/fonty/teutonmager-web.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "TeutonHellWeb";
  font-weight: normal;
  font-style: normal;
  src: url("/ASPINCLUDE/vismoWeb5/html/fonty/teutonhell-web.woff2") format("woff2"), url("/ASPINCLUDE/vismoWeb5/html/fonty/teutonhell-web.woff") format("woff"), url("/ASPINCLUDE/vismoWeb5/html/fonty/teutonhell-web.ttf") format("truetype");
  font-display: swap;
}
html {
  line-height: 1.375;
  font-size: 18px;
  font-family: Roboto;
}
@media screen and (min-width: 601px) {
  html {
    font-size: 16px;
  }
}

body.editor {
  background-color: #ffffff;
  font-size: 18px;
  font-family: Roboto;
}
@media screen and (min-width: 601px) {
  body.editor {
    font-size: 16px;
  }
}
body.editor h3 {
  font-size: 1.375rem;
  font-family: Roboto;
  font-weight: normal;
}

body,
.editor {
  font-size: 1em;
}

body,
.automat {
  color: #666666;
}

a,
a:link,
a:visited {
  color: #a53740;
  text-decoration: underline;
}
.dgm a, .gustavmahler a, .htradice a,
.dgm a:link,
.gustavmahler a:link,
.htradice a:link,
.dgm a:visited,
.gustavmahler a:visited,
.htradice a:visited {
  color: #007d90;
}
.odpady a,
.odpady a:link,
.odpady a:visited {
  color: #00893b;
}
.turista a,
.turista a:link,
.turista a:visited {
  color: #46983b;
}
.doprava a,
.doprava a:link,
.doprava a:visited {
  color: #007aae;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

hr,
hr.oddelovac {
  color: #dddddd;
  background-color: #dddddd;
}

input,
button,
textarea,
select {
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
}

.zvyrazneni,
.vystraha,
.strlistovani strong {
  color: #ca1517;
  background-color: #ffffff;
  padding: 0 3px;
}

.vystraha {
  border-radius: 3px;
}

.zvyrazneni2 {
  background-color: #303030;
  color: #ffffff;
  padding: 0 2px;
}

strong.zprava {
  font-size: 1.1em;
}

img {
  max-width: 100%;
  height: auto;
}

.editor .personalni-detail {
  color: #666;
}
.editor .personalni-detail .te-fett {
  font-weight: bold;
  font-family: TeutonFettWeb;
  font-size: 1.25rem;
}
.editor .personalni-detail .te-main {
  color: #ca1517;
}
.editor .personalni-detail .te-dull {
  color: #6f6f6e;
}
.editor .personalni-detail br {
  line-height: 0.6;
}
.editor .personalni-detail ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.editor .personalni-detail ul li {
  list-style-type: circle;
  list-style-position: outside;
  margin-left: 20px;
}

/**************************
* DEFINICE PROMĚNNÝCH
**************************/
/*************************/
/**************************
* OBECNÉ
**************************/
html,
body {
  background-color: #ffffff;
}

body {
  text-align: center;
}

a {
  text-decoration-skip-ink: auto;
}

::-moz-selection {
  background-color: #ca1517;
  color: #ffffff;
}

::selection {
  background-color: #ca1517;
  color: #ffffff;
}

#fuck-ie8 {
  position: fixed;
  z-index: 300;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent url(/html/images/alpha.png) repeat 0 0;
  width: 100%;
  height: 100%;
  display: table;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

#ie8-modal-wrap {
  display: table-cell;
  vertical-align: middle;
}

#ie8-modal {
  background-color: #ffffff;
  border-radius: 5px;
  text-align: center;
  width: 420px;
  padding: 20px;
  margin: 0 auto;
}

#ie8-modal img,
#ie8-modal p {
  margin-bottom: 30px;
}

#ie8-modal a {
  display: inline-block;
  border-radius: 3px;
  background-color: #2b985f;
  padding: 8px 25px;
}

#ie8-modal a,
#ie8-modal a:link,
#ie8-modal a:visited {
  text-decoration: none;
  color: #ffffff;
}

#ie8-modal a:hover,
#ie8-modal a:focus,
#ie8-modal a:active {
  text-decoration: underline;
}

/*************************
* HLAVNÍ OBSAH
*************************/
.centrovany {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  text-align: left;
}
.centrovany--padded-left {
  padding-left: 20px;
}
.centrovany--padded-right {
  padding-right: 20px;
}
.centrovany--padded-both {
  padding-left: 20px;
  padding-right: 20px;
}
.centrovany--padded-border-bottom {
  margin-bottom: 60px;
  border-bottom: 3px solid #cbcbcb;
}
.centrovany--padded-bottom {
  padding-bottom: 60px;
}
.centrovany .unshift-left {
  margin-left: -20px;
}
.centrovany .unshift-right {
  margin-right: -20px;
}
@media screen and (min-width: 601px) {
  .centrovany--padded-left {
    padding-left: 32px;
  }
  .centrovany--padded-right {
    padding-right: 32px;
  }
  .centrovany--padded-both {
    padding-left: 32px;
    padding-right: 32px;
  }
  .centrovany .unshift-left {
    margin-left: -32px;
  }
  .centrovany .unshift-right {
    margin-right: -32px;
  }
}
@media screen and (min-width: 1025px) {
  .centrovany--padded-both {
    padding-left: 100px;
    padding-right: 100px;
  }
  .centrovany--padded-left {
    padding-left: 100px;
  }
  .centrovany--padded-right {
    padding-right: 100px;
  }
  .centrovany .unshift-left {
    margin-left: -100px;
  }
  .centrovany .unshift-right {
    margin-right: -100px;
  }
}

#stranka #celek {
  padding-left: 20px;
}
@media screen and (min-width: 601px) {
  #stranka #celek {
    padding-left: 0;
  }
}

#stranka.profil.oci #celek {
  padding-right: 0;
}

#stred,
#vpravo,
#vlevo {
  width: 100%;
  float: none;
  margin-bottom: 30px;
}

#vpravo,
#vlevo {
  word-wrap: break-word;
}

body:not(.profil.oci) #pozicovani {
  margin-bottom: 72px;
}
body:not(.profil) #pozicovani .centrovany {
  background-color: #f2f0f0;
}
#pozicovani .poz {
  padding-top: 36px;
}
#pozicovani .poz ~ .poz .dok {
  display: none;
}
#pozicovani .poz ~ .poz .mobile-link {
  margin: 0;
  display: block;
}
#pozicovani .poz ~ .poz .mobile-link a {
  display: block;
  text-align: center;
  padding: 12px 1em;
  background-color: #ca1517;
}
#pozicovani .poz ~ .poz .mobile-link a,
#pozicovani .poz ~ .poz .mobile-link a:link,
#pozicovani .poz ~ .poz .mobile-link a:visited {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.625rem;
  font-family: TeutonMagerWeb;
}
#pozicovani .poz ~ .poz .mobile-link a:hover,
#pozicovani .poz ~ .poz .mobile-link a:focus,
#pozicovani .poz ~ .poz .mobile-link a:active {
  text-decoration: underline;
}
#pozicovani .poz ~ .poz h2,
#pozicovani .poz ~ .poz .obaldalsi {
  display: none;
}
@media screen and (min-width: 650px) {
  #pozicovani .poz ~ .poz .dok,
  #pozicovani .poz ~ .poz .obaldalsi {
    display: block;
  }
  #pozicovani .poz ~ .poz .mobile-link {
    display: none;
  }
  #pozicovani .poz ~ .poz h2 {
    display: block;
  }
}
@media screen and (min-width: 650px) {
  #pozicovani .skryty {
    display: none;
  }
}
.profil #pozicovani h2.cvi {
  font-size: 2.75rem;
}
#pozicovani .dok {
  margin-bottom: 22px;
}
@media screen and (min-width: 601px) {
  #pozicovani .dok ul {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -moz-box-wrap: nowrap;
    -webkit-box-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    -ms-flexbox-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -moz-box-wrap: wrap;
    -webkit-box-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flexbox-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
#pozicovani .dok li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  margin-top: 27px;
  margin-bottom: 0;
  position: relative;
  padding: 10px 20px 18px 20px;
  background-color: rgba(255, 255, 255, 0.6);
}
@media screen and (max-width: 599px) {
  #pozicovani .dok li {
    width: 100%;
  }
}
@media screen and (min-width: 600px) and (max-width: 1023px) {
  #pozicovani .dok li {
    width: calc( 50% - 10px );
    margin-left: 20px;
  }
  #pozicovani .dok li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1024px) {
  #pozicovani .dok li {
    width: calc( 33.3333333333% - 13.3333333333px );
    margin-left: 20px;
  }
  #pozicovani .dok li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
.profil #pozicovani .dok li {
  padding: 6px 0 18px 0;
}
.havireni #pozicovani .dok li > strong:first-child a,
.havireni #pozicovani .dok li > strong:first-child a:link,
.havireni #pozicovani .dok li > strong:first-child a:visited {
  color: #25af4f;
}
#pozicovani .dok li::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -21px;
  background-color: #ca1517;
  height: 13px;
}
.dgm #pozicovani .dok li::after, .gustavmahler #pozicovani .dok li::after, .htradice #pozicovani .dok li::after {
  background-color: #b2cfd7;
}
.namesti #pozicovani .dok li::after, .probereme #pozicovani .dok li::after {
  background-color: #a78989;
}
.odpady #pozicovani .dok li::after {
  background-color: #6d9a0b;
}
.zdrave-mesto #pozicovani .dok li::after {
  background-color: #fbb104;
}
.doprava #pozicovani .dok li::after {
  background-color: #88bddc;
}
.podzemi #pozicovani .dok li::after {
  background-color: #c36065;
}
.havireni #pozicovani .dok li::after {
  background-color: #b2d7bd;
}
.dgm #pozicovani .dok li:hover::after, .gustavmahler #pozicovani .dok li:hover::after, .htradice #pozicovani .dok li:hover::after {
  background-color: #159aa9;
}
.namesti #pozicovani .dok li:hover::after, .probereme #pozicovani .dok li:hover::after {
  background-color: #c46164;
}
.odpady #pozicovani .dok li:hover::after {
  background-color: #00893b;
}
.zdrave-mesto #pozicovani .dok li:hover::after {
  background-color: #de5b03;
}
.doprava #pozicovani .dok li:hover::after {
  background-color: #0090cc;
}
.podzemi #pozicovani .dok li:hover::after {
  background-color: #a3043e;
}
.havireni #pozicovani .dok li:hover::after {
  background-color: #15a82f;
}
#pozicovani .dok li.jihlavaslozka strong a {
  color: #6c6b6b;
}
#pozicovani .dok li.jihlavaslozka::after {
  background-color: #b3b3b2;
}

/*************************************
* TABULKOVÝ LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/
#vlevo {
  display: none;
}

@media screen and (min-width: 601px) {
  #telo {
    display: table;
    table-layout: fixed;
    border-spacing: 0;
    width: 100%;
  }
  #stred,
  #vlevo {
    display: table-cell;
    vertical-align: top;
  }
  #stred {
    width: auto;
    padding-left: 24px;
  }
  .noleftpanel #stred {
    padding-left: 46px;
  }
  .oci.profil.noleftpanel #stred {
    padding-left: 0;
    padding-right: 0 !important;
  }
  #vlevo {
    width: 292px;
  }
}
@media screen and (min-width: 861px) {
  #vlevo {
    width: 352px;
  }
}
/*************************************
* FLOATOVANÝ LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/
/************************
* PRVKY STRÁNKY
************************/
.obalcesta {
  background-color: #f6f6f6;
  padding: 15px 0;
}

.cesta {
  padding: 0 20px;
  margin: 0;
  position: relative;
  font-weight: 200;
  color: #666666;
}
@media screen and (min-width: 651px) {
  .cesta {
    padding: 0 0 0 46px;
  }
}
.cesta a::after {
  content: url(images/arr-path.svg);
  margin: 0 0.72em;
}
.cesta a:last-of-type::after {
  content: normal;
}
.cesta span::before {
  content: url(images/arr-path.svg);
  margin: 0 0.72em;
}
.cesta strong + span::before {
  content: normal;
}
.cesta a,
.cesta a:link,
.cesta a:visited {
  color: #666666;
}

.noleftpanel .dok,
.noleftpanel .perex,
.noleftpanel .uvodprvku,
.noleftpanel .editor,
.noleftpanel .bezny {
  max-width: 70ch;
}

.noleftpanel.profil.oci .dok,
.noleftpanel.profil.oci .perex,
.noleftpanel.profil.oci .uvodprvku,
.noleftpanel.profil.oci .editor,
.noleftpanel.profil.oci .bezny {
  max-width: 1280px;
}

.box {
  padding: 0 15px 10px 15px;
  background: #cccccc;
  margin: 0 0 15px 0;
}

.dalsi {
  font-size: 1.125rem;
  text-align: right;
  clear: both;
  position: relative;
}
.dalsi a {
  background-color: #ca1517;
  display: inline-block;
  padding: 8px 15px 10px 15px;
  line-height: 1.1;
}
.dalsi a.nahoru {
  text-decoration: none !important;
  text-align: center;
  min-width: 18px;
  margin-left: 7px;
  background-color: #b3b3b2;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url(images/arr-top.svg);
}
.dalsi a.nahoru::before {
  color: transparent;
  content: "n";
}
.dalsi a,
.dalsi a:link,
.dalsi a:visited {
  text-decoration: none;
  color: #ffffff;
}
.dalsi a:hover,
.dalsi a:focus,
.dalsi a:active {
  text-decoration: underline;
}

/************************
* ZÁPATÍ
************************/
#nadpatou {
  color: #444444;
}
#nadpatou #content-odkazy {
  background: #d7d7d7;
}
#nadpatou a,
#nadpatou a:link,
#nadpatou a:visited {
  color: #444444;
}
#nadpatou .footer-col {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  margin-bottom: 30px;
}
@media screen and (max-width: 499px) {
  #nadpatou .footer-col {
    width: 100%;
  }
}
@media screen and (min-width: 500px) and (max-width: 899px) {
  #nadpatou .footer-col {
    width: calc( 50% - 11px );
    margin-left: 22px;
  }
  #nadpatou .footer-col:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 900px) {
  #nadpatou .footer-col {
    width: calc( 25% - 16.5px );
    margin-left: 22px;
  }
  #nadpatou .footer-col:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}
.topakce #nadpatou .footer-col {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
}
@media screen and (max-width: 699px) {
  .topakce #nadpatou .footer-col {
    width: 100%;
  }
}
@media screen and (min-width: 700px) {
  .topakce #nadpatou .footer-col {
    width: calc( 33.3333333333% - 14.6666666667px );
    margin-left: 22px;
  }
  .topakce #nadpatou .footer-col:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
#nadpatou .footer-col + .sf {
  display: none;
}
#nadpatou .footer-col.heading-less::before {
  padding: 18px 0 4px 0;
  display: block;
  content: "Odsazení";
  color: transparent;
  font-family: TeutonNormalWeb;
  line-height: 1.2;
  font-size: 1.3125rem;
}
#nadpatou h3.cvi,
#nadpatou h2.cvi {
  margin: 0;
  padding: 18px 0 4px 0;
}
#nadpatou h2.cvi {
  font-family: TeutonNormalWeb;
  font-size: 1.3125rem;
  font-weight: normal;
  line-height: 1.2;
  color: #585758;
}
#nadpatou .ui {
  margin: 0;
  padding: 0;
}
#nadpatou .ui strong {
  font-weight: normal;
}
#nadpatou .ui strong a {
  font-weight: normal;
  font-family: Roboto;
  font-size: 1rem;
}
#nadpatou .ui a,
#nadpatou .ui a:link,
#nadpatou .ui a:visited {
  text-decoration: none;
}
#nadpatou .ui a:hover,
#nadpatou .ui a:focus,
#nadpatou .ui a:active {
  text-decoration: underline;
}
#nadpatou .ui li {
  margin: 5px 0 11px 0;
}

#pata {
  position: relative;
  background-repeat: no-repeat;
  background-position: 0 100%;
  font-size: 0.875rem;
  text-align: right;
  padding-top: 30px;
  padding-bottom: 60px;
}
#pata object,
#pata .bezodpadu-logo {
  position: absolute;
  left: 0;
  bottom: 0;
}
#pata .bezodpadu-logo {
  width: 250px;
}
.dgm #pata {
  background-image: url(images/logo-dgm.svg);
  background-size: 300px auto;
}
.dgm #pata object {
  display: none;
}
@media screen and (min-width: 801px) {
  #pata {
    background-position: 0 15px;
  }
  #pata object,
  #pata .bezodpadu-logo {
    top: 15px;
    bottom: auto;
  }
}
#pata .patalogo {
  padding: 0;
}
#pata .patalogo[href*=webhouse], #pata .patalogo[href*=vismo] {
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
}
#pata .webmaster,
#pata .inline {
  display: block;
  margin: 0 0 12px 0;
  padding: 0;
}
#pata a,
#pata a:link,
#pata a:visited {
  color: #666666;
  text-decoration: underline;
}
#pata .promoted,
#pata .promoted:link,
#pata .promoted:visited {
  color: #ca1517;
}
#pata a:hover,
#pata a:focus,
#pata a:active {
  text-decoration: none;
}

.strlistovani img,
#listovani img {
  background-color: #bbbbbb;
  color: #000000;
}
.strlistovani .aktivni img,
#listovani .aktivni img {
  background-color: #555555 !important;
  color: #ffffff;
}
.strlistovani .aktivni:hover img, .strlistovani .aktivni:focus img,
#listovani .aktivni:hover img,
#listovani .aktivni:focus img {
  background-color: #bbbbbb !important;
  color: #000000;
}

.volny-cas {
  margin-bottom: 50px;
}
.volny-cas h2.cvi span {
  background-color: #a53740;
  color: #ffffff;
}
.volny-cas .volny-cas-content {
  background: #1a1b16 url(images/bg-volny-cas.jpg) no-repeat 50% 0;
  background-size: cover;
}
.volny-cas .volny-cas-info {
  font-size: 25px;
  font-size: 1.5625rem;
  background-color: rgba(255, 255, 255, 0.82);
  padding: 46px 22px;
  color: #a53740;
  font-family: TeutonMagerWeb;
  line-height: 1.8;
}
@media screen and (min-width: 1025px) {
  .volny-cas .volny-cas-info {
    float: left;
    width: calc(25% + 82px);
  }
}
.volny-cas .volny-cas-info strong {
  font-family: TeutonNormalWeb;
}

#titul .editor ul.mbannery {
  text-align: center !important;
}

/**************************************
* PROFILKA VOLNÝ ČAS
**************************************/
.columns {
  margin-bottom: 55px;
}
@media screen and (max-width: 800px) {
  .columns.centrovany--padded-right {
    padding-left: 3.85%;
  }
}
@media screen and (max-width: 600px) {
  .columns.centrovany--padded-right {
    padding-left: 20px;
  }
}
.columns .column {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
@media screen and (min-width: 801px) {
  .columns .column {
    float: left;
    width: 30.6779661017%;
  }
  .columns .column.column--bigger {
    float: right;
    width: 68.2203389831%;
  }
  .columns .column .osnova .nakal {
    content: "";
    padding-bottom: 81%;
    display: block;
    background-image: url(images/kalendar.png);
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: 100% auto;
  }
  .en .columns .column .osnova .nakal {
    background-image: url(images/kalendar_en.png);
  }
  .de .columns .column .osnova .nakal {
    background-image: url(images/kalendar_de.png);
  }
}
@media screen and (min-width: 801px) {
  .columns .column .osnova {
    padding-left: 20px;
  }
}
@media screen and (min-width: 1341px) {
  .columns .column .osnova {
    padding-left: 0;
  }
}

.halves .half {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  background-color: #f6f6f6;
  margin-bottom: 22px;
  padding: 18px 24px 24px 24px;
}
@media screen and (max-width: 649px) {
  .halves .half {
    width: 100%;
  }
}
@media screen and (min-width: 650px) {
  .halves .half {
    width: calc( 50% - 11px );
    margin-left: 22px;
  }
  .halves .half:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
.halves .half h2.cvi {
  font-family: TeutonNormalWeb;
  font-size: 1.5625rem;
  display: inline;
}
.halves .half h2.cvi::after {
  content: "|";
  margin: 0 0.35em 0 0.6em;
}

.profil-animace {
  display: none;
  text-align: center;
}
@media screen and (min-width: 751px) {
  .profil-animace {
    display: block;
  }
  .profil-animace iframe {
    margin: 0 auto;
  }
}

.akce-podle-data {
  margin: 30px 0;
}
.akce-podle-data .inline {
  display: inline;
}

table.calendar {
  background-color: #ffffff;
}

#prepinacsportoviste {
  font-weight: bold;
  padding: 20px 0 30px;
}

.sportovistepata {
  padding: 25px 0;
}

.profil.oci .volAdmin {
  clear: both;
}
.profil.oci #pozicovani {
  padding-top: 30px;
}
.profil.oci #pozicovani .cards .dok-upoutavka img {
  width: 100%;
}
@media screen and (min-width: 1001px) {
  .profil.oci.fixed-menu #obalhlava {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 99;
    box-shadow: 0 0 49px rgba(0, 0, 0, 0.16);
  }
  .profil.oci.fixed-menu#stranka #obalcelek,
  .profil.oci.fixed-menu #obalobrazek {
    padding-top: 118px;
  }
  .profil.oci .kotva {
    margin-top: -118px;
    padding-top: 118px;
  }
}
.profil.oci#stranka #hlava {
  margin-bottom: 0;
}
.profil.oci #obalobrazek #obrazek {
  padding-bottom: 150px;
}
.profil.oci .dok-upoutavka {
  position: relative;
}
.profil.oci .alt-span {
  font: 300 0.875rem Roboto;
  color: white;
  padding: 15px 20px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  right: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.75);
}
@media screen and (min-width: 600px) {
  .profil.oci .alt-span {
    display: block;
  }
}
.profil.oci .img-container {
  position: relative;
  margin: 20px 0;
}
.profil.oci .img-container .alt-span {
  right: auto;
  left: 0;
}
.profil.oci .img-container img {
  margin: 0 !important;
}
.profil.oci #hlava #profil-navrat img {
  width: 137px;
}
.profil.oci .centrovany {
  width: 94%;
  max-width: 1360px;
}
.profil.oci#titul .centrovany {
  width: 94%;
}
@media screen and (min-width: 1361px) {
  .profil.oci#titul .centrovany {
    max-width: 1100px;
  }
}
@media screen and (min-width: 1561px) {
  .profil.oci#titul .centrovany {
    max-width: 1280px;
  }
}
@media screen and (min-width: 1741px) {
  .profil.oci#titul .centrovany {
    max-width: 1360px;
  }
}
.profil.oci #content-odkazy {
  padding-left: 50px !important;
  padding-right: 50px !important;
}
.profil.oci .kontakt-ext h2.cvi {
  padding-bottom: 24px !important;
}
.profil.oci #hledani .format {
  height: 37px !important;
  width: 100% !important;
  box-sizing: border-box;
}
.profil.oci.noleftpanel .obsah#dokument .perex {
  max-width: 100%;
  font-weight: 500;
  color: #2d2d2d;
}
.profil.oci.noleftpanel .obsah#dokument .velkaupoutavka img {
  margin: 10px 0;
}
.profil.oci.noleftpanel .obsah#dokument .editor {
  max-width: 100%;
}
.profil.oci.noleftpanel .obsah#dokument .ReakcePrispevek {
  margin: 0;
}
.profil.oci.noleftpanel .obsah#dokument .jezkovi-oci-vybery {
  margin: 30px 0 0;
  border-top: 3px solid #cbcbcb;
  padding: 30px 0 0;
}
@media screen and (min-width: 901px) {
  .profil.oci.noleftpanel .obsah#dokument .jezkovi-oci-vybery {
    padding-left: 50px;
  }
}
.profil.oci.noleftpanel .obsah#dokument .jezkovi-oci-vybery li {
  min-width: 160px;
  float: left;
  display: inline-flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  box-sizing: border-box;
  font: 400 0.875rem Roboto;
  background-color: #ca1517;
  margin: 0 24px 15px 0;
  position: relative;
  text-align: center;
}
.profil.oci.noleftpanel .obsah#dokument .jezkovi-oci-vybery li a {
  color: white;
  text-decoration: none;
}
.profil.oci.noleftpanel .obsah#dokument .jezkovi-oci-vybery li a:hover {
  text-decoration: underline;
}
.profil.oci.noleftpanel .obsah#dokument .jezkovi-oci-vybery li a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
}
.profil.oci .dok-datum-oci {
  padding-top: 10px;
  padding-bottom: 20px;
  font: 700 1.25rem Roboto;
  color: #2d2d2d;
}
.profil.oci #oci-levy-sloupec {
  font: 400 1.125rem Roboto;
  color: #666666;
}
.profil.oci #oci-levy-sloupec h2.cvi {
  font-weight: 700;
}
@media screen and (min-width: 901px) {
  .profil.oci #oci-levy-sloupec {
    float: left;
    width: 67.6%;
  }
  .profil.oci #oci-levy-sloupec h2.cvi,
  .profil.oci #oci-levy-sloupec .perex,
  .profil.oci #oci-levy-sloupec .editor,
  .profil.oci #oci-levy-sloupec .ReakcePrispevek,
  .profil.oci #oci-levy-sloupec .popis.dpopis,
  .profil.oci #oci-levy-sloupec .dok-datum-oci {
    padding-left: 50px;
  }
}
.profil.oci .oci-pravy-sloupec {
  color: #666666;
  font: 400 0.9375rem Roboto;
}
.profil.oci .oci-pravy-sloupec > h2.cvi {
  background-color: #ca1517;
  color: white;
  padding: 70px 25px 30px 30px;
  margin-bottom: 20px;
}
.profil.oci .oci-pravy-sloupec .dok ul li {
  margin: 0 0 0 30px;
  padding: 20px 0;
}
.profil.oci .oci-pravy-sloupec .dok ul li strong a img {
  width: 100%;
  max-width: 360px;
  margin: 0 auto 10px;
  float: none;
  display: block;
}
.profil.oci .oci-pravy-sloupec .dok ul li div {
  padding-top: 10px;
}
.profil.oci .oci-pravy-sloupec .dok ul li div span {
  padding-top: 10px;
  display: block;
  font: 700 1rem Roboto;
  color: #2d2d2d;
}
@media screen and (min-width: 601px) {
  .profil.oci .oci-pravy-sloupec .dok ul li strong a img {
    max-width: 300px;
    margin: 0 20px 10px 0;
    float: left;
  }
}
@media screen and (min-width: 901px) {
  .profil.oci .oci-pravy-sloupec {
    float: right;
    width: 29%;
  }
  .profil.oci .oci-pravy-sloupec .dok ul li strong a img {
    max-width: 365px;
    float: none;
    margin: 0 0 10px;
  }
}

#kotvy {
  position: absolute;
  right: 0;
  top: 170px;
  text-align: left;
  display: none;
  font: 700 1.25rem TeutonFettWeb;
  background: white url(images/jezekR.png) no-repeat center top;
  border-right: 0;
  box-sizing: border-box;
  z-index: 100;
  width: 120px;
  padding-top: 70px;
}
#kotvy ul,
#kotvy li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#kotvy ul {
  border-top: 3px solid #cbcbcb;
  position: relative;
  padding-bottom: 38px;
}
#kotvy ul::after {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 38px;
  background: #cbcbcb url(images/sipkaDolu.png) no-repeat 25% center;
}
#kotvy li {
  padding: 3px 0;
}
#kotvy a {
  color: #444444;
  line-height: 1;
  padding: 10px 0 10px 20%;
  text-decoration: none;
  display: block;
  box-sizing: border-box;
}
#kotvy a:hover {
  text-decoration: underline;
}
#kotvy .akt {
  background: #ca1517;
  color: white;
}
#kotvy .akt:hover {
  text-decoration: none;
}
@media screen and (min-width: 1361px) {
  #kotvy {
    display: block;
  }
}
@media screen and (min-width: 1721px) {
  #kotvy {
    width: 175px;
  }
}

#kotvyObal.fixed {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  font-size: 1.25rem;
  width: 120px;
}
@media screen and (min-width: 1361px) {
  #kotvyObal.fixed {
    display: block;
  }
}
@media screen and (min-width: 1721px) {
  #kotvyObal.fixed {
    width: 175px;
  }
}
#kotvyObal.fixed #kotvy {
  position: fixed;
  right: auto;
}

/* Skrytí upoutávky pro ježkovy voči */
.profil.oci .unp {
  display: none;
}
.profil.oci .unp + #unpobtekane {
  margin-left: 0 !important;
}

.international-vsuvka {
  margin-bottom: 40px;
}

.kotvy-socialni {
  margin: 0 0 0 30px;
  padding: 0;
  display: inline-flex;
  align-items: center;
}
.kotvy-socialni li {
  margin: 0 12px 0 0;
  display: inline-flex;
  align-items: center;
}
.kotvy-socialni li:last-child {
  margin-right: 0;
}
.kotvy-socialni li a {
  display: inline-flex;
  align-items: center;
}
.kotvy-socialni li svg {
  fill: #151515;
}

.vismo--public.profil-str.personalni #dokument .perex {
  display: none;
}

/*************************
* ZÁHLAVÍ
*************************/
/*************************
* HLAVA
*************************/
#obalhlava {
  position: relative;
  z-index: 52;
  background-color: #ffffff;
}
@media screen and (min-width: 1041px) {
  .titul #obalhlava {
    position: sticky;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 50;
  }
}

#hlava {
  clear: both;
  position: relative;
  z-index: 3;
  text-align: center;
}
@media screen and (min-width: 501px) {
  #hlava {
    text-align: left;
  }
}
#stranka #hlava {
  margin-bottom: 18px;
}
#stranka.profil--havireni #hlava, #stranka.havireni #hlava {
  margin-bottom: 0;
}
@media screen and (min-width: 1025px) {
  .profil #hlava, .profil-str #hlava {
    padding-top: 15px;
  }
}
.profil.iti #hlava .znak.text, .profil-str.iti #hlava .znak.text {
  display: none;
}
@media screen and (min-width: 601px) {
  .profil.iti #hlava .znak.text, .profil-str.iti #hlava .znak.text {
    display: inline-block;
  }
}
.profil.iti #hlava .znak.notext, .profil-str.iti #hlava .znak.notext {
  display: inline-block;
}
@media screen and (min-width: 601px) {
  .profil.iti #hlava .znak.notext, .profil-str.iti #hlava .znak.notext {
    display: none;
  }
}
.profil.bez-znaku #hlava, .profil-str.bez-znaku #hlava, .profil-str.namesti #hlava {
  padding-top: 60px;
}
@media screen and (min-width: 801px) {
  .profil.bez-znaku #hlava, .profil-str.bez-znaku #hlava, .profil-str.namesti #hlava {
    padding-top: 15px;
    padding-right: 100px;
  }
}
#hlava #profil-navrat {
  text-decoration: none !important;
  position: absolute;
  right: 0;
  top: 0;
}
#hlava #profil-navrat img {
  width: 100px;
}
@media screen and (min-width: 801px) {
  #hlava #profil-navrat {
    top: 56px;
  }
}
.havireni #hlava #profil-navrat {
  top: 0;
  bottom: auto;
}
@media screen and (min-width: 801px) {
  .havireni #hlava #profil-navrat {
    top: auto;
    bottom: 0;
  }
}

.hlava {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
@media screen and (min-width: 601px) {
  .hlava {
    flex-direction: row;
    align-items: flex-end;
  }
}

#znak {
  margin: 0;
  position: relative;
  z-index: 3;
  height: auto;
}
@media screen and (max-width: 500px) {
  #znak {
    width: 80px;
  }
}

#menu-trigger {
  float: right;
  margin-right: 20px;
  margin-top: 24px;
  background-color: #f5eaea;
  padding: 1px 8px;
  font-size: 1.3125rem;
  color: #ca1517;
}
@media screen and (min-width: 501px) {
  #menu-trigger {
    display: none;
  }
}

#menu-close {
  display: block;
  text-align: right;
  margin-bottom: 20px;
  padding: 15px 15px 0 15px;
}
@media screen and (min-width: 501px) {
  #menu-close {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .obalnahore {
    -webkit-transition: max-height 0.3s ease-out;
    transition: max-height 0.3s ease-out;
    position: fixed;
    overflow: hidden;
    text-align: left;
    z-index: 60;
    top: 0;
    left: 0;
    right: 0;
    max-height: 0;
    background-color: rgba(0, 0, 0, 0.85);
    color: #ffffff;
  }
  .obalnahore a,
  .obalnahore a:link,
  .obalnahore a:visited {
    color: #ffffff;
  }
  .mobile-displayed .obalnahore {
    max-height: 100vh;
    overflow-y: scroll;
  }
}
#nazev {
  text-align: left;
  position: relative;
  margin: 24px 0 0 0;
  display: inline-block;
  float: left;
}
#stranka:not([class*=profil]) #nazev {
  margin-left: 20px;
  margin-top: 0;
}
#nazev ~ .nsa,
#nazev ~ .kv {
  float: right;
  margin-top: 42px;
  margin-right: 10px;
}
.profil #nazev, .profil-str #nazev {
  margin-top: 11px;
}
.profil.havireni #nazev, .profil-str.havireni #nazev {
  margin-top: 0;
}
.namesti #nazev, .probereme #nazev {
  margin-bottom: 10px;
}
@media screen and (min-width: 601px) {
  #stranka:not([class*=profil]) #nazev {
    margin-left: 40px;
  }
}
#nazev a {
  z-index: 30;
  display: inline;
  text-decoration: none !important;
  outline-width: 0;
  font-size: 0;
}
#nazev a img {
  font-size: 1rem;
  line-height: 1;
}
#nazev a::after {
  content: "";
}

.oci.profil #hlava {
  padding: 0 !important;
  max-width: 1360px;
}
.oci.profil #nazev {
  margin-top: 24px;
}
.oci.profil .hledani-gte {
  float: right;
}
.oci.profil .hledani-gte #hledani {
  display: block;
  float: none;
  margin-top: 0;
  width: 320px;
  max-width: 100%;
  box-sizing: border-box;
}
@media screen and (min-width: 1001px) and (max-width: 1100px) {
  .oci.profil .hledani-gte #hledani {
    width: 300px;
  }
}
.oci.profil .hledani-gte #hledani label {
  line-height: 0.75;
}
.oci.profil .hledani-gte #hledani .format {
  width: 100%;
  height: 38px;
}
.oci.profil .hledani-gte #hledani .btn {
  right: 7px;
  top: 7px;
}
.oci.profil .hledani-gte #jazyky {
  padding-top: 15px;
  margin-left: 0;
  float: left;
}
.oci.profil .hledani-gte #profil-navrat {
  position: static !important;
  float: right;
  margin-top: 10px;
}
.oci.profil .editor.profil-titul {
  max-width: 920px !important;
  margin: 0 auto;
}

@media screen and (min-width: 501px) {
  #znak,
  #nazev a,
  h1.cvi {
    float: left;
  }
}
#content-lista {
  display: none;
}

@media screen and (min-width: 1041px) {
  #content-lista {
    display: block;
    text-align: left;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 50;
    background: #d7d7d7 url(images/bg-fixed-header.svg) no-repeat 100% 17px;
    line-height: 1.2;
  }
  #content-lista object {
    position: absolute;
    right: 0;
    top: 17px;
  }
  #content-lista .centrovany::before {
    margin-top: 24px;
    content: url(images/znak.svg);
    float: left;
    width: 138px;
    height: 60px;
  }
  #content-lista ul {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    list-style-type: none;
    padding: 42px 0 0 0;
    margin: 0;
    line-height: 1.2;
    float: left;
    position: relative;
    z-index: 3;
  }
  #content-lista li {
    float: left;
    margin: 0;
  }
  @-moz-document url-prefix() {
    #content-lista li {
      line-height: 1;
    }
  }
  #content-lista li:first-child {
    margin-left: 40px;
  }
  #content-lista a,
  #content-lista a:link,
  #content-lista a:visited {
    color: #444444;
    text-decoration: none;
  }
  #content-lista a {
    font-size: 1.125rem;
    display: inline;
    padding: 0 40px 20px 0;
    position: relative;
  }
  #content-lista a span {
    -webkit-transition: width 0.25s, background-color 0.25s;
    transition: width 0.25s, background-color 0.25s;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 13px;
    background-color: transparent;
  }
  #content-lista a span.in-progress {
    background-color: #b2b2b2;
  }
  #content-lista a span.finished {
    background-color: #b2b2b2;
  }
}
/****************************
* HLAVNÍ OBRÁZEK A BLENDER
****************************/
#obalobrazek {
  clear: both;
}
#obalobrazek #obrazek {
  position: relative;
  padding-bottom: 28.90625%;
  background-color: #666666;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}
#obalobrazek #obrazek:empty {
  background-image: url(images/bg-header.jpg);
}
#obalobrazek #obrazek > div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
#obalobrazek #obrazek .ib_image,
#obalobrazek #obrazek .ib_link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  text-align: right;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}
#obalobrazek #obrazek .ib_popisek_obal {
  font-size: 39px;
  font-size: 2.4375rem;
  position: absolute;
  left: 20%;
  right: 0;
  bottom: 0.12em;
}
@media screen and (min-width: 1025px) {
  #obalobrazek #obrazek .ib_popisek_obal {
    left: 40%;
  }
}
#obalobrazek #obrazek .ib_popisek {
  display: none;
}
@media screen and (max-width: 600px) {
  #obalobrazek #obrazek {
    padding-bottom: 48%;
  }
}
@media screen and (min-width: 676px) {
  #obalobrazek #obrazek .ib_popisek {
    background-color: rgba(141, 101, 104, 0.7);
    color: #ffffff;
    text-decoration: none;
    font-family: TeutonNormalWeb;
    padding: 10px 40px 10px 40px;
    display: inline;
    line-height: 1.82;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }
}
@media screen and (min-width: 676px) and (-ms-high-contrast: active), screen and (min-width: 676px) and (-ms-high-contrast: none) {
  #obalobrazek #obrazek .ib_popisek {
    display: inline-block;
    line-height: 1.2;
  }
}
@media screen and (min-width: 676px) {
  .tic #obalobrazek #obrazek .ib_popisek {
    background-color: #159ba7;
  }
  .personalni #obalobrazek #obrazek .ib_popisek {
    display: none;
  }
  .dgm #obalobrazek #obrazek .ib_popisek, .gustavmahler #obalobrazek #obrazek .ib_popisek, .htradice #obalobrazek #obrazek .ib_popisek {
    background-color: #159aa9;
  }
  .turista #obalobrazek #obrazek .ib_popisek {
    background-color: #46983b;
  }
  .sport #obalobrazek #obrazek .ib_popisek {
    background-color: #048fcd;
  }
  .kultura #obalobrazek #obrazek .ib_popisek {
    background-color: #324ab0;
  }
  .festival #obalobrazek #obrazek .ib_popisek {
    background-color: #69554b;
  }
  .bezodpadu #obalobrazek #obrazek .ib_popisek {
    background-color: #b48c43;
  }
  .topakce #obalobrazek #obrazek .ib_popisek {
    background-color: #964e23;
  }
  .havireni #obalobrazek #obrazek .ib_popisek {
    background-color: #25af4f;
  }
  .podzemi #obalobrazek #obrazek .ib_popisek {
    background-color: #a3043e;
  }
  .mp #obalobrazek #obrazek .ib_popisek {
    background-color: rgba(255, 255, 255, 0.8);
    color: #6f0707;
  }
  .arena #obalobrazek #obrazek .ib_popisek {
    background-color: rgba(96, 7, 36, 0.7);
    color: #ffffff;
  }
  .namesti #obalobrazek #obrazek .ib_popisek, .probereme #obalobrazek #obrazek .ib_popisek {
    background-color: rgba(255, 255, 255, 0.75);
    color: #ca1517;
  }
}
#obalobrazek #administraceobrazky {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
}

.tic #obalobrazek,
.topakce #obalobrazek,
.sport #obalobrazek,
.iti #obalobrazek,
.kultura #obalobrazek {
  position: relative;
}
.tic #obrlinky,
.topakce #obrlinky,
.sport #obrlinky,
.iti #obrlinky,
.kultura #obrlinky {
  position: absolute;
  left: 1em;
  top: 0.75em;
  z-index: 30;
}
.tic #obrlinky ul,
.topakce #obrlinky ul,
.sport #obrlinky ul,
.iti #obrlinky ul,
.kultura #obrlinky ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.tic #obrlinky li,
.topakce #obrlinky li,
.sport #obrlinky li,
.iti #obrlinky li,
.kultura #obrlinky li {
  display: inline-block;
  margin-right: 1em;
}
.tic #obrlinky li a,
.topakce #obrlinky li a,
.sport #obrlinky li a,
.iti #obrlinky li a,
.kultura #obrlinky li a {
  border: 3px #ffffff solid;
  width: 20px;
  height: 20px;
  display: inline-block;
}
.tic #obrlinky li a.imagesBlender_link_active,
.topakce #obrlinky li a.imagesBlender_link_active,
.sport #obrlinky li a.imagesBlender_link_active,
.iti #obrlinky li a.imagesBlender_link_active,
.kultura #obrlinky li a.imagesBlender_link_active {
  background-color: #ca1517;
}

#lista ul {
  position: relative;
}
#lista ul.inline {
  display: inline;
}
#lista li {
  margin: 0;
  white-space: nowrap;
}
#lista li.skip {
  position: absolute;
  display: block;
  top: 0 !important;
  left: 0 !important;
  max-width: 320px;
  padding: 1px;
  border: 0;
  white-space: normal;
}
#lista li.skip a {
  text-align: center;
  border: 0;
  position: absolute;
  left: 0;
  top: -500px;
  z-index: 0;
  padding: 0 2px;
  background-color: #ffffff;
  color: #000000;
}
#lista li.skip a:focus,
#lista li.skip a:active {
  z-index: 10 !important;
  position: absolute !important;
  right: 10px;
  top: 5px;
}

#jazyky {
  margin: 0;
  padding: 25px 25px 0 10px;
  position: relative;
  text-align: center;
  float: right;
  font-size: 18px;
  font-size: 1.125rem;
}
#jazyky ul {
  display: inline;
}
@media screen and (min-width: 501px) {
  #jazyky {
    padding: 42px 34px 0 0;
    margin-left: 36px;
    text-align: right;
  }
}
#jazyky li {
  display: inline;
  margin-left: 8px;
}
#jazyky span {
  color: #ca1517;
  font-weight: bold;
}
#jazyky a,
#jazyky a:link,
#jazyky a:visited {
  color: #666666;
}
.havireni #jazyky {
  padding-top: 7px;
  padding-bottom: 14px;
}
@media screen and (min-width: 501px) {
  .havireni #jazyky {
    padding-top: 42px;
  }
}

/***********************
*	TABULKY
***********************/
.blokposun {
  background-color: #eeeeee;
}

.seznam {
  border: 0;
}
.seznam th a,
.seznam th a:link,
.seznam th a:visited,
.seznam th a:hover,
.seznam th a:focus,
.seznam th a:active {
  color: #ffffff;
}
.seznam td,
.seznam th {
  border: 1px white solid;
  border-width: 0 1px 1px 0;
}
.seznam td:last-child,
.seznam th:last-child {
  border-right: 0;
}
.seznam th {
  font-size: 1.0625rem;
  font-family: Roboto;
  line-height: 1.1;
  font-weight: normal;
  background-color: #ca1517;
  color: #ffffff;
}
.seznam th a,
.seznam th a:link,
.seznam th a:visited {
  color: #ffffff;
}
.seznam td {
  color: black;
  border-width: 1px 1px 0 0;
}
.seznam .b1 td {
  border-color: #ffffff;
}
.seznam .b2 td, .seznam #isvs .hodnota td, #isvs .seznam .hodnota td {
  border-color: #ebebeb;
}

.b1 {
  background-color: #ebebeb;
}

.b2, #isvs .hodnota {
  background-color: #ffffff;
}

/*************************
* SEZNAMY
*************************/
/*************************
* DEFINICE PROMĚNNÝCH
*************************/
/************************/
/*************************
* MIXINY PRO ODRÁŽKY
*************************/
/************************/
.bodkazy .ui li {
  padding-left: 0;
}
.bodkazy .ui li.typsouboru {
  padding-left: 34px;
  background-position: 0 0.4em;
  list-style-image: none;
  margin-left: -25px;
}

dl.kontakty ul.ui {
  margin-left: 0;
}
dl.kontakty ul.ui ul {
  margin-left: 0;
}

.odkazy .ui li li::before, .aktuality2 li.bezobr li::before, .utvary .ui li.o li::before, .kategorie .ui li li::before, .utvary .ui li li::before, .galerie .ui li li::before {
  position: absolute;
  left: 0;
  top: 0.56em;
  display: inline-block;
  content: "";
  width: 7px;
  height: 7px;
  background: transparent url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='7px' height='7px' viewBox='0 0 7 7'%3E%3Cpath fill='%23bcbcbc' stroke='%23bcbcbc' stroke-miterlimit='10' d='M2.214,0.5h2.572C5.732,0.5,6.5,1.268,6.5,2.214v2.572C6.5,5.732,5.732,6.5,4.786,6.5H2.214C1.268,6.5,0.5,5.732,0.5,4.786V2.214C0.5,1.268,1.268,0.5,2.214,0.5z'/%3E%3C/svg%3E") no-repeat 0 0;
  line-height: 0;
  overflow: hidden;
}
li.u, li.typsouboru {
  list-style-type: none !important;
}
li.u::before, li.typsouboru::before {
  content: normal !important;
}
.inline li {
  display: inline;
  list-style-type: none;
}
.inline li::before {
  position: relative;
  content: "|";
  display: inline-block;
  margin: 0 10px 0 11px;
}
.inline li:first-child::before, .inline li.skip::before, .inline li.skip + li:not(.skip)::before {
  content: normal;
}

.ui li {
  clear: none;
  padding: 0;
  margin: 5px 0 20px 0;
}
.ui li strong {
  font-weight: normal;
}
.ui li strong a {
  font-family: TeutonNormalWeb;
  font-size: 1.4375rem;
}
.ui li li {
  margin: 5px 0;
  padding-left: 22px;
}
.ui li li::after {
  content: normal;
}
.ui li li strong a {
  font-family: Roboto;
  font-size: 1rem;
}
.ui div {
  font-weight: normal;
  margin-bottom: 2px;
  margin-top: 4px;
  padding-top: 0;
  padding-bottom: 0;
}
.ui div.ktg {
  margin-top: 0;
}

/**********************************
*	POVINNĚ ZVEŘEJŇOVANÉ INFORMACE
**********************************/
#isvs .hodnota li::before {
  content: normal !important;
}
#isvs .odkazy > .ui > li > strong:first-child {
  font-family: TeutonNormalWeb;
  font-size: 1.4375rem;
  color: #a53740;
}

/*********************
* VÝPISY DOKUMENTŮ
*********************/
.dok li {
  content: normal;
  display: block;
  margin: 20px 0;
  padding: 0;
}
.dok strong img {
  margin: 3px 12px 4px 0;
  float: left;
}
.dok strong a {
  font-size: 1.3125rem;
  font-weight: normal;
  font-family: TeutonNormalWeb;
  color: #ca1517;
  line-height: 1.1904761905;
}
.dok strong a,
.dok strong a:link,
.dok strong a:visited {
  text-decoration: underline;
}
.dok strong a:hover,
.dok strong a:focus,
.dok strong a:active {
  text-decoration: none;
}

#zpravodajstvi ul.inline {
  padding: 0;
  margin: 20px 0;
}

img.pritomnost {
  border-radius: 50%;
  margin: 0 0.75em;
}

h2.cvi + img.pritomnost {
  vertical-align: middle;
  margin-top: -0.75em;
}

.ui strong img.pritomnost {
  float: none;
  margin: 0 0.75em;
}

#nahore .odkazy .ui li::before,
#dole .odkazy .ui li::before,
.souvisejiciodkazy.kategorie .ui li::before {
  content: url(images/arr-text.svg);
  margin-right: 0.75em;
  position: relative;
  top: 0.15em;
  left: 0;
}
#nahore .odkazy .ui li a,
#dole .odkazy .ui li a,
.souvisejiciodkazy.kategorie .ui li a {
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  font-size: 1rem;
}

/*******************************
* PŘEHLED OSOB
*******************************/
.dok.osoby li {
  padding-left: 146px;
}
.dok.osoby li img {
  margin-left: -146px;
  max-width: 120px;
}
.dok.osoby li img.pritomnost {
  float: none;
  margin: 0 0.75em 0 0;
}
.dok.osoby li [href*="tel:"],
.dok.osoby li [href*="tel:"]:link,
.dok.osoby li [href*="tel:"]:visited {
  color: #a53740;
}
.dok.osoby li.noface::before {
  content: "";
  margin-left: -146px;
  float: left;
  width: 120px;
  height: 120px;
  background: #cccccc url(/aspinclude/vismoweb5/html/noface.svg) no-repeat 50% 50%;
  background-size: 80px auto;
}
@media screen and (max-width: 419px) {
  .dok.osoby li {
    padding-left: 0;
  }
  .dok.osoby li strong img, .dok.osoby li.noface::before {
    float: none;
    display: table;
    margin: 0 0 10px 0;
  }
  .dok.osoby li strong img.pritomnost {
    display: initial;
  }
}
.dok.osoby.seznam-ji li {
  padding-left: 0;
}

.ui .ji-kontakty li a,
.ui .ji-kontakty li a:link,
.ui .ji-kontakty li a:visited {
  color: #a53740;
}

/*****************************
* OSNOVA S UPOUTÁVKAMI V POZADÍ
*****************************/
.obrodkazy li {
  display: block;
  margin-bottom: 13px;
  background-image: none;
  position: relative;
}
.obrodkazy li::before {
  content: normal !important;
}
.obrodkazy a {
  font-size: 1.25rem;
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  background-repeat: no-repeat;
  background-position: 0 50%;
  padding-left: 65px;
  display: table;
  height: 1em;
  min-height: 48px;
}
.obrodkazy .mtext {
  display: table-cell;
  vertical-align: middle;
}
.obrodkazy strong {
  font-weight: normal;
}
.obrodkazy a,
.obrodkazy a:link,
.obrodkazy a:visited {
  text-decoration: none;
}
.obrodkazy a:hover,
.obrodkazy a:focus,
.obrodkazy a:active {
  text-decoration: underline;
}

/*****************************
* VÝPIS AKCÍ S CEDULKAMI
*****************************/
.vice-akci {
  margin-bottom: 12px;
  margin-top: 1em;
  position: relative;
}
@media screen and (min-width: 501px) {
  .vice-akci {
    margin-top: -1.2em;
    padding-right: 12em;
  }
}
.vice-akci .inline {
  display: inline;
}

.vypisakci-titul {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  margin-bottom: -2px;
}
@media screen and (max-width: 699px) {
  .vypisakci-titul {
    width: 100%;
  }
}
@media screen and (min-width: 700px) {
  .vypisakci-titul {
    width: calc( 50% - 11px );
    margin-left: 22px;
  }
  .vypisakci-titul:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
.vypisakci-titul.vypisakci-probereme {
  width: 100%;
  max-width: 700px;
  margin-left: auto !important;
  margin-right: auto !important;
  float: none;
  margin-bottom: 40px;
}
.vypisakci-titul h2.cvi {
  color: #c36066;
  margin-bottom: -1px;
}
.vypisakci-titul.akce-doporucene h2.cvi span {
  background-color: #c36066;
  color: #ffffff;
}
.vypisakci-titul + .vypisakci-titul h2.cvi span {
  padding-left: 0;
}
.vypisakci-titul.akce-doporucene li:nth-child(2), .vypisakci-titul + .vypisakci-titul li:first-child {
  background: transparent url(images/bg-event.svg) no-repeat 100% 0;
}
.vypisakci-titul .dok ul {
  border-top: 1px #d9d9d8 solid;
  border-bottom: 1px #ececec solid;
}
.vypisakci-titul .dok li {
  display: table;
  margin: 0;
  padding: 14px 0;
  width: 100%;
  position: relative;
  border-bottom: 1px #d9d9d8 solid;
  border-top: 1px #ececec solid;
  height: 1em;
  min-height: 100px;
}
.vypisakci-titul .dok li:first-child {
  margin-top: 0;
  padding-top: 14px;
}
.vypisakci-titul .dok li strong a,
.vypisakci-titul .dok li strong a:link,
.vypisakci-titul .dok li strong a:visited {
  color: #ca1517;
}
.vypisakci-titul .dok .datum,
.vypisakci-titul .dok .prazdnyDatum {
  display: table-cell;
  vertical-align: top;
  width: 4.35em;
  padding-top: 5px;
  text-align: left;
  font-family: Roboto;
}
@media screen and (min-width: 601px) {
  .vypisakci-titul .dok .datum,
  .vypisakci-titul .dok .prazdnyDatum {
    width: 6.25em;
  }
}
.vypisakci-titul .dok .den,
.vypisakci-titul .dok .mesic {
  display: block;
  line-height: 0.9;
}
.vypisakci-titul .dok .den {
  font-size: 3rem;
  font-weight: bold;
  background: transparent;
  color: #a53740;
  font-family: TeutonFettWeb;
}
.vypisakci-titul .dok .mesic {
  font-size: 1rem;
  background: transparent;
  color: #a53740;
  text-transform: lowercase;
}
.vypisakci-titul .dok .denPredlozka,
.vypisakci-titul .dok .rok,
.vypisakci-titul .dok .den .tecka {
  position: absolute;
  left: -9999px;
  right: 9990px;
}
.vypisakci-titul .dok .je-vicedenni {
  display: block;
  margin-top: 6px;
  color: #848484;
  font-size: 0.875rem;
}
.vypisakci-titul .dok .prazdnyDatum {
  background: transparent;
}
.vypisakci-titul .dok .obsahAkce {
  display: table-cell;
  padding-right: 15px;
  padding-left: 15px;
}

/*****************************
* SEKCE TÉMATA NA TITULCE
*****************************/
.temata {
  padding-top: 62px;
  margin-bottom: 60px;
}
.temata[class*=temata--]:not(.temata--tic) li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
}
@media screen and (max-width: 519px) {
  .temata[class*=temata--]:not(.temata--tic) li {
    width: 100%;
  }
}
@media screen and (min-width: 520px) and (max-width: 799px) {
  .temata[class*=temata--]:not(.temata--tic) li {
    width: calc( 50% - 11px );
    margin-left: 22px;
  }
  .temata[class*=temata--]:not(.temata--tic) li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 800px) and (max-width: 1023px) {
  .temata[class*=temata--]:not(.temata--tic) li {
    width: calc( 33.3333333333% - 14.6666666667px );
    margin-left: 22px;
  }
  .temata[class*=temata--]:not(.temata--tic) li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1024px) {
  .temata[class*=temata--]:not(.temata--tic) li {
    width: calc( 25% - 16.5px );
    margin-left: 22px;
  }
  .temata[class*=temata--]:not(.temata--tic) li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}
.temata.temata--tic {
  padding-top: 40px;
}
.temata.temata--tic li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
}
@media screen and (max-width: 519px) {
  .temata.temata--tic li {
    width: 100%;
  }
}
@media screen and (min-width: 520px) and (max-width: 799px) {
  .temata.temata--tic li {
    width: calc( 50% - 11px );
    margin-left: 22px;
  }
  .temata.temata--tic li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 800px) and (max-width: 1023px) {
  .temata.temata--tic li {
    width: calc( 50% - 11px );
    margin-left: 22px;
  }
  .temata.temata--tic li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1024px) {
  .temata.temata--tic li {
    width: calc( 33.3333333333% - 14.6666666667px );
    margin-left: 22px;
  }
  .temata.temata--tic li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
.temata ul.ui div {
  margin-top: 7px;
  line-height: 1.375;
}
.temata ul.ui li {
  margin-top: 0;
  margin-bottom: 22px;
  /*  display: flex;
  flex-direction: column;

  > strong:first-child {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
  } */
}
.temata ul.ui li a {
  font-size: 22px;
  font-size: 1.375rem;
  background: #ca1517;
  display: block;
  padding: 8px 12px 6px 12px;
  line-height: 1.1;
  font-family: TeutonNormalWeb;
}
.temata ul.ui li a,
.temata ul.ui li a:link,
.temata ul.ui li a:visited {
  color: #ffffff;
  text-decoration: none;
}
.temata ul.ui li a:hover,
.temata ul.ui li a:focus,
.temata ul.ui li a:active {
  text-decoration: underline;
}
.temata.temata--main ul.ui li:nth-child(1) a {
  background-color: #ca1517;
}
.temata.temata--main ul.ui li:nth-child(2) a {
  background-color: #048fcd;
}
.temata.temata--main ul.ui li:nth-child(3) a {
  background-color: #004a7a;
}
.temata.temata--main ul.ui li:nth-child(4) a {
  background-color: #006ea2;
}
.temata.temata--main ul.ui li:nth-child(5) a {
  background-color: #c36066;
}
.temata.temata--main ul.ui li:nth-child(6) a {
  background-color: #de5b12;
}
.temata.temata--main ul.ui li:nth-child(7) a {
  background-color: #6c9b2e;
}
.temata.temata--main ul.ui li:nth-child(8) a {
  background-color: #159aa8;
}
.temata.temata--main ul.ui li:nth-child(9) a {
  background-color: #a8888a;
}
.temata.temata--main ul.ui li:nth-child(10) a {
  background-color: #a43740;
}
.temata.temata--main ul.ui li:nth-child(11) a {
  background-color: #018739;
}
.temata.temata--main ul.ui li:nth-child(12) a {
  background-color: #dd697b;
}
.temata.temata--main ul.ui li:nth-child(13) a {
  background-color: #988a86;
}
.temata.temata--main ul.ui li:nth-child(14) a {
  background-color: #cc7e00;
}
.temata.temata--main ul.ui li:nth-child(15) a {
  background-color: #579e7c;
}
.temata.temata--tic ul.ui li:nth-child(1) a {
  background-color: #ca1517;
}
.temata.temata--tic ul.ui li:nth-child(2) a {
  background-color: #159ba7;
}
.temata.temata--tic ul.ui li:nth-child(3) a {
  background-color: #a53740;
}
.temata.temata--tic ul.ui li:nth-child(4) a {
  background-color: #c36065;
}
.temata.temata--tic ul.ui li:nth-child(5) a {
  background-color: #007d99;
}
.temata.temata--tic ul.ui li:nth-child(6) a {
  background-color: #018fcd;
}
.temata.temata--tic ul.ui li:nth-child(7) a {
  background-color: #006a7a;
}
.temata.temata--tic ul.ui li:nth-child(8) a {
  background-color: #a8888b;
}
.temata.temata--tic ul.ui li:nth-child(9) a {
  background-color: #159aa9;
}
.temata.temata--tic ul.ui li:nth-child(10) a {
  background-color: #a53740;
}
.temata.temata--dgm ul.ui li:nth-child(1) a {
  background-color: #ca0c0e;
}
.temata.temata--dgm ul.ui li:nth-child(2) a {
  background-color: #c36065;
}
.temata.temata--dgm ul.ui li:nth-child(3) a {
  background-color: #159aa9;
}
.temata.temata--dgm ul.ui li:nth-child(4) a {
  background-color: #6f9294;
}
.temata.temata--dgm ul.ui li:nth-child(5) a {
  background-color: #016990;
}
.temata.temata--dgm ul.ui li:nth-child(6) a {
  background-color: #009cbb;
}
.temata.temata--dgm ul.ui li:nth-child(7) a {
  background-color: #007d99;
}
.temata.temata--dgm ul.ui li:nth-child(8) a {
  background-color: #9d9d9d;
}
.temata.temata--gustavmahler ul.ui li:nth-child(1) a {
  background-color: #159aa9;
}
.temata.temata--gustavmahler ul.ui li:nth-child(2) a {
  background-color: #69554b;
}
.temata.temata--gustavmahler ul.ui li:nth-child(3) a {
  background-color: #007d99;
}
.temata.temata--gustavmahler ul.ui li:nth-child(4) a {
  background-color: #6f9294;
}
.temata.temata--bezodpadu ul.ui li:nth-child(1) a {
  background-color: #ca0c0f;
}
.temata.temata--bezodpadu ul.ui li:nth-child(2) a {
  background-color: #000000;
}
.temata.temata--bezodpadu ul.ui li:nth-child(3) a {
  background-color: #cca050;
}
.temata.temata--bezodpadu ul.ui li:nth-child(4) a {
  background-color: #b48c43;
}
.temata.temata--bezodpadu ul.ui li:nth-child(5) a {
  background-color: #6d9a0b;
}
.temata.temata--bezodpadu ul.ui li:nth-child(6) a {
  background-color: #b3b136;
}
.temata.temata--bezodpadu ul.ui li:nth-child(7) a {
  background-color: #7b7908;
}
.temata.temata--bezodpadu ul.ui li:nth-child(8) a {
  background-color: #7b7908;
}
.temata.temata--arena ul.ui li:nth-child(1) a {
  background-color: #600624;
}
.temata.temata--arena ul.ui li:nth-child(2) a {
  background-color: #c92026;
}
.temata.temata--arena ul.ui li:nth-child(3) a {
  background-color: #3fa535;
}
.temata.temata--arena ul.ui li:nth-child(4) a {
  background-color: #063e85;
}
.temata.temata--arena ul.ui li:nth-child(5) a {
  background-color: #ab2854;
}
.temata.temata--arena ul.ui li:nth-child(6) a {
  background-color: #c6403c;
}
.temata.temata--arena ul.ui li:nth-child(7) a {
  background-color: #2e3b31;
}
.temata.temata--arena ul.ui li:nth-child(8) a {
  background-color: #3f5073;
}
.temata.temata--arena ul.ui li:nth-child(9) a {
  background-color: #3f1f24;
}
.temata.temata--arena ul.ui li:nth-child(10) a {
  background-color: #915f5c;
}
.temata.temata--arena ul.ui li:nth-child(11) a {
  background-color: #272422;
}
.temata.temata--arena ul.ui li:nth-child(12) a {
  background-color: #6f6f6e;
}
.temata.temata--namesti ul.ui li:nth-child(1) a {
  background-color: #aa878b;
}
.temata.temata--namesti ul.ui li:nth-child(2) a {
  background-color: #ca0c0e;
}
.temata.temata--namesti ul.ui li:nth-child(3) a {
  background-color: #c36065;
}
.temata.temata--namesti ul.ui li:nth-child(4) a {
  background-color: #af735b;
}
.temata.temata--namesti ul.ui li:nth-child(5) a {
  background-color: #6a6a6a;
}
.temata.temata--namesti ul.ui li:nth-child(6) a {
  background-color: #de5b03;
}
.temata.temata--namesti ul.ui li:nth-child(7) a {
  background-color: #a53740;
}
.temata.temata--odpady ul.ui li:nth-child(1) a {
  background-color: #ca1517;
}
.temata.temata--odpady ul.ui li:nth-child(2) a {
  background-color: #006a7a;
}
.temata.temata--odpady ul.ui li:nth-child(3) a {
  background-color: #159ba7;
}
.temata.temata--odpady ul.ui li:nth-child(4) a {
  background-color: #018fcd;
}
.temata.temata--odpady ul.ui li:nth-child(5) a {
  background-color: #00893b;
}
.temata.temata--odpady ul.ui li:nth-child(6) a {
  background-color: #007d99;
}
.temata.temata--odpady ul.ui li:nth-child(7) a {
  background-color: #6d9a0b;
}
.temata.temata--odpady ul.ui li:nth-child(8) a {
  background-color: #7d7d7b;
}
.temata.temata--odpady ul.ui li:nth-child(9) a {
  background-color: #007539;
}
.temata.temata--odpady ul.ui li:nth-child(10) a {
  background-color: #226307;
}
.temata.temata--odpady ul.ui li:nth-child(11) a {
  background-color: #607d23;
}
.temata.temata--zdrave-mesto ul.ui li:nth-child(1) a {
  background-color: #018fcd;
}
.temata.temata--zdrave-mesto ul.ui li:nth-child(2) a {
  background-color: #ca0c0e;
}
.temata.temata--zdrave-mesto ul.ui li:nth-child(3) a {
  background-color: #c36065;
}
.temata.temata--zdrave-mesto ul.ui li:nth-child(4) a {
  background-color: #b36d32;
}
.temata.temata--zdrave-mesto ul.ui li:nth-child(5) a {
  background-color: #de5b03;
}
.temata.temata--zdrave-mesto ul.ui li:nth-child(6) a {
  background-color: #e7311b;
}
.temata.temata--zdrave-mesto ul.ui li:nth-child(7) a {
  background-color: #a8888b;
}
.temata.temata--zdrave-mesto ul.ui li:nth-child(8) a {
  background-color: #7d7d7b;
}
.temata.temata--zdrave-mesto ul.ui li:nth-child(9) a {
  background-color: #b36d32;
}
.temata.temata--zdrave-mesto ul.ui li:nth-child(10) a {
  background-color: #a53740;
}
.temata.temata--doprava ul.ui li:nth-child(1) a {
  background-color: #3bd4ff;
}
.temata.temata--doprava ul.ui li:nth-child(1) a, .temata.temata--doprava ul.ui li:nth-child(1) a:link, .temata.temata--doprava ul.ui li:nth-child(1) a:visited {
  color: #000;
}
.temata.temata--doprava ul.ui li:nth-child(2) a {
  background-color: #8a7dd9;
}
.temata.temata--doprava ul.ui li:nth-child(2) a, .temata.temata--doprava ul.ui li:nth-child(2) a:link, .temata.temata--doprava ul.ui li:nth-child(2) a:visited {
  color: #000;
}
.temata.temata--doprava ul.ui li:nth-child(3) a {
  background-color: #68c260;
}
.temata.temata--doprava ul.ui li:nth-child(3) a, .temata.temata--doprava ul.ui li:nth-child(3) a:link, .temata.temata--doprava ul.ui li:nth-child(3) a:visited {
  color: #000;
}
.temata.temata--doprava ul.ui li:nth-child(4) a {
  background-color: #b2b598;
}
.temata.temata--doprava ul.ui li:nth-child(4) a, .temata.temata--doprava ul.ui li:nth-child(4) a:link, .temata.temata--doprava ul.ui li:nth-child(4) a:visited {
  color: #000;
}
.temata.temata--doprava ul.ui li:nth-child(5) a {
  background-color: #ff3461;
}
.temata.temata--doprava ul.ui li:nth-child(5) a, .temata.temata--doprava ul.ui li:nth-child(5) a:link, .temata.temata--doprava ul.ui li:nth-child(5) a:visited {
  color: #000;
}
.temata.temata--doprava ul.ui li:nth-child(6) a {
  background-color: #ffae44;
}
.temata.temata--doprava ul.ui li:nth-child(6) a, .temata.temata--doprava ul.ui li:nth-child(6) a:link, .temata.temata--doprava ul.ui li:nth-child(6) a:visited {
  color: #000;
}
.temata.temata--doprava ul.ui li:nth-child(7) a {
  background-color: #0094dc;
}
.temata.temata--doprava ul.ui li:nth-child(7) a, .temata.temata--doprava ul.ui li:nth-child(7) a:link, .temata.temata--doprava ul.ui li:nth-child(7) a:visited {
  color: #000;
}
.temata.temata--doprava ul.ui li:nth-child(8) a {
  background-color: #ff6c41;
}
.temata.temata--doprava ul.ui li:nth-child(8) a, .temata.temata--doprava ul.ui li:nth-child(8) a:link, .temata.temata--doprava ul.ui li:nth-child(8) a:visited {
  color: #000;
}
.temata.temata--podzemi ul.ui li:nth-child(1) a {
  background-color: #ca1517;
}
.temata.temata--podzemi ul.ui li:nth-child(2) a {
  background-color: #c36065;
}
.temata.temata--podzemi ul.ui li:nth-child(3) a {
  background-color: #b30970;
}
.temata.temata--podzemi ul.ui li:nth-child(4) a {
  background-color: #96140c;
}
.temata.temata--podzemi ul.ui li:nth-child(5) a {
  background-color: #800063;
}
.temata.temata--podzemi ul.ui li:nth-child(6) a {
  background-color: #865560;
}
.temata.temata--podzemi ul.ui li:nth-child(7) a {
  background-color: #9d9d9c;
}
.temata.temata--mp ul.ui li:nth-child(1) a {
  background-color: #ca0c0f;
}
.temata.temata--mp ul.ui li:nth-child(2) a {
  background-color: #929496;
}
.temata.temata--mp ul.ui li:nth-child(3) a {
  background-color: #c36065;
}
.temata.temata--mp ul.ui li:nth-child(4) a {
  background-color: #a4373f;
}
.temata.temata--mp ul.ui li:nth-child(5) a {
  background-color: #6f0707;
}
.temata.temata--mp ul.ui li:nth-child(6) a {
  background-color: #a9888a;
}
.temata.temata--mp ul.ui li:nth-child(7) a {
  background-color: #764e59;
}
.temata.temata--mp ul.ui li:nth-child(8) a {
  background-color: #6b6a6a;
}
.temata.temata--havireni ul.ui li:nth-child(1) a {
  background-color: #25af4f;
}
.temata.temata--havireni ul.ui li:nth-child(2) a {
  background-color: #108d36;
}
.temata.temata--havireni ul.ui li:nth-child(3) a {
  background-color: #c90b0f;
}
.temata.temata--havireni ul.ui li:nth-child(4) a {
  background-color: #b8037a;
}
.temata.temata--havireni ul.ui li:nth-child(5) a {
  background-color: #0e6729;
}
.temata.temata--havireni ul.ui li:nth-child(6) a {
  background-color: #b57ca1;
}
.temata.temata--havireni ul.ui li:nth-child(7) a {
  background-color: #4b1f89;
}
.temata.temata--havireni ul.ui li:nth-child(8) a {
  background-color: #7d3fa9;
}
.temata.temata--informacni-portal ul.ui li:nth-child(1) a {
  background-color: #565656;
}
.temata.temata--informacni-portal ul.ui li:nth-child(2) a {
  background-color: #828282;
}
.temata.temata--informacni-portal ul.ui li:nth-child(3) a {
  background-color: #c12318;
}
.temata.temata--informacni-portal ul.ui li:nth-child(4) a {
  background-color: #f95a3b;
}
.temata.temata--informacni-portal ul.ui li:nth-child(5) a {
  background-color: #ea9c02;
}
.temata.temata--informacni-portal ul.ui li:nth-child(6) a {
  background-color: #9f4141;
}
.temata.temata--informacni-portal ul.ui li:nth-child(7) a {
  background-color: #ffb459;
}
.temata.temata--informacni-portal ul.ui li:nth-child(8) a {
  background-color: #b48d6e;
}
.temata.temata--informacni-portal ul.ui li:nth-child(9) a {
  background-color: #ad6767;
}
.temata.temata--informacni-portal ul.ui li:nth-child(10) a {
  background-color: #e95e7d;
}
.temata.temata--informacni-portal ul.ui li:nth-child(11) a {
  background-color: #804971;
}
.temata.temata--informacni-portal ul.ui li:nth-child(12) a {
  background-color: #c6c6c6;
}
.temata.temata--topakce ul.ui li:nth-child(1) a {
  background-color: #ca1517;
}
.temata.temata--topakce ul.ui li:nth-child(2) a {
  background-color: #de5b12;
}
.temata.temata--topakce ul.ui li:nth-child(3) a {
  background-color: #964e23;
}
.temata.temata--topakce ul.ui li:nth-child(4) a {
  background-color: #61645a;
}
.temata.temata--topakce ul.ui li:nth-child(5) a {
  background-color: #c36066;
}
.temata.temata--topakce ul.ui li:nth-child(6) a {
  background-color: #6c383c;
}
.temata.temata--topakce ul.ui li:nth-child(7) a {
  background-color: #cc8258;
}
.temata.temata--topakce ul.ui li:nth-child(8) a {
  background-color: #858e70;
}
.temata.temata--topakce ul.ui li:nth-child(9) a {
  background-color: #a8888a;
}
.temata.temata--topakce ul.ui li:nth-child(10) a {
  background-color: #a43740;
}
.temata.temata--turista ul.ui li:nth-child(1) a {
  background-color: #ca1517;
}
.temata.temata--turista ul.ui li:nth-child(2) a {
  background-color: #789b2e;
}
.temata.temata--turista ul.ui li:nth-child(3) a {
  background-color: #35642c;
}
.temata.temata--turista ul.ui li:nth-child(4) a {
  background-color: #46983b;
}
.temata.temata--turista ul.ui li:nth-child(5) a {
  background-color: #c34c39;
}
.temata.temata--turista ul.ui li:nth-child(6) a {
  background-color: #cd6120;
}
.temata.temata--turista ul.ui li:nth-child(7) a {
  background-color: #1c885d;
}
.temata.temata--turista ul.ui li:nth-child(8) a {
  background-color: #5b615a;
}
.temata.temata--sport ul.ui li:nth-child(1) a {
  background-color: #ca1517;
}
.temata.temata--sport ul.ui li:nth-child(2) a {
  background-color: #a43740;
}
.temata.temata--sport ul.ui li:nth-child(3) a {
  background-color: #004a7a;
}
.temata.temata--sport ul.ui li:nth-child(4) a {
  background-color: #048fcd;
}
.temata.temata--kultura ul.ui li:nth-child(1) a {
  background-color: #ca1517;
}
.temata.temata--kultura ul.ui li:nth-child(2) a {
  background-color: #b1456e;
}
.temata.temata--kultura ul.ui li:nth-child(3) a {
  background-color: #653b94;
}
.temata.temata--kultura ul.ui li:nth-child(4) a {
  background-color: #324ab0;
}
.temata.temata--kultura ul.ui li:nth-child(5) a {
  background-color: #c36066;
}
.temata.temata--kultura ul.ui li:nth-child(6) a {
  background-color: #6c383c;
}
.temata.temata--kultura ul.ui li:nth-child(7) a {
  background-color: #736484;
}
.temata.temata--kultura ul.ui li:nth-child(8) a {
  background-color: #887373;
}
.temata.temata--festival ul.ui li:nth-child(1) a {
  background-color: #ca1517;
}
.temata.temata--festival ul.ui li:nth-child(2) a {
  background-color: #ba5d2b;
}
.temata.temata--festival ul.ui li:nth-child(3) a {
  background-color: #b09123;
}
.temata.temata--festival ul.ui li:nth-child(4) a {
  background-color: #908f78;
}
.temata.temata--festival ul.ui li:nth-child(5) a {
  background-color: #b78555;
}
.temata.temata--festival ul.ui li:nth-child(6) a {
  background-color: #9a4e4f;
}
.temata.temata--festival ul.ui li:nth-child(7) a {
  background-color: #949149;
}
.temata.temata--festival ul.ui li:nth-child(8) a {
  background-color: #69554b;
}
.temata.temata--personalni ul.ui li:nth-child(1) a {
  background-color: #f38336;
}
.temata.temata--personalni ul.ui li:nth-child(2) a {
  background-color: #a56b85;
}
.temata.temata--personalni ul.ui li:nth-child(3) a {
  background-color: #5da183;
}
.temata.temata--personalni ul.ui li:nth-child(4) a {
  background-color: #dc7281;
}
.temata.temata--personalni ul.ui li:nth-child(5) a {
  background-color: #819245;
}
.temata.temata--personalni ul.ui li:nth-child(6) a {
  background-color: #565a5a;
}
.temata.temata--personalni ul.ui li:nth-child(7) a {
  background-color: #d40100;
}
.temata.temata--proberemeji ul.ui li:nth-child(1) a {
  background-color: #e8bb00;
}
.temata.temata--proberemeji ul.ui li:nth-child(2) a {
  background-color: #c90c0f;
}
.temata.temata--proberemeji ul.ui li:nth-child(3) a {
  background-color: #623655;
}
.temata.temata--proberemeji ul.ui li:nth-child(4) a {
  background-color: #b1bc03;
}
.temata.temata--proberemeji ul.ui li:nth-child(5) a {
  background-color: #b37d19;
}
.temata.temata--proberemeji ul.ui li:nth-child(6) a {
  background-color: #127e34;
}
.temata.temata--proberemeji ul.ui li:nth-child(7) a {
  background-color: #213a7b;
}
.temata.temata--proberemeji ul.ui li:nth-child(8) a {
  background-color: #a877a8;
}
.temata.temata--iti ul.ui li:nth-child(1) a {
  background-color: #db4e2f;
}
.temata.temata--iti ul.ui li:nth-child(1) a, .temata.temata--iti ul.ui li:nth-child(1) a:link, .temata.temata--iti ul.ui li:nth-child(1) a:visited {
  color: #000;
}
.temata.temata--iti ul.ui li:nth-child(2) a {
  background-color: #f99b21;
}
.temata.temata--iti ul.ui li:nth-child(2) a, .temata.temata--iti ul.ui li:nth-child(2) a:link, .temata.temata--iti ul.ui li:nth-child(2) a:visited {
  color: #000;
}
.temata.temata--iti ul.ui li:nth-child(3) a {
  background-color: #e9bb00;
}
.temata.temata--iti ul.ui li:nth-child(3) a, .temata.temata--iti ul.ui li:nth-child(3) a:link, .temata.temata--iti ul.ui li:nth-child(3) a:visited {
  color: #000;
}
.temata.temata--iti ul.ui li:nth-child(4) a {
  background-color: #a3c1c9;
}
.temata.temata--iti ul.ui li:nth-child(4) a, .temata.temata--iti ul.ui li:nth-child(4) a:link, .temata.temata--iti ul.ui li:nth-child(4) a:visited {
  color: #000;
}
.temata.temata--iti ul.ui li:nth-child(5) a {
  background-color: #a9c041;
}
.temata.temata--iti ul.ui li:nth-child(5) a, .temata.temata--iti ul.ui li:nth-child(5) a:link, .temata.temata--iti ul.ui li:nth-child(5) a:visited {
  color: #000;
}
.temata.temata--iti ul.ui li:nth-child(6) a {
  background-color: #c5d464;
}
.temata.temata--iti ul.ui li:nth-child(6) a, .temata.temata--iti ul.ui li:nth-child(6) a:link, .temata.temata--iti ul.ui li:nth-child(6) a:visited {
  color: #000;
}
.temata.temata--iti ul.ui li:nth-child(7) a {
  background-color: #c4b1a8;
}
.temata.temata--iti ul.ui li:nth-child(7) a, .temata.temata--iti ul.ui li:nth-child(7) a:link, .temata.temata--iti ul.ui li:nth-child(7) a:visited {
  color: #000;
}
.temata.temata--iti ul.ui li:nth-child(8) a {
  background-color: #d9c4b9;
}
.temata.temata--iti ul.ui li:nth-child(8) a, .temata.temata--iti ul.ui li:nth-child(8) a:link, .temata.temata--iti ul.ui li:nth-child(8) a:visited {
  color: #000;
}

/*****************************
* MODUL DALŠÍ ZDROJE NA TITULCE
*****************************/
.osnova .ui {
  text-align: left;
}
.osnova .ui li {
  margin: 0 0 0.5em 0;
}
.osnova .ui li a,
.osnova .ui li a:link,
.osnova .ui li a:visited {
  color: #ca1517;
  text-decoration: none;
  border-bottom: 5px #ca1517 solid;
}

.titulodkazy li {
  display: block;
  background-image: none;
  margin: 4px 0;
  padding: 0;
  position: relative;
}
.titulodkazy li::before {
  content: normal !important;
}
.titulodkazy li div {
  margin: 2px 0 0 0;
}
.titulodkazy strong {
  font-weight: normal;
}

.volny-cas-odkazy {
  padding-top: 64px;
  min-height: 283px;
  box-sizing: border-box;
}
.volny-cas-odkazy li {
  font-size: 26px;
  font-size: 1.625rem;
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  display: block;
  background-image: none;
  margin-top: 0;
  margin-bottom: 22px;
  padding: 9px 20px;
  position: relative;
  font-family: TeutonNormalWeb;
}
.volny-cas-odkazy li::before {
  content: normal !important;
}
@media screen and (max-width: 499px) {
  .volny-cas-odkazy li {
    width: 100%;
  }
}
@media screen and (min-width: 500px) and (max-width: 699px) {
  .volny-cas-odkazy li {
    width: calc( 50% - 11px );
    margin-left: 22px;
  }
  .volny-cas-odkazy li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 700px) and (max-width: 999px) {
  .volny-cas-odkazy li {
    width: calc( 33.3333333333% - 14.6666666667px );
    margin-left: 22px;
  }
  .volny-cas-odkazy li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1000px) {
  .volny-cas-odkazy li {
    width: calc( 25% - 16.5px );
    margin-left: 22px;
  }
  .volny-cas-odkazy li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}
.volny-cas-odkazy li:nth-child(1) {
  background-color: rgba(28, 153, 208, 0.95);
}
.volny-cas-odkazy li:nth-child(2) {
  background-color: rgba(24, 145, 73, 0.95);
}
.volny-cas-odkazy li:nth-child(3) {
  background-color: rgba(17, 117, 162, 0.95);
}
.volny-cas-odkazy li:nth-child(4) {
  background-color: rgba(198, 34, 32, 0.95);
}
.volny-cas-odkazy li:nth-child(5) {
  background-color: rgba(206, 147, 10, 0.95);
}
.volny-cas-odkazy li:nth-child(6) {
  background-color: rgba(120, 162, 64, 0.95);
}
.volny-cas-odkazy li:nth-child(7) {
  background-color: rgba(56, 148, 156, 0.95);
}
.volny-cas-odkazy li:nth-child(8) {
  background-color: rgba(199, 108, 111, 0.95);
}
.volny-cas-odkazy li a {
  display: block;
}
.volny-cas-odkazy li a,
.volny-cas-odkazy li a:link,
.volny-cas-odkazy li a:visited {
  color: #ffffff;
  text-decoration: none;
}
.volny-cas-odkazy li a:hover,
.volny-cas-odkazy li a:focus,
.volny-cas-odkazy li a:active {
  text-decoration: underline;
}
.volny-cas-odkazy strong {
  font-weight: normal;
}

.obalmenunavrat {
  margin-bottom: 30px;
}

.menunavrat {
  color: #ffffff;
  font-family: TeutonFettWeb;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 30px -20px 0 -20px;
}
.menunavrat a {
  background-color: #ca1517;
  display: block;
  padding: 15px 22px 12px 22px;
}
.dgm .menunavrat a, .gustavmahler .menunavrat a, .htradice .menunavrat a {
  background-color: #007d90;
}
.odpady .menunavrat a {
  background-color: #00893b;
}
.turista .menunavrat a {
  background-color: #46983b;
}
.sport .menunavrat a {
  background-color: #048fcd;
}
.doprava .menunavrat a {
  background-color: #007aae;
}
.podzemi .menunavrat a {
  background-color: #a3043e;
}
.menunavrat a::before {
  content: url(images/arr-nav-back.svg);
  margin-right: 12px;
  vertical-align: middle;
  position: relative;
  top: -0.1em;
}
.navrat-fixed .menunavrat {
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 60;
  border-bottom: 6px rgba(170, 170, 170, 0.49) solid;
}
.navrat-fixed .menunavrat + .mapa-stranek-2016 {
  padding-top: 72px;
}
.menunavrat a,
.menunavrat a:link,
.menunavrat a:visited {
  color: #ffffff;
  text-decoration: none;
}
.menunavrat a:hover,
.menunavrat a:focus,
.menunavrat a:active {
  text-decoration: underline;
}
@media screen and (min-width: 601px) {
  .menunavrat {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .mapa-stranek-2016 .popis:first-child {
    display: none;
  }
}
.mapa-stranek-2016 .odkazy.souvisejici {
  margin: 0 -20px 50px -20px;
  background-color: #ca1517;
  color: #ffffff;
  padding: 10px 0 30px 0;
}
.dgm .mapa-stranek-2016 .odkazy.souvisejici, .gustavmahler .mapa-stranek-2016 .odkazy.souvisejici, .htradice .mapa-stranek-2016 .odkazy.souvisejici {
  background-color: #007d90;
}
.odpady .mapa-stranek-2016 .odkazy.souvisejici {
  background-color: #00893b;
}
.turista .mapa-stranek-2016 .odkazy.souvisejici {
  background-color: #46983b;
}
.sport .mapa-stranek-2016 .odkazy.souvisejici {
  background-color: #048fcd;
}
.doprava .mapa-stranek-2016 .odkazy.souvisejici {
  background-color: #007aae;
}
.podzemi .mapa-stranek-2016 .odkazy.souvisejici {
  background-color: #a3043e;
}
.mapa-stranek-2016 .odkazy.souvisejici li {
  clear: none;
  padding: 6px 0 0 0;
  margin-top: 0;
  margin-bottom: 0;
}
.mapa-stranek-2016 .odkazy.souvisejici li::before {
  content: normal !important;
}
.mapa-stranek-2016 .odkazy.souvisejici li div {
  display: none;
}
.mapa-stranek-2016 .odkazy.souvisejici li a {
  font-size: 1rem;
  line-height: 1.1;
  font-family: Roboto;
  padding: 8px 22px;
  display: block;
}
.mapa-stranek-2016 .odkazy.souvisejici li a::after {
  content: url(images/arr-nav.svg);
  vertical-align: middle;
  position: relative;
  float: right;
  top: 0.3em;
}
.mapa-stranek-2016 .odkazy.souvisejici li a,
.mapa-stranek-2016 .odkazy.souvisejici li a:link,
.mapa-stranek-2016 .odkazy.souvisejici li a:visited {
  color: #ffffff;
  text-decoration: none;
}
.mapa-stranek-2016 .odkazy.souvisejici li a:hover,
.mapa-stranek-2016 .odkazy.souvisejici li a:focus,
.mapa-stranek-2016 .odkazy.souvisejici li a:active {
  text-decoration: underline;
}
@media screen and (min-width: 601px) {
  .mapa-stranek-2016 .odkazy.souvisejici {
    margin-top: 30px;
    margin-left: 0;
    margin-right: 0;
    background-color: transparent !important;
    color: #666666;
    padding: 0;
  }
  .mapa-stranek-2016 .odkazy.souvisejici li {
    margin-bottom: 25px;
    border-top: 8px #d6a4a6 solid;
    position: relative;
  }
  .dgm .mapa-stranek-2016 .odkazy.souvisejici li, .gustavmahler .mapa-stranek-2016 .odkazy.souvisejici li, .htradice .mapa-stranek-2016 .odkazy.souvisejici li {
    border-top-color: #b2cfd7;
  }
  .namesti .mapa-stranek-2016 .odkazy.souvisejici li, .probereme .mapa-stranek-2016 .odkazy.souvisejici li {
    border-top-color: #a78989;
  }
  .odpady .mapa-stranek-2016 .odkazy.souvisejici li {
    border-top-color: #6d9a0b;
  }
  .turista .mapa-stranek-2016 .odkazy.souvisejici li {
    border-top-color: #c2eabd;
  }
  .sport .mapa-stranek-2016 .odkazy.souvisejici li {
    border-top-color: #6bb2d2;
  }
  .kultura .mapa-stranek-2016 .odkazy.souvisejici li {
    border-top-color: #7789d2;
  }
  .festival .mapa-stranek-2016 .odkazy.souvisejici li {
    border-top-color: #cec5c1;
  }
  .zdrave-mesto .mapa-stranek-2016 .odkazy.souvisejici li {
    border-top-color: #fbb104;
  }
  .doprava .mapa-stranek-2016 .odkazy.souvisejici li {
    border-top-color: #88bddc;
  }
  .podzemi .mapa-stranek-2016 .odkazy.souvisejici li {
    border-top-color: #b30970;
  }
  .bezodpadu .mapa-stranek-2016 .odkazy.souvisejici li {
    border-top-color: #dec08a;
  }
  .mapa-stranek-2016 .odkazy.souvisejici li div {
    margin-top: 6px;
    display: block;
  }
  .mapa-stranek-2016 .odkazy.souvisejici li a {
    font-family: TeutonNormalWeb;
    padding: 0;
    font-size: 1.375rem;
    display: inline;
  }
  .mapa-stranek-2016 .odkazy.souvisejici li a::after {
    content: normal;
  }
  .mapa-stranek-2016 .odkazy.souvisejici li a::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
  }
  .mapa-stranek-2016 .odkazy.souvisejici li a,
  .mapa-stranek-2016 .odkazy.souvisejici li a:link,
  .mapa-stranek-2016 .odkazy.souvisejici li a:visited {
    color: #ca1517;
  }
  .dgm .mapa-stranek-2016 .odkazy.souvisejici li a, .gustavmahler .mapa-stranek-2016 .odkazy.souvisejici li a, .htradice .mapa-stranek-2016 .odkazy.souvisejici li a,
  .dgm .mapa-stranek-2016 .odkazy.souvisejici li a:link,
  .gustavmahler .mapa-stranek-2016 .odkazy.souvisejici li a:link,
  .htradice .mapa-stranek-2016 .odkazy.souvisejici li a:link,
  .dgm .mapa-stranek-2016 .odkazy.souvisejici li a:visited,
  .gustavmahler .mapa-stranek-2016 .odkazy.souvisejici li a:visited,
  .htradice .mapa-stranek-2016 .odkazy.souvisejici li a:visited {
    color: #007d90;
  }
  .namesti .mapa-stranek-2016 .odkazy.souvisejici li a, .probereme .mapa-stranek-2016 .odkazy.souvisejici li a,
  .namesti .mapa-stranek-2016 .odkazy.souvisejici li a:link,
  .probereme .mapa-stranek-2016 .odkazy.souvisejici li a:link,
  .namesti .mapa-stranek-2016 .odkazy.souvisejici li a:visited,
  .probereme .mapa-stranek-2016 .odkazy.souvisejici li a:visited {
    color: #a78989;
  }
  .odpady .mapa-stranek-2016 .odkazy.souvisejici li a,
  .odpady .mapa-stranek-2016 .odkazy.souvisejici li a:link,
  .odpady .mapa-stranek-2016 .odkazy.souvisejici li a:visited {
    color: #6d9a0b;
  }
  .turista .mapa-stranek-2016 .odkazy.souvisejici li a,
  .turista .mapa-stranek-2016 .odkazy.souvisejici li a:link,
  .turista .mapa-stranek-2016 .odkazy.souvisejici li a:visited {
    color: #46983b;
  }
  .sport .mapa-stranek-2016 .odkazy.souvisejici li a,
  .sport .mapa-stranek-2016 .odkazy.souvisejici li a:link,
  .sport .mapa-stranek-2016 .odkazy.souvisejici li a:visited {
    color: #048fcd;
  }
  .kultura .mapa-stranek-2016 .odkazy.souvisejici li a,
  .kultura .mapa-stranek-2016 .odkazy.souvisejici li a:link,
  .kultura .mapa-stranek-2016 .odkazy.souvisejici li a:visited {
    color: #324ab0;
  }
  .festival .mapa-stranek-2016 .odkazy.souvisejici li a,
  .festival .mapa-stranek-2016 .odkazy.souvisejici li a:link,
  .festival .mapa-stranek-2016 .odkazy.souvisejici li a:visited {
    color: #69554b;
  }
  .zdrave-mesto .mapa-stranek-2016 .odkazy.souvisejici li a,
  .zdrave-mesto .mapa-stranek-2016 .odkazy.souvisejici li a:link,
  .zdrave-mesto .mapa-stranek-2016 .odkazy.souvisejici li a:visited {
    color: #fbb104;
  }
  .doprava .mapa-stranek-2016 .odkazy.souvisejici li a,
  .doprava .mapa-stranek-2016 .odkazy.souvisejici li a:link,
  .doprava .mapa-stranek-2016 .odkazy.souvisejici li a:visited {
    color: #88bddc;
  }
  .bezodpadu .mapa-stranek-2016 .odkazy.souvisejici li a,
  .bezodpadu .mapa-stranek-2016 .odkazy.souvisejici li a:link,
  .bezodpadu .mapa-stranek-2016 .odkazy.souvisejici li a:visited {
    color: #6d9a0b;
  }
  .podzemi .mapa-stranek-2016 .odkazy.souvisejici li a,
  .podzemi .mapa-stranek-2016 .odkazy.souvisejici li a:link,
  .podzemi .mapa-stranek-2016 .odkazy.souvisejici li a:visited {
    color: #a3043e;
  }
  .mapa-stranek-2016 .odkazy.souvisejici li:hover {
    border-top-color: #ca1517;
  }
  .dgm .mapa-stranek-2016 .odkazy.souvisejici li:hover, .podzemi .mapa-stranek-2016 .odkazy.souvisejici li:hover, .gustavmahler .mapa-stranek-2016 .odkazy.souvisejici li:hover, .htradice .mapa-stranek-2016 .odkazy.souvisejici li:hover {
    border-top-color: #159aa9;
  }
  .namesti .mapa-stranek-2016 .odkazy.souvisejici li:hover, .probereme .mapa-stranek-2016 .odkazy.souvisejici li:hover {
    border-top-color: #c46164;
  }
  .odpady .mapa-stranek-2016 .odkazy.souvisejici li:hover {
    border-top-color: #00893b;
  }
  .turista .mapa-stranek-2016 .odkazy.souvisejici li:hover {
    border-top-color: #46983b;
  }
  .sport .mapa-stranek-2016 .odkazy.souvisejici li:hover {
    border-top-color: #048fcd;
  }
  .kultura .mapa-stranek-2016 .odkazy.souvisejici li:hover {
    border-top-color: #324ab0;
  }
  .festival .mapa-stranek-2016 .odkazy.souvisejici li:hover {
    border-top-color: #69554b;
  }
  .zdrave-mesto .mapa-stranek-2016 .odkazy.souvisejici li:hover {
    border-top-color: #de5b03;
  }
  .doprava .mapa-stranek-2016 .odkazy.souvisejici li:hover {
    border-top-color: #0090cc;
  }
  .podzemi .mapa-stranek-2016 .odkazy.souvisejici li:hover {
    border-top-color: #a3043e;
  }
}
body:not(.noleftpanel) .mapa-stranek-2016 .odkazy.souvisejici li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
}
@media screen and (max-width: 739px) {
  body:not(.noleftpanel) .mapa-stranek-2016 .odkazy.souvisejici li {
    width: 100%;
  }
}
@media screen and (min-width: 740px) and (max-width: 939px) {
  body:not(.noleftpanel) .mapa-stranek-2016 .odkazy.souvisejici li {
    width: 49%;
    margin-left: 2%;
  }
  body:not(.noleftpanel) .mapa-stranek-2016 .odkazy.souvisejici li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 940px) {
  body:not(.noleftpanel) .mapa-stranek-2016 .odkazy.souvisejici li {
    width: 31.3333333333%;
    margin-left: 3%;
  }
  body:not(.noleftpanel) .mapa-stranek-2016 .odkazy.souvisejici li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
}
@media screen and (max-width: 649px) {
  .noleftpanel .mapa-stranek-2016 .odkazy.souvisejici li {
    width: 100%;
  }
}
@media screen and (min-width: 650px) and (max-width: 879px) {
  .noleftpanel .mapa-stranek-2016 .odkazy.souvisejici li {
    width: 49%;
    margin-left: 2%;
  }
  .noleftpanel .mapa-stranek-2016 .odkazy.souvisejici li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 880px) and (max-width: 1079px) {
  .noleftpanel .mapa-stranek-2016 .odkazy.souvisejici li {
    width: 31.3333333333%;
    margin-left: 3%;
  }
  .noleftpanel .mapa-stranek-2016 .odkazy.souvisejici li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1080px) {
  .noleftpanel .mapa-stranek-2016 .odkazy.souvisejici li {
    width: 22.75%;
    margin-left: 3%;
  }
  .noleftpanel .mapa-stranek-2016 .odkazy.souvisejici li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}

ul.ui li dl {
  margin-left: 0;
}

.dok .ui ul.inline {
  display: inline;
}
.dok .ui ul.inline li {
  display: inline;
  padding-left: 0;
}

#kotva2.aktualne {
  border-bottom: 3px solid #cbcbcb;
  margin-bottom: 32px;
}
#kotva2.aktualne .cvi {
  font: 400 2.125rem TeutonMagerWeb;
  padding: 11px 20px;
  background: #ca1517;
  color: white;
  display: inline-block;
}
#kotva2.aktualne .zpravy {
  font: 400 1.1875rem Roboto;
  color: #666666;
  float: right;
  display: block;
  padding-top: 10px;
}
#kotva2.aktualne .zpravy strong {
  color: #2d2d2d;
  font-weight: 900;
}
@media screen and (max-width: 600px) {
  #kotva2.aktualne .zpravy {
    display: none;
  }
}

h2.aktualne {
  padding: 0 32px;
  border-bottom: 3px solid #cbcbcb;
  margin-bottom: 32px;
}
h2.aktualne .cvi {
  font: 400 2.125rem TeutonMagerWeb;
  padding: 11px 20px;
  background: #ca1517;
  color: white;
  display: inline-block;
}
h2.aktualne .zpravy {
  font: 400 1.1875rem Roboto;
  color: #666666;
  float: right;
  display: block;
  padding-top: 10px;
}
h2.aktualne .zpravy strong {
  color: #2d2d2d;
  font-weight: 900;
}
@media screen and (max-width: 600px) {
  h2.aktualne .zpravy {
    display: none;
  }
}

#pozicovani {
  padding-bottom: 60px;
}
#pozicovani .obaldalsi {
  padding-right: 34px;
}
#pozicovani .cards ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
#pozicovani .cards li {
  margin: 0;
  box-sizing: border-box;
  padding: 0 0 35px;
  clear: right;
  position: relative;
}
#pozicovani .cards li strong a {
  color: #ca1517;
  font-family: TeutonFettWeb;
  font-size: 1.5rem;
}
#pozicovani .cards li strong a:hover {
  color: #2d2d2d;
}
#pozicovani .cards li .dok-popis:not(:empty) {
  color: #666666;
  font-family: Roboto;
  font-size: 1.125rem;
  padding-top: 16px;
}
#pozicovani .cards li .dok-upoutavka {
  float: left;
  width: 50%;
  max-width: 320px;
  margin-right: 20px;
}
#pozicovani .cards li .dok-datum {
  color: #2d2d2d;
  font-weight: 700;
  font-size: 1.125rem;
  font-family: Roboto;
  padding-top: 16px;
}
@media screen and (min-width: 801px) {
  #pozicovani .cards li {
    width: 48.2%;
    float: right;
  }
}
#pozicovani .cards.hlavni-aktualita strong a {
  font-size: 2.25rem;
}
#pozicovani .cards.hlavni-aktualita .dok-upoutavka {
  width: 100%;
  display: block;
  margin-bottom: 16px;
  max-width: 100%;
  float: none;
  padding: 0;
}
@media screen and (min-width: 801px) {
  #pozicovani .cards.hlavni-aktualita li {
    float: left;
  }
}
#pozicovani .cards2 ul {
  border-top: 3px solid #cbcbcb;
  padding: 35px 0 0;
  margin: 0;
  list-style-type: none;
}
#pozicovani .cards2 li {
  margin-top: 0;
  margin-bottom: 0;
  box-sizing: border-box;
  padding: 0 0 35px;
  clear: right;
  position: relative;
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
}
@media screen and (max-width: 900px) {
  #pozicovani .cards2 li {
    width: 100%;
  }
}
@media screen and (min-width: 901px) {
  #pozicovani .cards2 li {
    width: 48.5%;
    margin-left: 3%;
  }
  #pozicovani .cards2 li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
#pozicovani .cards2 li strong a {
  color: #ca1517;
  font-family: TeutonFettWeb;
  font-size: 1.5rem;
}
#pozicovani .cards2 li strong a:hover {
  color: #2d2d2d;
}
#pozicovani .cards2 li .dok-popis:not(:empty) {
  color: #666666;
  font-family: Roboto;
  font-size: 1.125rem;
  padding-top: 16px;
}
#pozicovani .cards2 li .dok-upoutavka {
  float: left;
  width: 35%;
  max-width: 240px;
  background-color: #e2e2e2;
  margin-right: 20px;
}
#pozicovani .cards2 li .dok-datum {
  color: #2d2d2d;
  font-weight: 700;
  font-size: 1.125rem;
  font-family: Roboto;
  padding-top: 16px;
}
@media screen and (min-width: 650px) {
  #pozicovani .skryty {
    display: none;
  }
}

@media screen and (max-width: 799px) {
  .profil.oci .uprednostnene-aktuality + .cards > ul {
    display: grid;
  }
}

.personalni-info {
  margin: 25px 0;
}

.personalni-nabidky {
  margin-bottom: 40px;
}
.personalni-nabidky ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.personalni-nabidky ul .personalni-nabidka {
  background-color: #e6e8e8;
  padding: 10px;
}
.personalni-nabidky ul .personalni-nabidka .dok-nazev strong a,
.personalni-nabidky ul .personalni-nabidka .dok-nazev strong a:link,
.personalni-nabidky ul .personalni-nabidka .dok-nazev strong a:visited {
  color: #ca1517;
  font-size: 1.25rem;
  font-weight: bold;
  text-decoration: none;
  font-family: TeutonNormalWeb;
}
.personalni-nabidky ul .personalni-nabidka .dok-nazev strong a:hover,
.personalni-nabidky ul .personalni-nabidka .dok-nazev strong a:focus,
.personalni-nabidky ul .personalni-nabidka .dok-nazev strong a:active {
  text-decoration: underline;
}
.personalni-nabidky ul .personalni-nabidka .dok-vice {
  text-align: center;
}
@media screen and (min-width: 600px) {
  .personalni-nabidky ul .personalni-nabidka .dok-vice {
    text-align: right;
  }
}
.personalni-nabidky ul .personalni-nabidka .dok-vice a,
.personalni-nabidky ul .personalni-nabidka .dok-vice a:link,
.personalni-nabidky ul .personalni-nabidka .dok-vice a:visited {
  padding: 8px;
  font-size: 1.0625rem;
  background-color: #ca1517;
  color: white;
  font-weight: bold;
  font-family: TeutonNormalWeb;
  text-decoration: none;
}
.personalni-nabidky ul .personalni-nabidka .dok-vice a:hover,
.personalni-nabidky ul .personalni-nabidka .dok-vice a:focus,
.personalni-nabidky ul .personalni-nabidka .dok-vice a:active {
  text-decoration: underline;
}
.personalni-nabidky ul .personalni-nabidka .dok-text {
  display: grid;
  grid-gap: 25px;
  align-items: flex-end;
}
@media screen and (min-width: 600px) {
  .personalni-nabidky ul .personalni-nabidka .dok-text {
    grid-template-columns: 1fr max-content;
  }
}

#kalendarAkci > .dok li strong img {
  max-width: 300px;
}
@media screen and (min-width: 800px) {
  #kalendarAkci > .dok li strong img {
    width: 300px;
  }
}

/**********************
*	FOTOGALERIE
**********************/
.nahledy li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  margin-bottom: 15px;
}
@media screen and (max-width: 399px) {
  .nahledy li {
    width: 100%;
  }
}
@media screen and (min-width: 400px) and (max-width: 799px) {
  .nahledy li {
    width: 49%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 800px) and (max-width: 1023px) {
  .nahledy li {
    width: 32%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1024px) {
  .nahledy li {
    width: 23.5%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}
.nahledy a,
.nahledy a:link,
.nahledy a:visited {
  text-decoration: none;
}
.nahledy div {
  height: 100%;
  display: block;
}
.nahledy div > a {
  border: 2px #E6E6E6 solid;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
  min-height: 200px;
}
.nahledy:not(.pridanytext) div > a {
  height: 100%;
}
.nahledy div > a:hover,
.nahledy div > a:focus,
.nahledy div > a:active {
  background: transparent;
  border-color: #ca1517;
}
.nahledy img {
  margin: 0 auto;
  width: 80%;
  height: auto;
}

/**********************
*	PŘEHLEDY GALERIÍ
**********************/
.galerie-2016 .ui {
  margin-bottom: 30px;
  margin-top: 20px;
}
.galerie-2016 .ui li {
  clear: none;
  margin-bottom: 35px;
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
}
@media screen and (max-width: 399px) {
  .galerie-2016 .ui li {
    width: 100%;
  }
}
@media screen and (min-width: 400px) and (max-width: 1023px) {
  .galerie-2016 .ui li {
    width: 49%;
    margin-left: 2%;
  }
  .galerie-2016 .ui li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1024px) {
  .galerie-2016 .ui li {
    width: 32%;
    margin-left: 2%;
  }
  .galerie-2016 .ui li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
.galerie-2016 .ui li img {
  margin: 0 0 8px 0;
  float: none;
  display: table;
}
.galerie-2016 .ui li a {
  display: block;
}
.galerie-2016 .ui li.noimage a::before {
  content: "";
  display: block;
  height: 0;
  margin-bottom: 8px;
  padding-bottom: 60%;
  background: #cccccc url(/aspinclude/vismoweb5/html/noimage.svg) no-repeat 50% 50%;
}

/***********************
*	ZÁLOŽKY
***********************/
/***********************
* DEFINICE PROMĚNNÝCH
***********************/
/**********************/
.zalozky {
  font-size: 1.25rem;
  line-height: 1.1;
  font-family: TeutonMagerWeb;
  font-weight: normal;
}
.zalozky li {
  padding: 0;
  margin: 0 0.5em 0.5em 0;
  float: left;
  position: relative;
  display: block;
}
.zalozky li.azalozka {
  background: transparent none;
}
.zalozky a {
  display: block;
  text-decoration: underline;
  margin: 0;
  padding: 8px 15px;
  position: relative;
  z-index: 20;
  cursor: pointer;
}
.zalozky a,
.zalozky a:link,
.zalozky a:visited {
  background-color: transparent;
  color: #666666;
}
.zalozky a:hover,
.zalozky a:focus,
.zalozky a:active {
  text-decoration: underline;
}
.zalozky .azalozka a,
.zalozky .azalozka a:link,
.zalozky .azalozka a:visited,
.zalozky .azalozka a:hover,
.zalozky .azalozka a:focus,
.zalozky .azalozka a:active {
  background-color: #ca1517;
  color: #ffffff;
  text-decoration: none;
  cursor: default;
}

#zalozkynadpis {
  padding: 15px 0 0 0;
}

.vych_pol {
  font-size: 1rem;
  text-align: right;
  padding: 0 0 10px 0;
  margin: 0 0 10px 0;
  text-transform: uppercase;
}
.vych_pol strong {
  font-weight: normal;
}

a[id*=k0] {
  height: 0;
  overflow: hidden;
  line-height: 0;
  font-size: 0;
  color: transparent !important;
}

/****************************
*	S VYPNUTÝM JS NA TITULCE
****************************/
.bezjs {
  padding: 20px 0 15px 0;
}
.bezjs .inline li::before {
  color: #404040;
}

#keskryti {
  display: inline;
}

/****************************
*	SE ZAPNTÝM JS NA TITULCE
****************************/
#titul .zalozky {
  font-size: 1.9375rem;
}
#titul .zalozky .inline li::before {
  content: normal;
}
#titul .zalozky a {
  padding: 15px 32px;
}
#titul .zalozky a,
#titul .zalozky a:link,
#titul .zalozky a:visited {
  background-color: transparent;
  color: #666666;
}
#titul .zalozky a:hover,
#titul .zalozky a:focus,
#titul .zalozky a:active {
  text-decoration: underline;
}
#titul .zalozky .azalozka a,
#titul .zalozky .azalozka a:link,
#titul .zalozky .azalozka a:visited,
#titul .zalozky .azalozka a:hover,
#titul .zalozky .azalozka a:focus,
#titul .zalozky .azalozka a:active {
  background-color: #ca1517;
  color: #ffffff;
}

#titul .linkynakotvy {
  position: relative;
}
#titul .linkynakotvy .zalozky {
  padding-right: 16.25rem;
}

.jezkovy-oci-link {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  font-size: 1.3125rem;
  width: 15rem;
}
.jezkovy-oci-link a,
.jezkovy-oci-link a:link,
.jezkovy-oci-link a:visited {
  background-color: #ca1517;
  color: white;
  text-decoration: none;
  font-family: TeutonMagerWeb;
  display: block;
  text-align: center;
  padding: 9px 5px;
  line-height: 1.07;
}
.jezkovy-oci-link a strong,
.jezkovy-oci-link a:link strong,
.jezkovy-oci-link a:visited strong {
  font-family: TeutonNormalWeb;
}
.jezkovy-oci-link a::after,
.jezkovy-oci-link a:link::after,
.jezkovy-oci-link a:visited::after {
  content: url(images/arr-nav.svg);
  width: 8px;
  height: 12px;
  display: inline-block;
  margin-left: 10px;
}
.jezkovy-oci-link a:hover,
.jezkovy-oci-link a:focus,
.jezkovy-oci-link a:active {
  background-color: #ca1517;
  color: white;
  text-decoration: underline;
}

@media screen and (max-width: 649px) {
  #titul .linkynakotvy {
    padding-top: 70px;
  }
  #linkynakotvy {
    display: none;
  }
  #titul .poz h2.cist {
    position: static;
    width: auto;
    height: auto;
  }
  #titul .poz .skryty {
    display: block;
  }
}
/******************
* MODULY
******************/
#uvod {
  overflow: hidden;
  margin: 0 0 30px 0;
}
#uvod h3 {
  font-weight: normal;
  margin: 10px 0;
  padding: 0;
  font-family: Roboto;
  line-height: 1.2;
}

.oci.profil #obalUpozorneni {
  position: relative;
  height: 150px;
  overflow: hidden;
}
.oci.profil #upozorneni {
  background: #ca1517;
  max-width: 680px;
  box-sizing: border-box;
  padding: 22px 33px;
  margin-top: 80px;
}
.oci.profil #upozorneni h3.cvi, .oci.profil #upozorneni a {
  color: white;
}
.oci.profil #upozorneni h3.cvi {
  margin: 0;
  padding: 0;
  line-height: 1.25;
  float: left;
}
.oci.profil #upozorneni .vice-info {
  float: right;
}
.oci.profil #upozorneni .dok-upoutavka {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.oci.profil #upozorneni .dok-upoutavka img {
  width: 100%;
}
.oci.profil .profil-titul.editor {
  margin: 45px auto 20px;
}

.profil-telo {
  margin-bottom: 50px;
}

.o-jihlave-left, .o-jihlave-right {
  padding: 50px 0;
}
.o-jihlave-left {
  padding-bottom: 0;
}
.o-jihlave-right {
  padding-top: 0;
}
@media screen and (min-width: 701px) {
  .o-jihlave-right {
    padding-bottom: 50px;
  }
  .o-jihlave-right {
    padding-top: 50px;
  }
}
.o-jihlave-left {
  font-size: 16px;
  font-size: 1rem;
  color: #a53740;
}
.o-jihlave-left p {
  margin: 0 0 30px 0;
}
.o-jihlave-left .te-medium,
.o-jihlave-left .te-big {
  line-height: 1;
}
.o-jihlave-left .te-medium {
  font-size: 31px;
  font-size: 1.9375rem;
  font-family: TeutonNormalWeb;
}
.o-jihlave-left .te-big {
  font-size: 54px;
  font-size: 3.375rem;
  font-family: TeutonNormalWeb;
}
.o-jihlave-right {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.8888888889;
}
@media screen and (min-width: 701px) {
  .o-jihlave-left {
    float: left;
    width: 24%;
    width: calc(25% - 18px);
  }
  .o-jihlave-right {
    float: right;
    width: 74%;
    width: calc(75% - 6px);
  }
}

#mbannery {
  font-size: 0.875rem;
  clear: both;
  padding: 30px 0;
  margin: 0;
  color: #000000;
  background: #ffffff;
}
#mbannery .editor {
  text-align: center;
}
#mbannery .editor a,
#mbannery .editor a:link,
#mbannery .editor a:visited,
#mbannery .editor a:hover,
#mbannery .editor a:focus,
#mbannery .editor a:active {
  color: #000000 !important;
}
#mbannery li {
  margin-top: 0;
  margin-bottom: 0;
}

#jmeniny {
  padding: 6px 12px 2px 15px;
  text-align: left;
}
#jmeniny * {
  display: inline;
}

#kontakt address {
  display: block;
  font-style: normal;
  margin: 0 0 15px 0;
  line-height: 2.0625;
}
#kontakt strong {
  font-weight: normal;
}
#kontakt .kontakt-ext img {
  margin: 0 1em 1em 0;
}

#anketa p {
  font-size: 1.125rem;
  display: inline;
  padding: 0;
  line-height: 1.4;
  margin: 0 0 1em 0;
}
#anketa ul {
  list-style-type: none;
  padding: 0;
  margin: 2em 0 5px 0;
}
#anketa ul li {
  margin-bottom: 8px;
}
#anketa .hlas {
  margin-top: 3px;
  z-index: 1;
}
#anketa .hlas div {
  height: 12px;
  background-color: #ca1517;
}

#pocitadlo {
  margin: 15px 0 0 0;
  text-align: center;
  padding-bottom: 15px;
}
#pocitadlo ul.inline {
  padding-left: 0;
  display: inline;
}
#pocitadlo ul.inline li span {
  margin: 0 0 0 2px;
}

/*****************************
* FORMULÁŘE
*****************************/
/*****************************
* DEFINICE PROMĚNNÝCH
*****************************/
/****************************/
/*****************************
* PLACEHOLDERY PRO PRVKY
*****************************/
#hledani label, .titulformular label {
  line-height: 1.2;
  text-align: left;
  padding: 3px 2px;
  display: block;
}
#hledani label.label-skryty, .titulformular label.label-skryty {
  padding: 0 2px;
  position: relative;
}
#hledani label.label-skryty::after, .titulformular label.label-skryty::after {
  content: "";
  z-index: 2;
}

/****************************/
/*************************************************
* VŠECHNY BĚŽNÉ PRVKY VE FORMULÁŘÍCH
*************************************************/
.format {
  -webkit-transition: border-color 0.15s ease-out;
  transition: border-color 0.15s ease-out;
  background-color: #ffffff;
  color: #000000;
  height: 40px;
  border-radius: 0;
  line-height: 1.2142857143;
}
.format:focus {
  border-color: #b1b1b1;
}

.format,
fieldset {
  border: 2px #d7d7d7 solid;
}

textarea.format {
  min-height: 80px;
}

select.format[multiple] {
  height: 120px;
}

.btn {
  background-color: #ca1517;
  border: 1px #ca1517 solid;
  color: #ffffff;
  height: 40px;
  font-family: TeutonFettWeb;
  font-size: 1.375rem;
}

.fbtn .btn {
  padding: 0 1em;
}

/************************************************/
.fkont {
  margin-top: 20px;
}
.fkont form {
  padding: 20px 0;
}
.fkont.nizky .fvpravo p {
  text-align: right;
}

.formular,
.fkont form,
.nastaveni {
  background-color: transparent;
}

.fcesta,
.fcesta a, .fcesta a:link, .fcesta a:visited, .fcesta a:hover, selector:focus, selector:active {
  background-color: #ca1517;
  color: #ffffff;
}

/*******************************
*	ABO A NAPIŠTE NÁM NA TITULCE
*******************************/
.titulformular label.label-skryty::after {
  background: transparent url(images/frm_prekryti.png) repeat 0 0;
}
.titulformular .format {
  margin: 2px 0;
  padding: 5px 15px;
  width: 100%;
  border-color: #ffffff;
  background-repeat: no-repeat;
  background-position: 0 0;
}
.titulformular img {
  float: left;
  margin: 0 8px 0 0;
}
.titulformular .captchaformat {
  width: 102px;
  margin: 0;
  float: right;
}
.titulformular .fbtn,
.titulformular .btn {
  clear: none;
  margin: 0;
  float: right;
}
.titulformular .fbtn {
  width: 102px;
}
.titulformular .btn {
  width: 100%;
}
.titulformular p {
  padding: 0 0 10px 0;
  margin: 0;
}
.titulformular p.opiste {
  padding: 6px 0 0 0;
}

/***************
*	HLEDÁNÍ
***************/
#hledani {
  overflow: hidden;
  position: relative;
  top: 9px;
  padding: 0 15px;
}
#stranka #hledani {
  clear: both;
}
@media screen and (min-width: 501px) {
  #hledani {
    float: right;
    width: 164px;
    padding: 0;
    top: 0;
    margin-top: 24px;
  }
  #stranka #hledani {
    clear: none;
  }
}
.havireni #hledani {
  padding-bottom: 14px;
}
#hledani .pole {
  position: relative;
  z-index: 5;
}
#hledani label.label-skryty::after {
  background: transparent url(images/hl_prekryti.png) repeat 0 0;
}
@media screen and (max-width: 500px) {
  #hledani label {
    display: none;
  }
}
#hledani input:focus {
  outline: 0;
}
#hledani .format {
  font-size: 16px;
  font-size: 1rem;
  width: 100%;
  margin: 0;
  height: 26px;
  border-width: 1px;
  border-color: #d7d7d7;
  color: #000000;
  padding: 4px 30px 4px 8px;
  background-repeat: no-repeat;
  background-position: 0 50%;
}
#hledani .btn {
  border: 0;
  background-color: transparent;
  position: absolute;
  z-index: 2;
  top: 1px;
  right: 1px;
  height: auto;
  color: #000000;
}

/*******************************
* KALENDÁŘE
*******************************/
/*******************************
* DEFINICE PROMĚNNÝCH 
*******************************/
/******************************/
/********************************
* KALENDÁŘ NA TITULCE
********************************/
#kalakci p {
  clear: both;
}
#kalakci .mesice {
  display: table;
  width: 100%;
  line-height: 0.8;
  table-layout: fixed;
  border-spacing: 1px 0;
  margin-bottom: 10px;
}
#kalakci .mesice + p {
  margin: 20px 0;
}
#kalakci .mesice a {
  display: table-cell;
  padding: 1em 0;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
}
#kalakci .mesice a.sipka {
  width: 14.2857142857%;
  font-family: Roboto;
  position: relative;
}
#kalakci .mesice a,
#kalakci .mesice a:link,
#kalakci .mesice a:visited {
  color: #ffffff;
  background-color: #4A4A4A;
}
#kalakci .mesice a:hover,
#kalakci .mesice a:focus,
#kalakci .mesice a:active {
  background-color: #000000;
}
#kalakci .calcells th {
  font-weight: normal;
  color: #444444;
  background-color: transparent;
  border-bottom: 10px transparent solid;
  background-clip: padding-box;
}
#kalakci .calcells th,
#kalakci .calcells td {
  padding: 7px 0;
}
#kalakci .calcells td > a {
  padding: 7px 0;
  margin: -7px 0;
}
#kalakci .calcells td,
#kalakci .calcells td > a, #kalakci .calcells td a:link, #kalakci .calcells td a:visited {
  background-color: #ca1517;
  color: #ffffff;
}
#kalakci .calcells td.notmnth {
  background-color: transparent;
  color: #444444;
}
#kalakci .calcells td.notmnth > a, #kalakci .calcells td.notmnth a:link, #kalakci .calcells td.notmnth a:visited {
  background-color: transparent;
  color: #444444;
}
#kalakci .calcells td.curdate {
  border: 2px #ffffff solid;
  padding: 0;
}
#kalakci .calcells td.curdate > a {
  padding: 5px 0;
  margin: -2px 0;
}
#kalakci .calcells td.wkday > a:hover, #kalakci .calcells td.wkday a:focus, #kalakci .calcells td.wkday a:active, #kalakci .calcells td.notmnth > a:hover, #kalakci .calcells td.notmnth a:focus, #kalakci .calcells td.notmnth a:active {
  background-color: #ffffff;
  color: #000000;
}

/********************************
* KALENDÁŘE NA PODSTRÁNKÁCH
********************************/
#stranka #kalakci .calcells td.wkday,
#stranka #kalakci .calcells td.wkday > a, #stranka #kalakci .calcells td.wkday a:link, #stranka #kalakci .calcells td.wkday a:visited, #stranka #kalakci .calcells td.wkend,
#stranka #kalakci .calcells td.wkend > a, #stranka #kalakci .calcells td.wkend a:link, #stranka #kalakci .calcells td.wkend a:visited {
  background-color: #ca1517;
  color: #ffffff;
}
#stranka #kalakci .calcells td > a, #stranka #kalakci .calcells td a:link, #stranka #kalakci .calcells td a:visited {
  background-color: transparent;
  color: #444444;
}
#stranka #kalakci .calcells td.curdate {
  border: 2px #ffffff solid;
  border-color: #ffffff;
}
#stranka #kalakci .calcells td.wkday > a:hover, #stranka #kalakci .calcells td.wkday a:focus, #stranka #kalakci .calcells td.wkday a:active, #stranka #kalakci .calcells td.wkend > a:hover, #stranka #kalakci .calcells td.wkend a:focus, #stranka #kalakci .calcells td.wkend a:active, #stranka #kalakci .calcells td.notmnth > a:hover, #stranka #kalakci .calcells td.notmnth a:focus, #stranka #kalakci .calcells td.notmnth a:active {
  background-color: #ffffff;
  color: #000000;
}
#stranka #kalakci .calcells td.cell_selected,
#stranka #kalakci .calcells td.cell_selected > selector-link-all {
  background-color: #B0030C;
  color: #ffffff;
}

/*****************************************
* DEKLARACE PROMĚNNÝCH
*****************************************/
/****************/
/*****************************************
* MIXINY A PROMĚNNÉ PRO TABULKOVÉ MENU
*****************************************/
/****************************************/
/******************************
* HORIZONTÁLNÍ MENU A VÝBĚRY
******************************/
#menu1 {
  font-size: 18px;
  font-size: 1.125rem;
  position: relative;
  padding: 42px 15px 0 15px;
  margin-bottom: 20px;
  clear: both;
}
.profil.oci #menu1 {
  padding-top: 32px;
}
@media screen and (min-width: 1001px) {
  .profil.oci #menu1 {
    padding-top: 52px;
  }
}
#menu1 ul {
  line-height: 1.2;
}
#menu1 li {
  margin-bottom: 10px;
}
@media screen and (min-width: 601px) {
  #menu1 {
    float: left;
    clear: none;
    padding-left: 0;
    padding-right: 0;
  }
  #menu1 li {
    margin-left: 24px;
    margin-bottom: 0;
    float: left;
  }
}
@media screen and (min-width: 701px) {
  #menu1 li {
    margin-left: 40px;
  }
}
#menu1 div {
  display: inline;
}
#menu1 a,
#menu1 a:link,
#menu1 a:visited {
  color: #666666;
  text-decoration: none;
}
@media screen and (max-width: 500px) {
  body:not(.profil.oci) #menu1 a,
  body:not(.profil.oci) #menu1 a:link,
  body:not(.profil.oci) #menu1 a:visited {
    color: #ffffff;
  }
}
#menu1 a:hover,
#menu1 a:focus,
#menu1 a:active {
  text-decoration: underline;
}
@media screen and (max-width: 599px) {
  #stranka:not(.profil.oci) #menu1 {
    display: none;
  }
}

/*@include bp("> 500px", "<= 800px") {
	#menu1 {
		ul {
			@include flex-base;
			@include flex-wrap(wrap);
		}

		li {
			@include box-sizing;
			@include grid-column(2, 2%);
			float: left;
			margin-bottom: 15px;
		}
	}
}*/
/*@include bp("> 800px") {
	#menu1,
	#vybrane {
		@include make-spacing( $menu-spacing );
	}

	#menu1 ul,
	#vybrane .bg {
		@include make-row( $menu-spacing );
	}

	#menu1 li,
	#vybrane [class*="sekce"] {
		@include make-columns;
	}
}*/
/*#menu1 {
	ul {
		@extend %reset-list;
	}

	li {
		font-size: rem(20px);
		line-height: 1.1;

		&.akt {
			border: 5px #000 solid;
		}

		a {
			display: block;
			font-family: $font;
			padding: 14px 20px;
		}

		@include link {
			text-decoration: none;
			color: #ffffff;
		}

		@include link-over {
			text-decoration: underline;
		}

		@include link-all("#osmakth") {
			cursor: text;
			text-decoration: none;
		}
	}
}*/
/*************************
* VÝBĚRY MENU
*************************/
/*#vybrane {
	display: none;

	@include bp("> 800px") {
		display: block;
	}

	.bg {
		@include link {
			color: #ffffff;
			text-decoration: none;
		}

		@include link-over {
			text-decoration: underline;
		}
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 15px 22px 10px 22px;

		li {
			padding: 0;
			display: inline;
		}

		.pam {
			font-size: rem(11px);
			border-left: 1px #ffffff solid;
			margin: 0 0px 0 9px;
			padding: 0;
			vertical-align: middle;
			position: relative;
			top: -1px;
		}

		.pampv .pam {
			display: none;
		}

		@include link(".pamdalsi a") {
			text-decoration: underline;
		}
	}
}*/
/*********************
* MENU VE SLOUPCI
*********************/
.nadmenu {
  display: block;
  font-weight: normal;
  padding: 0 15px 12px 45px;
  font-family: TeutonFettWeb;
  font-size: 1.5rem;
  line-height: 1.1;
}
.nadmenu::before {
  content: url(images/arr-nav-back.svg);
  margin-right: 12px;
  vertical-align: middle;
  position: relative;
  top: -0.1em;
}

#menu {
  background-color: #ca1517;
  color: #ffffff;
  padding-top: 44px;
  padding-bottom: 64px;
}
.dgm #menu, .gustavmahler #menu, .htradice #menu {
  background-color: #007d90;
}
.odpady #menu {
  background-color: #00893b;
}
.informacni-portal #menu {
  background-color: #565656;
}
.doprava #menu {
  background-color: #007aae;
}
.turista #menu {
  background-color: #46983b;
}
.sport #menu {
  background-color: #048fcd;
}
.kultura #menu {
  background-color: #324ab0;
}
.festival #menu {
  background-color: #69554b;
}
.podzemi #menu {
  background-color: #a3043e;
}
.bezodpadu #menu {
  background-color: #6d9a0b;
}
#menu a,
#menu a:link,
#menu a:visited {
  color: #ffffff;
  text-decoration: none;
}
#menu a:hover,
#menu a:focus,
#menu a:active {
  text-decoration: underline;
}
#menu #osmakth,
#menu #osmakth:link,
#menu #osmakth:visited,
#menu #osmakth:hover,
#menu #osmakth:focus,
#menu #osmakth:active {
  font-weight: bold;
  color: #ca1517;
  background-color: #ffffff;
  text-decoration: none;
  cursor: default;
}
.dgm #menu #osmakth, .gustavmahler #menu #osmakth, .htradice #menu #osmakth,
.dgm #menu #osmakth:link,
.gustavmahler #menu #osmakth:link,
.htradice #menu #osmakth:link,
.dgm #menu #osmakth:visited,
.gustavmahler #menu #osmakth:visited,
.htradice #menu #osmakth:visited,
.dgm #menu #osmakth:hover,
.gustavmahler #menu #osmakth:hover,
.htradice #menu #osmakth:hover,
.dgm #menu #osmakth:focus,
.gustavmahler #menu #osmakth:focus,
.htradice #menu #osmakth:focus,
.dgm #menu #osmakth:active,
.gustavmahler #menu #osmakth:active,
.htradice #menu #osmakth:active {
  color: #007d90;
}
.informacni-portal #menu #osmakth,
.informacni-portal #menu #osmakth:link,
.informacni-portal #menu #osmakth:visited,
.informacni-portal #menu #osmakth:hover,
.informacni-portal #menu #osmakth:focus,
.informacni-portal #menu #osmakth:active {
  color: #565656;
}
.odpady #menu #osmakth,
.odpady #menu #osmakth:link,
.odpady #menu #osmakth:visited,
.odpady #menu #osmakth:hover,
.odpady #menu #osmakth:focus,
.odpady #menu #osmakth:active {
  color: #00893b;
}
.turista #menu #osmakth,
.turista #menu #osmakth:link,
.turista #menu #osmakth:visited,
.turista #menu #osmakth:hover,
.turista #menu #osmakth:focus,
.turista #menu #osmakth:active {
  color: #46983b;
}
.sport #menu #osmakth,
.sport #menu #osmakth:link,
.sport #menu #osmakth:visited,
.sport #menu #osmakth:hover,
.sport #menu #osmakth:focus,
.sport #menu #osmakth:active {
  color: #048fcd;
}
.kultura #menu #osmakth,
.kultura #menu #osmakth:link,
.kultura #menu #osmakth:visited,
.kultura #menu #osmakth:hover,
.kultura #menu #osmakth:focus,
.kultura #menu #osmakth:active {
  color: #324ab0;
}
.festival #menu #osmakth,
.festival #menu #osmakth:link,
.festival #menu #osmakth:visited,
.festival #menu #osmakth:hover,
.festival #menu #osmakth:focus,
.festival #menu #osmakth:active {
  color: #69554b;
}
.doprava #menu #osmakth,
.doprava #menu #osmakth:link,
.doprava #menu #osmakth:visited,
.doprava #menu #osmakth:hover,
.doprava #menu #osmakth:focus,
.doprava #menu #osmakth:active {
  color: #007aae;
}
.podzemi #menu #osmakth,
.podzemi #menu #osmakth:link,
.podzemi #menu #osmakth:visited,
.podzemi #menu #osmakth:hover,
.podzemi #menu #osmakth:focus,
.podzemi #menu #osmakth:active {
  color: #a3043e;
}
.bezodpadu #menu #osmakth,
.bezodpadu #menu #osmakth:link,
.bezodpadu #menu #osmakth:visited,
.bezodpadu #menu #osmakth:hover,
.bezodpadu #menu #osmakth:focus,
.bezodpadu #menu #osmakth:active {
  color: #6d9a0b;
}
#menu .aktodk,
#menu .aktodk:link,
#menu .aktodk:visited {
  font-weight: bold;
  color: #ca1517;
  background-color: #ffffff;
}
.dgm #menu .aktodk, .gustavmahler #menu .aktodk, .htradice #menu .aktodk,
.dgm #menu .aktodk:link,
.gustavmahler #menu .aktodk:link,
.htradice #menu .aktodk:link,
.dgm #menu .aktodk:visited,
.gustavmahler #menu .aktodk:visited,
.htradice #menu .aktodk:visited {
  color: #007d90;
}
.odpady #menu .aktodk,
.odpady #menu .aktodk:link,
.odpady #menu .aktodk:visited {
  color: #00893b;
}
.turista #menu .aktodk,
.turista #menu .aktodk:link,
.turista #menu .aktodk:visited {
  color: #46983b;
}
.sport #menu .aktodk,
.sport #menu .aktodk:link,
.sport #menu .aktodk:visited {
  color: #048fcd;
}
.kultura #menu .aktodk,
.kultura #menu .aktodk:link,
.kultura #menu .aktodk:visited {
  color: #324ab0;
}
.festival #menu .aktodk,
.festival #menu .aktodk:link,
.festival #menu .aktodk:visited {
  color: #69554b;
}
.doprava #menu .aktodk,
.doprava #menu .aktodk:link,
.doprava #menu .aktodk:visited {
  color: #007aae;
}
.podzemi #menu .aktodk,
.podzemi #menu .aktodk:link,
.podzemi #menu .aktodk:visited {
  color: #a3043e;
}
#menu .aktodk:hover,
#menu .aktodk:focus,
#menu .aktodk:active {
  text-decoration: underline;
  cursor: pointer;
}
#menu .menu {
  font-size: 1.125rem;
  font-family: Roboto;
  line-height: 1.2;
}
#menu .menu a {
  padding: 10px 15px;
  display: block;
  position: relative;
  z-index: 10;
}
#menu li {
  margin: 0;
  padding: 0 0 0 30px;
  position: relative;
  display: block;
}

#google_translate_element {
  display: inline-block;
  position: relative;
  display: none;
  overflow: hidden;
  flex-shrink: 0;
}
#google_translate_element.ready {
  margin-left: 12px;
  display: inline-block;
  top: 4px;
}
#google_translate_element .goog-te-select {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  opacity: 0 !important;
}
#google_translate_element .goog-te-gadget {
  border: 0;
  width: 100%;
  padding-bottom: 1px;
  background-color: transparent;
  font-size: 0 !important;
  position: relative;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  /* &::after {
     content: '';
     margin-left: 8px;
     width: 0;
     height: 0;
     border-style: solid;
     border-width: 6px 4px 0 4px;
     border-color: grey transparent transparent transparent;
  } */
}
#google_translate_element .goog-te-gadget .goog-te-gadget-simple {
  display: none !important;
}
#google_translate_element .goog-te-gadget > * {
  font-size: 1rem !important;
}
#google_translate_element .goog-te-gadget::before {
  content: "";
  width: 19px;
  height: 19px;
  background-image: url(/aspinclude/vismoWeb5/html/images/gte-icon.svg) !important;
  background-position: 0 0 !important;
  background-size: 100% auto;
  background-repeat: no-repeat;
}
#google_translate_element .goog-te-gadget .goog-te-gadget-icon + span {
  display: none;
}

body[style] {
  top: 0 !important;
}

#goog-gt-tt,
#goog-gt-tt + div > object,
iframe.goog-te-menu-frame,
iframe.goog-te-banner-frame {
  display: none !important;
}

/*************************
* DISKUZE
*************************/
/*************************
* ÚVOD DISKUZE - PŘEHLED
*************************/
.diskuzeTable td,
.diskuzeTable th,
.diskuzeTable {
  border-color: #EDEDE8;
}

.diskuzeTable th {
  background-color: #EDEDE8;
  border: 1px white solid;
  color: #000000;
  padding: 8px 20px;
}
.diskuzeTable th a, .diskuzeTable th a:link, .diskuzeTable th a:visited,
.diskuzeTable th a:active,
.diskuzeTable th a:focus {
  color: #ca1517;
}
.diskuzeTable th a:hover {
  color: #000000;
}
.diskuzeTable div {
  background-color: #f7f6f6;
}
.diskuzeTable th.typfora div {
  background-color: #656460;
  color: #ffffff;
}

/*************************
* VLASTNÍ DISKUZE
*************************/
.ZOtec,
#legenda .otec {
  background-color: #ca1517;
  color: #ffffff;
}

.podzahlavim,
.reakce {
  background-color: #E4E4E4;
}

.ZReakce {
  background-color: #464545;
  color: #ffffff;
}

.reakce .podzahlavim {
  background-color: #EFEFEF;
}

.dnesni {
  background-color: #FF0000;
  color: #000000;
}

.vcerejsi {
  background-color: #FECB00;
  color: #000000;
}

.ZReakce a,
.ZReakce a, .ZReakce a:link, .ZReakce a:visited, .ZReakce a:hover, selector:focus, selector:active,
.reakce .ikony a,
#legenda dt.reakce a {
  color: #ffffff;
}

.ZOtec a, .ZOtec a:link, .ZOtec a:visited, .ZOtec a:hover, selector:focus, selector:active,
.otec .ikony a,
#legenda dt.otec a {
  color: #ffffff;
}

.podzahlavim a,
.podzahlavim a:link,
.podzahlavim a:visited,
.podzahlavim a:hover,
.podzahlavim a:focus,
.podzahlavim a:active {
  color: #ca1517;
}

#legenda dd {
  margin-left: 3em;
}

.podzahlavim strong {
  color: #000000;
}

#celek .feditace form div {
  border-color: white;
}

.diskuze .ikony,
.diskuze .ikony a {
  width: auto;
  color: #ffffff;
}
.diskuze .ikony a {
  padding: 0 5px;
  display: inline-block;
  float: none;
}

/***************************
* PŘEPISY TINYMCE
***************************/
#diskuze .mce-btn button {
  width: auto;
}

#celek .feditace form .mce-container,
#celek .feditace form .mce-container div {
  padding: 0;
}
#celek .feditace form .mce-container .mce-statusbar {
  padding: 3px 5px;
}