// Sablona - vsechny stranky

/**************************
* DEFINICE PROMĚNNÝCH
**************************/

$zakladniSirka: 1280px;

/*************************/

/**************************
* OBECNÉ
**************************/

html,
body {
   background-color: #ffffff;
}

body {
   @extend %reset;
   text-align: center;
}

a {
   text-decoration-skip-ink: auto;
}

@include selection {
   background-color: $color-main;
   color: #ffffff;
}

#fuck-ie8 {
   position: fixed;
   z-index: 300;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   background: transparent url(/html/images/alpha.png) repeat 0 0;
   width: 100%;
   height: 100%;
   display: table;
   font-family: Roboto, Arial, Helvetica, sans-serif;
}

#ie8-modal-wrap {
   display: table-cell;
   vertical-align: middle;
}

#ie8-modal {
   background-color: #ffffff;
   border-radius: 5px;
   text-align: center;
   width: 420px;
   padding: 20px;
   margin: 0 auto;
}

#ie8-modal img,
#ie8-modal p {
   margin-bottom: 30px;
}

#ie8-modal a {
   display: inline-block;
   border-radius: 3px;
   background-color: #2b985f;
   padding: 8px 25px;
}

#ie8-modal a,
#ie8-modal a:link,
#ie8-modal a:visited {
   text-decoration: none;
   color: #ffffff;
}

#ie8-modal a:hover,
#ie8-modal a:focus,
#ie8-modal a:active {
   text-decoration: underline;
}

/*************************
* HLAVNÍ OBSAH
*************************/

.centrovany {
   @extend %border-box;
   @extend %sf;
   width: 100%;
   max-width: $zakladniSirka;
   margin: 0 auto;
   text-align: left;

   &--padded-left {
      padding-left: 20px;
   }

   &--padded-right {
      padding-right: 20px;
   }

   &--padded-both {
      padding-left: 20px;
      padding-right: 20px;
   }

   &--padded-border-bottom {
      margin-bottom: 60px;
      border-bottom: 3px solid #cbcbcb;
   }
   &--padded-bottom {
      padding-bottom: 60px;
   }

   .unshift-left {
      margin-left: -20px;
   }

   .unshift-right {
      margin-right: -20px;
   }

   @include bp('> 600px') {
      &--padded-left {
         padding-left: 32px;
      }

      &--padded-right {
         padding-right: 32px;
      }

      &--padded-both {
         padding-left: 32px;
         padding-right: 32px;
      }

      .unshift-left {
         margin-left: -32px;
      }

      .unshift-right {
         margin-right: -32px;
      }
   }

   @include bp('> 1024px') {
      &--padded-both {
         padding-left: 100px;
         padding-right: 100px;
      }

      &--padded-left {
         padding-left: 100px;
      }

      &--padded-right {
         padding-right: 100px;
      }

      .unshift-left {
         margin-left: -100px;
      }

      .unshift-right {
         margin-right: -100px;
      }
   }
}

#stranka #celek {
   padding-left: 20px;

   @include bp('> 600px') {
      padding-left: 0;
   }
}

#stranka.profil.oci #celek {
   padding-right: 0;
}

#stred,
#vpravo,
#vlevo {
   @extend %border-box;
   width: 100%;
   float: none;
   margin-bottom: 30px;
}

#vpravo,
#vlevo {
   word-wrap: break-word;
}

#pozicovani {
   body:not(.profil.oci) & {
      margin-bottom: 72px;
   }

   body:not(.profil) & .centrovany {
      // background: #ededed url(images/bg-pattern.svg) repeat 0 0;
      background-color: #f2f0f0;
   }

   .poz {
      padding-top: 36px;
   }

   .poz ~ .poz {
      .dok {
         display: none;
      }

      .mobile-link {
         margin: 0;
         display: block;

         a {
            display: block;
            text-align: center;
            padding: 12px 1em;
            background-color: $color-main;
         }

         @include link {
            color: #ffffff;
            text-decoration: none;
            font-size: rem(26px);
            font-family: $font-mager;
         }

         @include link-over {
            text-decoration: underline;
         }
      }

      h2,
      .obaldalsi {
         display: none;
      }

      @include bp('>= 650px') {
         .dok,
         .obaldalsi {
            display: block;
         }

         .mobile-link {
            display: none;
         }

         h2 {
            display: block;
         }
      }
   }

   @include bp('>= 650px') {
      .skryty {
         display: none;
      }
   }

   .profil & h2.cvi {
      font-size: rem(44px);
   }

   .dok {
      margin-bottom: 22px;

      @include bp('> 600px') {
         ul {
            @include flex-base;
            @include flex-wrap(wrap);
         }
      }

      li {
         @extend %border-box;
         @include grid(600px 2 20px, 1024px 3);
         margin-top: 27px;
         margin-bottom: 0;
         position: relative;
         padding: 10px 20px 18px 20px;
         background-color: rgba(#ffffff, 0.6);

         .profil & {
            padding: 6px 0 18px 0;
         }

         .havireni & > strong:first-child {
            @include link {
               color: $color-havireni;
            }
         }

         &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: -21px;
            background-color: $color-main;
            height: 13px;

            .dgm &,
            .gustavmahler &,
            .htradice & {
               background-color: #b2cfd7;
            }

            .namesti &,
            .probereme & {
               background-color: #a78989;
            }

            .odpady & {
               background-color: #6d9a0b;
            }

            .zdrave-mesto & {
               background-color: #fbb104;
            }

            .doprava & {
               background-color: #88bddc;
            }

            .podzemi & {
               background-color: #c36065;
            }

            .havireni & {
               background-color: #b2d7bd;
            }
         }

         &:hover::after {
            .dgm &,
            .gustavmahler &,
            .htradice & {
               background-color: #159aa9;
            }

            .namesti &,
            .probereme & {
               background-color: #c46164;
            }

            .odpady & {
               background-color: #00893b;
            }

            .zdrave-mesto & {
               background-color: #de5b03;
            }

            .doprava & {
               background-color: #0090cc;
            }

            .podzemi & {
               background-color: $color-podzemi;
            }

            .havireni & {
               background-color: #15a82f;
            }
         }

         &.jihlavaslozka {
            strong a {
               color: #6c6b6b;
            }

            &::after {
               background-color: #b3b3b2;
            }
         }
      }
   }
}

/*************************************
* TABULKOVÝ LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/

#vlevo {
   display: none;
}

@include bp('> 600px') {
   #telo {
      display: table;
      table-layout: fixed;
      border-spacing: 0;
      width: 100%;
   }

   #stred,
   #vlevo {
      display: table-cell;
      vertical-align: top;
   }

   #stred {
      width: auto;
      padding-left: 24px;

      .noleftpanel & {
         padding-left: 46px;
      }

      .oci.profil.noleftpanel & {
         padding-left: 0;
         padding-right: 0 !important;
      }
   }

   #vlevo {
      width: 292px;
   }
}

@include bp('> 860px') {
   #vlevo {
      width: 352px;
   }
}

/*************************************
* FLOATOVANÝ LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/

// #telo,
// #stred {
// 	@extend %sf;
// }

// @include bp(">= 800px") {
// 	#stred {
// 		float: left;
// 		width: ratio($zakladniSirka, 880px);

// 		#stranka & {
// 			float: right;
// 		}

// 		.noleftpanel & {
// 			width: 100%;
// 		}
// 	}

// 	#vpravo,
// 	#vlevo {
// 		width: ratio($zakladniSirka, 360px);
// 	}

// 	#vpravo {
// 		float: right;
// 	}

// 	#vlevo {
// 		float: left;
// 	}
// }

/************************
* PRVKY STRÁNKY
************************/

.obalcesta {
   background-color: #f6f6f6;
   padding: 15px 0;
}

.cesta {
   padding: 0 20px;
   margin: 0;
   position: relative;
   font-weight: 200;
   color: #666666;

   @include bp('> 650px') {
      padding: 0 0 0 46px;
   }

   a {
      &::after {
         content: url(images/arr-path.svg);
         margin: 0 0.72em;
      }

      &:last-of-type::after {
         content: normal;
      }
   }

   span::before {
      content: url(images/arr-path.svg);
      margin: 0 0.72em;
   }

   strong + span::before {
      content: normal;
   }

   @include link {
      color: #666666;
   }
}

.noleftpanel {
   .dok,
   .perex,
   .uvodprvku,
   .editor,
   .bezny {
      max-width: 70ch;
   }
}

.noleftpanel.profil.oci {
   .dok,
   .perex,
   .uvodprvku,
   .editor,
   .bezny {
      max-width: 1280px;
   }
}

.box {
   padding: 0 15px 10px 15px;
   background: #cccccc;
   margin: 0 0 15px 0;
}

.dalsi {
   font-size: rem(18px);
   @extend %reset;
   text-align: right;
   clear: both;
   position: relative;

   a {
      background-color: $color-main;
      display: inline-block;
      padding: 8px 15px 10px 15px;
      line-height: 1.1;

      &.nahoru {
         text-decoration: none !important;
         text-align: center;
         min-width: 18px;
         margin-left: 7px;
         background: {
            color: #b3b3b2;
            repeat: no-repeat;
            position: 50% 50%;
            image: url(images/arr-top.svg);
         }

         &::before {
            color: transparent;
            content: 'n';
         }
      }
   }

   @include link {
      text-decoration: none;
      color: #ffffff;
   }

   @include link-over {
      text-decoration: underline;
   }
}

/************************
* ZÁPATÍ
************************/

#nadpatou {
   color: #444444;

   #content-odkazy {
      background: #d7d7d7;
   }

   @include link {
      color: #444444;
   }

   .footer-col {
      @include grid(500px 2 22px, 900px 4);
      .topakce & {
         @include grid(700px 3 22px);
      }
      margin-bottom: 30px;

      + .sf {
         display: none;
      }

      &.heading-less {
         &::before {
            padding: 18px 0 4px 0;
            display: block;
            content: 'Odsazení';
            color: transparent;
            font-family: $font-normal;
            line-height: 1.2;
            font-size: rem(21px);
         }
      }
   }

   h3.cvi,
   h2.cvi {
      margin: 0;
      padding: 18px 0 4px 0;
   }

   h2.cvi {
      font-family: $font-normal;
      font-size: rem(21px);
      font-weight: normal;
      line-height: 1.2;
      color: #585758;
   }

   .ui {
      @extend %reset-list;
      margin: 0;
      padding: 0;

      strong {
         font-weight: normal;

         a {
            font-weight: normal;
            font-family: $font;
            font-size: rem(16px);
         }
      }

      @include link {
         text-decoration: none;
      }

      @include link-over {
         text-decoration: underline;
      }

      li {
         margin: 5px 0 11px 0;
      }
   }
}

#pata {
   position: relative;
   background: {
      //image: url(images/bg-footer.svg);
      repeat: no-repeat;
      position: 0 100%;
   }

   object,
   .bezodpadu-logo {
      position: absolute;
      left: 0;
      bottom: 0;
   }

   .bezodpadu-logo {
      width: 250px;
   }

   .dgm & {
      background-image: url(images/logo-dgm.svg);
      background-size: 300px auto;

      object {
         display: none;
      }
   }

   @include bp('> 800px') {
      background-position: 0 15px;

      object,
      .bezodpadu-logo {
         top: 15px;
         bottom: auto;
      }
   }

   font-size: rem(14px);
   text-align: right;
   padding-top: 30px;
   padding-bottom: 60px;

   .patalogo {
      padding: 0;

      &[href*='webhouse'],
      &[href*='vismo'] {
         font-family: $sans-serif;
      }
   }

   .webmaster,
   .inline {
      display: block;
      margin: 0 0 12px 0;
      padding: 0;
   }

   @include link {
      color: #666666;
      text-decoration: underline;
   }

   @include link('.promoted') {
      color: $color-main;
   }

   @include link-over {
      text-decoration: none;
   }
}

.strlistovani,
#listovani {
   img {
      background-color: #bbbbbb;
      color: #000000;
   }

   .aktivni {
      img {
         background-color: #555555 !important;
         color: #ffffff;
      }

      &:hover img,
      &:focus img {
         background-color: #bbbbbb !important;
         color: #000000;
      }
   }
}

.volny-cas {
   margin-bottom: 50px;

   h2.cvi {
      span {
         background-color: #a53740;
         color: #ffffff;
      }
   }

   .volny-cas-content {
      background: #1a1b16 url(images/bg-volny-cas.jpg) no-repeat 50% 0;
      background-size: cover;
   }

   .volny-cas-info {
      @extend %border-box;
      @include font-size(25px);
      background-color: rgba(#ffffff, 0.82);
      padding: 46px 22px;
      //margin-bottom: -38px;
      color: #a53740;
      font-family: $font-mager;
      line-height: (45 / 25);

      @include bp('> 1024px') {
         float: left;
         width: calc(25% + 82px);
      }

      strong {
         font-family: $font-normal;
      }
   }
}

#titul .editor ul.mbannery {
   text-align: center !important;
}

/**************************************
* PROFILKA VOLNÝ ČAS
**************************************/

.columns {
   margin-bottom: 55px;

   &.centrovany--padded-right {
      @include bp('<= 800px') {
         padding-left: 3.85%;
      }

      @include bp('<= 600px') {
         padding-left: 20px;
      }
   }

   .column {
      @include box-sizing;

      @include bp('> 800px') {
         float: left;
         width: ratio(1180px, 362px);

         &.column--bigger {
            float: right;
            width: ratio(1180px, 805px);
         }

         .osnova {
            .nakal {
               content: '';
               padding-bottom: 81%;
               display: block;
               background: {
                  image: url(images/kalendar.png);
                  repeat: no-repeat;
                  position: 50% 0;
                  size: 100% auto;
               }

               .en & {
                  background-image: url(images/kalendar_en.png);
               }

               .de & {
                  background-image: url(images/kalendar_de.png);
               }
            }
         }
      }

      .osnova {
         @include bp('> 800px') {
            padding-left: 20px;
         }

         @include bp('> 1340px') {
            padding-left: 0;
         }
      }
   }
}

.halves {
   .half {
      @include box-sizing;
      @include grid(650px 2 22px);
      background-color: #f6f6f6;
      margin-bottom: 22px;
      padding: 18px 24px 24px 24px;

      h2.cvi {
         font-family: $font-normal;
         font-size: rem(25px);
         display: inline;

         &::after {
            content: '\|';
            margin: 0 0.35em 0 0.6em;
         }
      }
   }
}

.profil-animace {
   display: none;
   text-align: center;

   @include bp('> 750px') {
      display: block;

      iframe {
         margin: 0 auto;
      }
   }
}

.akce-podle-data {
   margin: 30px 0;

   .inline {
      @extend %reset-list;
      display: inline;
   }
}

table.calendar {
   background-color: #ffffff;
}

#prepinacsportoviste {
   font-weight: bold;
   padding: 20px 0 30px;
}

.sportovistepata {
   padding: 25px 0;
}

// profilka jezkovy oci

.profil.oci {
   .volAdmin {
      clear: both;
   }

   #pozicovani {
      padding-top: 30px;

      .cards .dok-upoutavka {
         img {
            width: 100%;
         }
      }
   }

   @include bp('> 1000px') {
      &.fixed-menu {
         #obalhlava {
            position: fixed;
            width: 100%;
            left: 0;
            top: 0;
            z-index: 99;
            box-shadow: 0 0 49px rgba(#000000, 0.16);
         }

         &#stranka #obalcelek,
         #obalobrazek {
            padding-top: 118px;
         }
      }

      .kotva {
         margin-top: -118px;
         padding-top: 118px;
      }
   }

   &#stranka #hlava {
      margin-bottom: 0;
   }

   #obalobrazek #obrazek {
      padding-bottom: 150px;
   }

   .aktuality-obal {
      @extend %sf;
   }

   .dok-upoutavka {
      position: relative;
   }

   .alt-span {
      font: 300 rem(14px) $font;
      color: white;
      padding: 15px 20px;
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
      right: 0;
      display: none;
      background-color: rgba(#000000, 0.75);

      @include min(600px) {
         display: block;
      }
   }

   .img-container {
      position: relative;
      margin: 20px 0;

      .alt-span {
         right: auto;
         left: 0;
      }

      img {
         margin: 0 !important;
      }
   }

   #hlava #profil-navrat img {
      width: 137px;
   }

   .centrovany {
      width: 94%;
      max-width: 1360px;
   }

   &#titul .centrovany {
      width: 94%;

      @include bp('> 1360px') {
         max-width: 1100px;
      }

      @include bp('> 1560px') {
         max-width: 1280px;
      }

      @include bp('> 1740px') {
         max-width: 1360px;
      }
   }

   #content-odkazy {
      padding-left: 50px !important;
      padding-right: 50px !important;
   }

   .kontakt-ext {
      h2.cvi {
         padding-bottom: 24px !important;
      }
   }

   #hledani .format {
      height: 37px !important;
      width: 100% !important;
      box-sizing: border-box;
   }

   &.noleftpanel .obsah#dokument {
      .perex {
         max-width: 100%;
         font-weight: 500;
         color: #2d2d2d;
      }

      .velkaupoutavka img {
         margin: 10px 0;
      }

      .editor {
         max-width: 100%;
      }

      .ReakcePrispevek {
         margin: 0;
      }

      .jezkovi-oci-vybery {
         margin: 30px 0 0;
         border-top: 3px solid #cbcbcb;
         padding: 30px 0 0;
         @extend %sf;

         @include bp('> 900px') {
            padding-left: 50px;
         }

         ul,
         li {
            @extend %reset-list;
         }

         li {
            min-width: 160px;
            float: left;
            display: inline-flex;
            height: 40px;
            align-items: center;
            justify-content: center;
            padding: 10px 20px;
            box-sizing: border-box;
            font: 400 rem(14px) $font;
            background-color: $color-main;
            margin: 0 24px 15px 0;
            position: relative;
            text-align: center;

            a {
               color: white;
               text-decoration: none;

               &:hover {
                  text-decoration: underline;
               }

               &::before {
                  @include fill;
                  content: '';
               }
            }
         }
      }
   }

   .dok-datum-oci {
      padding-top: 10px;
      padding-bottom: 20px;
      font: 700 rem(20px) $font;
      color: #2d2d2d;
   }

   #oci-levy-sloupec {
      font: 400 rem(18px) $font;
      color: #666666;

      h2.cvi {
         font-weight: 700;
      }

      @include bp('> 900px') {
         float: left;
         width: 67.6%;

         h2.cvi,
         .perex,
         .editor,
         .ReakcePrispevek,
         .popis.dpopis,
         .dok-datum-oci {
            padding-left: 50px;
         }
      }
   }

   .oci-pravy-sloupec {
      color: #666666;
      font: 400 rem(15px) $font;

      > h2.cvi {
         background-color: $color-main;
         color: white;
         padding: 70px 25px 30px 30px;
         margin-bottom: 20px;
      }

      .dok ul li {
         margin: 0 0 0 30px;
         padding: 20px 0;

         strong a img {
            width: 100%;
            max-width: 360px;
            margin: 0 auto 10px;
            float: none;
            display: block;
         }

         div {
            padding-top: 10px;

            span {
               padding-top: 10px;
               display: block;
               font: 700 rem(16px) $font;
               color: #2d2d2d;
            }
         }
      }

      @include bp('> 600px') {
         .dok ul li strong a img {
            max-width: 300px;
            margin: 0 20px 10px 0;
            float: left;
         }
      }

      @include bp('> 900px') {
         float: right;
         width: 29%;

         .dok ul li strong a img {
            max-width: 365px;
            float: none;
            margin: 0 0 10px;
         }
      }
   }
}

#kotvy {
   position: absolute;
   right: 0;
   top: 170px;
   text-align: left;
   display: none;
   font: 700 rem(20px) $font-fett;
   background: white url(images/jezekR.png) no-repeat center top;
   border-right: 0;
   box-sizing: border-box;
   z-index: 100;
   width: 120px;
   padding-top: 70px;

   ul,
   li {
      list-style-type: none;
      margin: 0;
      padding: 0;
   }

   ul {
      border-top: 3px solid #cbcbcb;
      position: relative;
      padding-bottom: 38px;

      &::after {
         content: '';
         width: 100%;
         position: absolute;
         left: 0;
         bottom: 0;
         height: 38px;
         background: #cbcbcb url(images/sipkaDolu.png) no-repeat 25% center;
      }
   }

   li {
      padding: 3px 0;
   }

   a {
      color: #444444;
      line-height: 1;
      padding: 10px 0 10px 20%;
      text-decoration: none;
      display: block;
      box-sizing: border-box;

      &:hover {
         text-decoration: underline;
      }
   }

   .akt {
      background: $color-main;
      color: white;

      &:hover {
         text-decoration: none;
      }
   }

   @include bp('> 1360px') {
      display: block;
   }

   @include bp('> 1720px') {
      width: 175px;
   }
}

#kotvyObal.fixed {
   position: absolute;
   right: 0;
   top: 0;
   bottom: 0;
   display: none;
   font-size: rem(20px);
   width: 120px;

   @include bp('> 1360px') {
      display: block;
   }

   @include bp('> 1720px') {
      width: 175px;
   }

   #kotvy {
      position: fixed;
      right: auto;
   }
}

/* Skrytí upoutávky pro ježkovy voči */

.profil.oci {
   .unp {
      display: none;

      + #unpobtekane {
         margin-left: 0 !important;
      }
   }
}

.international-vsuvka {
   margin-bottom: 40px;
}

.kotvy-socialni {
   margin: 0 0 0 30px;
   padding: 0;
   display: inline-flex;
   align-items: center;

   li {
      margin: 0 12px 0 0;
      display: inline-flex;
      align-items: center;

      &:last-child {
         margin-right: 0;
      }

      a {
         display: inline-flex;
         align-items: center;
      }

      svg {
         fill: #151515;
      }
   }
}

.vismo--public.profil-str.personalni {
   #dokument .perex {
      display: none;
   }
}

//#utvar #dole .bodkazy {
//   display: none;
//}
