/*****************************************
* DEKLARACE PROMĚNNÝCH
*****************************************/

$menu-tablet-size: 601px; // hranice mezi vyjížděcím/statickým menu
$menu-bg: #ffffff; // pozadí menu
$menu-link-color: #303030; // barva odkazů a písma v menu
$menu-topbar-bg: $color-main; // barva horní lišty značící aktuální umístění
$menu-topbar-text: #ffffff; // text a ikony v horní liště
$menu-trigger-bg: $color-main; // barva pozadí tlačítka pro otevření menu
$menu-trigger-text: #ffffff; // text v tlačítku

/****************/

/*****************************************
* MIXINY A PROMĚNNÉ PRO TABULKOVÉ MENU
*****************************************/

$menu-spacing: 10px;
$menu-colors: #212922 #294936 #3e6259 #5b8266;

@mixin make-spacing($amount) {
   margin-left: -#{$amount};
   margin-right: -#{$amount};
}

@mixin make-row($spacing) {
   width: 100%;
   display: table;
   table-layout: fixed;
   border-collapse: separate;
   border-spacing: $menu-spacing 0;
}

@mixin make-columns {
   margin: 0;
   padding: 0;
   display: table-cell;
   list-style-type: none;
}

/****************************************/

/******************************
* HORIZONTÁLNÍ MENU A VÝBĚRY
******************************/

#menu1 {
   @include font-size(18px);
   position: relative;
   padding: 42px 15px 0 15px;
   margin-bottom: 20px;
   clear: both;

   .profil.oci & {
      padding-top: 32px;

      @include bp("> 1000px") {
         padding-top: 52px;
      }
   }

   ul {
      line-height: 1.2;
      @extend %reset-list;
   }

   li {
      @extend %reset;
      margin-bottom: 10px;
   }

   @include bp("> 600px") {
      float: left;
      clear: none;
      padding-left: 0;
      padding-right: 0;

      li {
         margin-left: 24px;
         margin-bottom: 0;
         float: left;
      }
   }

   @include bp("> 700px") {
      li {
         margin-left: 40px;
      }
   }

   div {
      display: inline;
   }

   @include link {
      color: #666666;
      text-decoration: none;

      @include bp("<= 500px") {
         body:not(.profil.oci) & {
            color: #ffffff;
         }
      }
   }

   @include link-over {
      text-decoration: underline;
   }

   #stranka:not(.profil.oci) & {
      @include bp("< 600px") {
         display: none;
      }
   }
}

/*@include bp("> 500px", "<= 800px") {
	#menu1 {
		ul {
			@include flex-base;
			@include flex-wrap(wrap);
		}

		li {
			@include box-sizing;
			@include grid-column(2, 2%);
			float: left;
			margin-bottom: 15px;
		}
	}
}*/

/*@include bp("> 800px") {
	#menu1,
	#vybrane {
		@include make-spacing( $menu-spacing );
	}

	#menu1 ul,
	#vybrane .bg {
		@include make-row( $menu-spacing );
	}

	#menu1 li,
	#vybrane [class*="sekce"] {
		@include make-columns;
	}
}*/

/*#menu1 {
	ul {
		@extend %reset-list;
	}

	li {
		font-size: rem(20px);
		line-height: 1.1;

		&.akt {
			border: 5px #000 solid;
		}

		a {
			display: block;
			font-family: $font;
			padding: 14px 20px;
		}

		@include link {
			text-decoration: none;
			color: #ffffff;
		}

		@include link-over {
			text-decoration: underline;
		}

		@include link-all("#osmakth") {
			cursor: text;
			text-decoration: none;
		}
	}
}*/

/*************************
* VÝBĚRY MENU
*************************/

/*#vybrane {
	display: none;

	@include bp("> 800px") {
		display: block;
	}

	.bg {
		@include link {
			color: #ffffff;
			text-decoration: none;
		}

		@include link-over {
			text-decoration: underline;
		}
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 15px 22px 10px 22px;

		li {
			padding: 0;
			display: inline;
		}

		.pam {
			font-size: rem(11px);
			border-left: 1px #ffffff solid;
			margin: 0 0px 0 9px;
			padding: 0;
			vertical-align: middle;
			position: relative;
			top: -1px;
		}

		.pampv .pam {
			display: none;
		}

		@include link(".pamdalsi a") {
			text-decoration: underline;
		}
	}
}*/

/*********************
* MENU VE SLOUPCI
*********************/

#menu ul {
   @extend %reset-list;
}

// #menu-control,
// #menu-start {
// 	display: none;
// }

//@include bp(">= #{$menu-tablet-size}") {
.nadmenu {
   display: block;
   font-weight: normal;
   padding: 0 15px 12px 45px;
   font-family: $font-fett;
   font-size: rem(24px);
   line-height: 1.1;

   &::before {
      content: url(images/arr-nav-back.svg);
      margin-right: 12px;
      vertical-align: middle;
      position: relative;
      top: -0.1em;
   }
}

#menu {
   background-color: $color-main;
   color: #ffffff;
   padding-top: 44px;
   padding-bottom: 64px;

   .dgm &,
   .gustavmahler &,
   .htradice & {
      background-color: #007d90;
   }

   .odpady & {
      background-color: #00893b;
   }

   .informacni-portal & {
      background-color: #565656;
   }

   .doprava & {
      background-color: #007aae;
   }
   .turista & {
      background-color: $color-turista;
   }
   .sport & {
      background-color: $color-sport;
   }

   .kultura & {
      background-color: $color-kultura;
   }
   .festival & {
      background-color: $color-festival;
   }

   .podzemi & {
      background-color: $color-podzemi;
   }

   .bezodpadu & {
      background-color: #6d9a0b;
   }

   @include link {
      color: #ffffff;
      text-decoration: none;
   }

   @include link-over {
      text-decoration: underline;
   }

   // @include link-over("ul a") {
   // 	text-decoration: underline;
   // }

   @include link-all("#osmakth") {
      font-weight: bold;
      color: $color-main;
      background-color: #ffffff;
      text-decoration: none;
      cursor: default;

      .dgm &,
      .gustavmahler &,
      .htradice & {
         color: #007d90;
      }

      .informacni-portal & {
         color: #565656;
      }

      .odpady & {
         color: #00893b;
      }
      .turista & {
         color: $color-turista;
      }

      .sport & {
         color: $color-sport;
      }
      .kultura & {
         color: $color-kultura;
      }
      .festival & {
         color: $color-festival;
      }

      .doprava & {
         color: #007aae;
      }

      .podzemi & {
         color: $color-podzemi;
      }

      .bezodpadu & {
         color: #6d9a0b;
      }
   }

   @include link(".aktodk") {
      font-weight: bold;
      color: $color-main;
      background-color: #ffffff;

      .dgm &,
      .gustavmahler &,
      .htradice & {
         color: #007d90;
      }

      .odpady & {
         color: #00893b;
      }
      .turista & {
         color: $color-turista;
      }
      .sport & {
         color: $color-sport;
      }
      .kultura & {
         color: $color-kultura;
      }
      .festival & {
         color: $color-festival;
      }
      .doprava & {
         color: #007aae;
      }

      .podzemi & {
         color: $color-podzemi;
      }
   }

   @include link-over(".aktodk") {
      text-decoration: underline;
      cursor: pointer;
   }

   .menu {
      font-size: rem(18px);
      font-family: $font;
      line-height: 1.2;

      // .menu-active-pc {
      // 	> a .menuobr {
      // 		@include transform(rotate(225deg));
      // 	}

      // 	> ul {
      // 		display: block;
      // 	}
      // }

      a {
         padding: 10px 15px;
         display: block;
         position: relative;
         z-index: 10;
      }

      // .menuobr {
      // 	width: 20px;
      // 	height: 20px;
      // 	overflow: hidden;
      // 	position: absolute;
      // 	top: .7em;
      // 	right: 8px;
      // 	z-index: 10;

      // 	img {
      // 		margin: 0;
      // 		cursor: pointer;
      // 		-webkit-transition: -webkit-transform;
      // 		transition: -ms-transform;
      // 		transition: transform;
      // 	}
      // }

      //ul {
      // font-size: rem(16px);
      // font-family: $sans-serif;
      // margin: 0;
      // padding: 12px 0 12px 25px;

      // a {
      // 	padding: 1px 32px 0 0;
      // 	line-height: 1.5;
      // }

      // .menuobr {
      // 	right: 7px;
      // 	top: .4em;
      // }

      // ul {
      // 	margin: 0;
      // 	padding: 0 0 0 18px;
      // 	position: static;
      // }
      //}
   }

   li {
      margin: 0;
      padding: 0 0 0 30px;
      position: relative;
      display: block;

      // li {
      // 	border: 0;
      // 	font-weight: normal;
      //background-position: 0 0.15em;

      // li {
      // 	padding: 1px 0;
      // }
      //}

      // &.akt {
      // 	font-weight: bold;
      // 	text-decoration: none;
      // }
   }
}
//}

@include bp("< #{$menu-tablet-size}") {
}
