
/*******************************
* KALENDÁŘE
*******************************/

/*******************************
* DEFINICE PROMĚNNÝCH 
*******************************/

$kalBunkaOdsazeni: 7px; //padding buňky - top a bottom

$kalTitulBunkyMesicPozadi: $color-main;
$kalTitulBunkyMesicText: #ffffff;
$kalTitulBunkyMimoMesicPozadi: transparent;
$kalTitulBunkyMimoMesicText: #444444;
$kalTitulAktualniObrys: #ffffff;

$kalBunkyPodMysiPozadi: #ffffff;
$kalBunkyPodMysiText: #000000;

$kalStrankaBunkyMesicPozadi: $kalTitulBunkyMesicPozadi;
$kalStrankaBunkyMesicText: $kalTitulBunkyMesicText;
$kalStrankaBunkyMimoMesicPozadi: $kalTitulBunkyMimoMesicPozadi;
$kalStrankaBunkyMimoMesicText: $kalTitulBunkyMimoMesicText;
$kalStrankaAktualniObrys: $kalTitulAktualniObrys;
$kalStrankaVybranyDenPozadi: #B0030C;
$kalStrankaVybranyDenText: #ffffff;

$kalVyskakovaciBunkyMimoMesicPozadi: $kalStrankaBunkyMimoMesicPozadi;
$kalVyskakovaciBunkyMimoMesicText: $kalStrankaBunkyMimoMesicText;

/******************************/


$bunka: (100% / 7);

/********************************
* KALENDÁŘ NA TITULCE
********************************/

#kalakci {
	p {
		clear: both;
	}

	.mesice {
		@extend %reset;
		display: table;
		width: 100%;
		line-height: .8;
		table-layout: fixed;
		border-spacing: 1px 0;
		margin-bottom: 10px;

		+ p {
			margin: 20px 0;
		}

		a {
			display: table-cell;
			padding: 1em 0;
			text-align: center;
			vertical-align: middle;
			text-decoration: none;

			&.sipka {
				width: $bunka;
				font-family: $font;
				position: relative;
			}
		}

		@include link {
			color: #ffffff;
			background-color: #4A4A4A;
		}
		
		@include link-over {
			background-color: #000000;
		}
	}

	.calcells {
		th {
			font-weight: normal;
			color: $kalTitulBunkyMimoMesicText;
			background-color: $kalTitulBunkyMimoMesicPozadi;
			border-bottom: 10px transparent solid;
			background-clip: padding-box;
		}

		th,
		td {
			padding: $kalBunkaOdsazeni 0;
		}

		td {
			> a {
				padding: $kalBunkaOdsazeni 0;
				margin: -$kalBunkaOdsazeni 0;
			}

			&,
			> #{selector-link()} {
				background-color: $kalTitulBunkyMesicPozadi;
				color: $kalTitulBunkyMesicText;
			}

			&.notmnth {
				background-color: $kalTitulBunkyMimoMesicPozadi;
				color: $kalTitulBunkyMimoMesicText;

				> #{selector-link()} {
					background-color: $kalTitulBunkyMimoMesicPozadi;
					color: $kalTitulBunkyMimoMesicText;
				}
			}

			&.curdate {
				border: 2px $kalTitulAktualniObrys solid;
				padding: 0;

				> a {
					padding: ( $kalBunkaOdsazeni - 2px ) 0;
					margin: -2px 0;
				}
			}

			&.wkday,
			&.notmnth {
				> #{selector-link-over()} {
					background-color: $kalBunkyPodMysiPozadi;
					color: $kalBunkyPodMysiText;
				}	
			}
		}

		// Nasledujici odkomentovat, pokud maji bubliny vylezat zleva
		//.floatingBubbleElm {
		//	margin-left: -15px !important;
		//}
	}
}


/********************************
* KALENDÁŘE NA PODSTRÁNKÁCH
********************************/

#stranka {
	#kalakci .calcells/*,
	.calendar*/ {
		td {
			&.wkday,
			&.wkend {
				&,
				> #{selector-link()} {
					background-color: $kalStrankaBunkyMesicPozadi;
					color: $kalStrankaBunkyMesicText;
				}
			}

			//&.notmnth {
			> #{selector-link()} {
				background-color: $kalStrankaBunkyMimoMesicPozadi;
				color: $kalStrankaBunkyMimoMesicText;
			}
			//}

			&.curdate {
				border: 2px $kalTitulAktualniObrys solid;
				border-color: $kalStrankaAktualniObrys;
			}

			&.wkday,
			&.wkend,
			&.notmnth {
				> #{selector-link-over()} {
					background-color: $kalBunkyPodMysiPozadi;
					color: $kalBunkyPodMysiText;
				}
			}

			&.cell_selected {
				&,
				> #{selector-link-all} {
					background-color: $kalStrankaVybranyDenPozadi;
					color: $kalStrankaVybranyDenText;
				}
			}
		}
	}
}

