#google_translate_element {
   display: inline-block;
   position: relative;
   display: none;
   overflow: hidden;
   flex-shrink: 0;

   &.ready {
      margin-left: 12px;
      display: inline-block;
      top: 4px;
   }

   .goog-te-select {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      padding: 0;
      opacity: 0 !important;
   }

   .goog-te-gadget {
      border: 0;
      width: 100%;
      padding-bottom: 1px;
      background-color: transparent;
      font-size: 0 !important;
      position: relative;
      display: inline-flex;
      align-items: center;
      overflow: hidden;

      .goog-te-gadget-simple {
         display: none !important;
      }

      > * {
         font-size: 1rem !important;
      }

      &::before {
         content: '';
         width: 19px;
         height: 19px;
         background-image: url(/aspinclude/vismoWeb5/html/images/gte-icon.svg) !important;
         background-position: 0 0 !important;
         background-size: 100% auto;
         background-repeat: no-repeat;
      }

      /* &::after {
         content: '';
         margin-left: 8px;
         width: 0;
         height: 0;
         border-style: solid;
         border-width: 6px 4px 0 4px;
         border-color: grey transparent transparent transparent;
      } */

      .goog-te-gadget-icon {
         + span {
            display: none;
         }
      }
   }
}

body[style] {
   top: 0 !important;
}

#goog-gt-tt,
#goog-gt-tt + div > object,
iframe.goog-te-menu-frame,
iframe.goog-te-banner-frame {
   display: none !important;
}
