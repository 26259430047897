/*************************
* GLOBÁLNÍ PROMĚNNÉ
*************************/

$sans-serif: Arial, 'Arial CE', Helvetica, 'Helvetica CE', sans-serif;
$font: Roboto;
$font-fett: TeutonFettWeb;
$font-mager: TeutonMagerWeb;
$font-normal: TeutonNormalWeb;
$font-hell: TeutonHellWeb;

$color-main: #ca1517;
$color-dgm: #159aa9;
$color-podzemi: #a3043e;
$color-havireni: #25af4f;
$color-turista: #46983b;
$color-sport: #048fcd;
$color-kultura: #324ab0;
$color-festival: #69554b;
$color-infoportal: #565656;

/************************/
