/***********************
*	ZÁLOŽKY
***********************/

/***********************
* DEFINICE PROMĚNNÝCH
***********************/

$zalozkaTitulPozadi: transparent;
$zalozkaTitulText: #666666;
$zalozkaTitulAktivniPozadi: $color-main;
$zalozkaTitulAktivniText: #ffffff;

$zalozkaStrankaPozadi: $zalozkaTitulPozadi;
$zalozkaStrankaText: $zalozkaTitulText;
$zalozkaStrankaAktivniPozadi: $zalozkaTitulAktivniPozadi;
$zalozkaStrankaAktivniText: $zalozkaTitulAktivniText;

/**********************/

.zalozky {
   font-size: rem(20px);
   line-height: 1.1;
   font-family: $font-mager;
   font-weight: normal;

   ul {
      @extend %sf;
      @extend %reset-list;
   }

   li {
      padding: 0;
      margin: 0 0.5em 0.5em 0;
      float: left;
      position: relative;
      display: block;

      &.azalozka {
         background: transparent none;
      }
   }

   a {
      display: block;
      text-decoration: underline;
      margin: 0;
      padding: 8px 15px;
      position: relative;
      z-index: 20;
      cursor: pointer;
   }

   @include link {
      background-color: $zalozkaStrankaPozadi;
      color: $zalozkaStrankaText;
   }

   @include link-over {
      text-decoration: underline;
   }

   .azalozka {
      @include link-all {
         background-color: $zalozkaStrankaAktivniPozadi;
         color: $zalozkaStrankaAktivniText;
         text-decoration: none;
         cursor: default;
      }
   }
}

#zalozkynadpis {
   padding: 15px 0 0 0;
}

.vych_pol {
   font-size: rem(16px);
   text-align: right;
   padding: 0 0 10px 0;
   margin: 0 0 10px 0;
   text-transform: uppercase;

   strong {
      font-weight: normal;
   }
}

a[id*='k0'] {
   height: 0;
   overflow: hidden;
   line-height: 0;
   font-size: 0;
   color: transparent !important;
}

/****************************
*	S VYPNUTÝM JS NA TITULCE
****************************/

.bezjs {
   padding: 20px 0 15px 0;

   ul {
      @extend %reset-list;
   }

   .inline li::before {
      color: #404040;
   }
}

#keskryti {
   @extend %reset;
   display: inline;
}

/****************************
*	SE ZAPNTÝM JS NA TITULCE
****************************/

#titul .zalozky {
   font-size: rem(31px);

   .inline li::before {
      content: normal;
   }

   li {
      @extend %reset;
   }

   a {
      padding: 15px 32px;
   }

   @include link {
      background-color: $zalozkaTitulPozadi;
      color: $zalozkaTitulText;
   }

   @include link-over {
      text-decoration: underline;
   }

   .azalozka {
      @include link-all {
         background-color: $zalozkaTitulAktivniPozadi;
         color: $zalozkaTitulAktivniText;
      }
   }
}

#titul .linkynakotvy {
   position: relative;

   .zalozky {
      padding-right: rem(260px);
   }
}

.jezkovy-oci-link {
   position: absolute;
   right: 0;
   bottom: 0;
   margin: 0;
   padding: 0;
   font-size: rem(21px);
   width: rem(240px);

   @include link {
      background-color: $color-main;
      color: white;
      text-decoration: none;
      font-family: $font-mager;
      display: block;
      text-align: center;
      padding: 9px 5px;
      line-height: 1.07;

      strong {
         font-family: $font-normal;
      }

      &::after {
         content: url(images/arr-nav.svg);
         width: 8px;
         height: 12px;
         display: inline-block;
         margin-left: 10px;
      }
   }

   @include link-over {
      background-color: $color-main;
      color: white;
      text-decoration: underline;
   }
}

@include bp('< 650px') {
   #titul .linkynakotvy {
      padding-top: 70px;
   }

   #linkynakotvy {
      display: none;
   }

   #titul .poz {
      h2.cist {
         position: static;
         width: auto;
         height: auto;
      }

      .skryty {
         display: block;
      }
   }
}
